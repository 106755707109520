import { BillingRequest, Client } from './http-services/api';

async function getFinancialOverview() {
  const client = new Client();

  const result = await client.financialOverview();
  return result;
}

async function getFinancialOverviewByTeam(team: number) {
  const client = new Client();

  const result = await client.financialOverviewByTeam(team);
  return result;
}

async function getUtilizationMonthOverview() {
  const client = new Client();

  const result = await client.utilizationAll();
  return result;
}

async function getUtilizationByUser(dateTime: Date) {
  const client = new Client();

  const result = await client.utilization(dateTime);
  return result;
}

async function getUtilizationByUserId(dateTime: Date, userId: number) {
  const client = new Client();

  const result = await client.utilizationByUserId(dateTime, userId);
  return result;
}

async function synchroniseBills() {
  try {
    const client = new Client();

    await client.bills();
    return true;
  } catch (error) {
    return false;
  }
}

async function synchroniseCustomers() {
  try {
    const client = new Client();

    await client.customers();
    return true;
  } catch (error) {
    return false;
  }
}

async function getBillingOverview() {
  const client = new Client();

  const result = await client.billingAll();
  return result;
}

async function getCustomerBilling(customerId: number) {
  const client = new Client();

  const result = await client.billing(customerId);
  return result;
}

async function updateTimerecordsWithBill(billingRequest: BillingRequest) {
  const client = new Client();

  const result = await client.existing(billingRequest);
  return result;
}

async function createNewBillFromTimerecords(customerId: number) {
  const client = new Client();

  try {
    const result = await client.new(customerId);
    return result;
  } catch (error) {
    return null;
  }
}

async function updateTimerecordsWithBillSpecificProject(projectId: number, billingRequest: BillingRequest) {
  const client = new Client();

  const result = await client.existing2(projectId, billingRequest);
  return result;
}

async function createNewBillFromTimerecordsSpecificProject(projectId: number, customerId: number) {
  const client = new Client();

  try {
    const result = await client.new2(projectId, customerId);
    return result;
  } catch (error) {
    return null;
  }
}

async function billHoursWithSpecificVoucher(voucherId: number, projectIds: number[]) {
  const client = new Client();

  try {
    const result = await client.registerVoucher(voucherId, projectIds);
    return result;
  } catch (error) {
    return null;
  }
}

async function sendStatusToAll() {
  const client = new Client();
  try {
    client.status();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function sendStatusToSpecificCustomer(customerId: number) {
  const client = new Client();
  try {
    await client.sendSpecificStatus(customerId);
    return true;
  } catch (error) {
    return false;
  }
}

async function sendStatusAdminToSpecificCustomer(customerId: number) {
  const client = new Client();
  try {
    await client.sendSpecificStatusAdmin(customerId);
    return true;
  } catch (error) {
    return false;
  }
}

async function getProjectManagement(customerId: number, view: string) {
  const client = new Client();

  const result = await client.projectManagement(customerId, view);
  return result;
}

async function getInvoiceBySecret(invoiceId: number | undefined, secret: string | undefined) {
  const client = new Client();

  const result = await client.invoice(invoiceId, secret);
  return result;
}

export const economicServices = {
  getFinancialOverview,
  getFinancialOverviewByTeam,
  getUtilizationMonthOverview,
  getUtilizationByUser,
  getUtilizationByUserId,
  synchroniseBills,
  synchroniseCustomers,
  getBillingOverview,
  getCustomerBilling,
  updateTimerecordsWithBill,
  createNewBillFromTimerecords,
  sendStatusToAll,
  updateTimerecordsWithBillSpecificProject,
  createNewBillFromTimerecordsSpecificProject,
  getProjectManagement,
  getInvoiceBySecret,
  sendStatusToSpecificCustomer,
  sendStatusAdminToSpecificCustomer,
  billHoursWithSpecificVoucher
};
