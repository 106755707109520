import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { AuthProvider } from './utilities/use-auth';
import './index.css';
import App from './App';
import { colorTheme } from './styles/color-theme';
import { WindowWidthProvider } from './utilities/use-window-width';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={colorTheme()}>
      <CssBaseline />
      <BrowserRouter basename="/"></BrowserRouter>
      <AuthProvider>
        <BrowserRouter>
          <WindowWidthProvider>
            <App />
          </WindowWidthProvider>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
