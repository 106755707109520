import { List } from '@material-ui/core';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';

import NavBarItem from '../navbar-item/navbar-item';
import { localized } from '../../utilities/language/i18n';

const LoginNavBar: React.FC = () => {
  const setInitialPath = () => {
    return window.location.pathname;
  };
  const [item, setItem] = React.useState(setInitialPath());

  const setHome = () => {
    setItem('/');
  };

  const setLogin = () => {
    setItem('/login');
  };

  return (
    <div className="LoginNavBar" data-testid="LoginNavBar">
      <div style={{ padding: 22 }}>
        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src="/Cenvation-logo-1.png" />
        <p style={{ textAlign: 'left', color: '#222', marginTop: '-4px' }}>{localized('TitelMobile')}</p>
      </div>
      <List>
        <NavBarItem linkTo="/" text={localized('Home')} icon={<HomeIcon />} selected={item === '/'} onMenuItemClicked={setHome} />
        <NavBarItem
          linkTo="/login"
          text={localized('Login')}
          icon={<PersonIcon />}
          selected={item === '/login'}
          onMenuItemClicked={setLogin}
        />
      </List>
      <footer>
        <p>&copy; 2023 Cenvation</p>
        <p>
          <a href="https://www.cenvation.com/">Visit cenvation.com</a>
        </p>
      </footer>
    </div>
  );
};

export default LoginNavBar;
