import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Booking } from '../../services/http-services/api';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

interface AlertDialogProps {
  sendBooking?: (booking: Booking) => void;
  acceptRequest: () => void;
  visible: boolean;
  setVisible: (val: boolean) => void;
  title: string;
  description: string;
  information?: string;
  BookingToChange?: Booking;
}

export default function AlertDialog(props: AlertDialogProps) {
  const { visible, setVisible, acceptRequest } = props;
  const [open, setOpen] = React.useState(visible);

  const handleDisagree = () => {
    setOpen(false);
    setVisible(false);
  };

  const handleAccept = () => {
    if (props.BookingToChange === undefined) {
      acceptRequest();
    } else if (props.sendBooking !== undefined) props.sendBooking(props.BookingToChange);
    setOpen(false);
    setVisible(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleDisagree} aria-labelledby="confirm-modal-title" aria-describedby="confirm-modal-description">
        <DialogTitle id="confirm-modal-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-modal-description">{props.description}</DialogContentText>
          <DialogContentText id="confirm-modal-description">{props.information}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} size={buttonSize} color="secondary" variant="contained">
            {localized('Disagree')}
          </Button>
          <Button onClick={handleAccept} color="primary" size={buttonSize} autoFocus variant="contained">
            {localized('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
