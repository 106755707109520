import { Client, Team } from './http-services/api';

async function getAll() {
  const client = new Client();

  const result = await client.teamAll();
  return result;
}

export const teamService = {
  getAll,
};