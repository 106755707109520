import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TableRow, TableContainer, Table, TableHead, TableBody, Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import { Bill, TimerecordsPerUserDTO } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../styles/use-styles';
import { timerecordService } from '../../services/timerecord.services';
import { billService } from '../../services/bill.service';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { customerService } from '../../services/customer.services';

function BillSpecification() {
  const [timerecordsFromBill, setTimerecordsFromBill] = React.useState<TimerecordsPerUserDTO[] | null>();
  const [bill, setBill] = React.useState<Bill | null>();
  const [name, setName] = React.useState<string | null>();

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    billSpecification: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const billId = JSON.parse(params.billSpecification);
      if (billId) {
        const relevantTimeRecords = await timerecordService.getSpecificationByBillId(parseInt(billId, 10));
        setTimerecordsFromBill(relevantTimeRecords);
        const correspondingBill = await billService.getBillById(billId);
        setBill(correspondingBill);
      }
    }

    dataFromProps();
  }, []);

  React.useEffect(() => {
    async function loadName() {
      const retval = bill && bill.customerId ? await customerService.getCustomerById(bill.customerId) : null;
      if (retval) {
        setName(retval!.name!);
      }
    }
    loadName();
  }, [bill]);

  const classes = TableStyles();

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {
          // eslint-disable-next-line prefer-template
          localized('SpecificationForInvoice') + ' : ' + bill?.number + ' ' + '(' + name + ')'
        }
      </Typography>
      {timerecordsFromBill ? (
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customerBills-table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> {localized('Date')}</StyledTableCell>
                  <StyledTableCell> {localized('Hours')}</StyledTableCell>
                  <StyledTableCell> {localized('Project')}</StyledTableCell>
                  <StyledTableCell> {localized('Comment')}</StyledTableCell>
                  <StyledTableCell> {localized('Status')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timerecordsFromBill.map((row: TimerecordsPerUserDTO) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.date && format(row.date, "EEEE'\r\n'dd-MM-yyyy")}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.hours}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.project && row.project.name}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.comment}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.status}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Box marginTop={2}>
        <Button variant="contained" color="primary" size={buttonSize} className="button" onClick={handleGoBackClick}>
          {localized('GoBack')}
        </Button>
      </Box>
    </div>
  );
}

export default withRouter(BillSpecification);
