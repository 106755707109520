import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import './navbar-item.scss';

export interface NavBarItemProps {
  linkTo: string;
  text: string;
  icon?: JSX.Element;
  selected: boolean;
  onMenuItemClicked: () => void;
}

function NavBarItem(props: NavBarItemProps) {
  const onMenuItemClicked = () => {
    props.onMenuItemClicked();
  };

  const iconShow = () => {
    if (props.icon === undefined) {
      return null;
    } else
      return (
        <ListItemIcon style={{ minWidth: '40px', color: '#222' }} className="iconWidth">
          {props.icon}
        </ListItemIcon>
      );
  };

  return (
    <Link to={props.linkTo} className="textDecorationLink">
      <ListItem button key={props.text} selected={props.selected} onClick={onMenuItemClicked}>
        {iconShow()}
        <ListItemText primary={props.text} />
      </ListItem>
    </Link>
  );
}

export default NavBarItem;
