import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { projectService } from '../../../services/project.service';
import { configurationService } from '../../../services/configuration.service';
import { TableStyles, DataGridStyle } from '../../../styles/use-styles';
import { Project, Product } from '../../../services/http-services/api';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function GetAllProjects() {
  const [projects, setProject] = React.useState<Project[] | null>(null);
  const [searchName, setSearchName] = useState('');
  const [products, setProducts] = useState<Product[] | undefined>();
  React.useEffect(() => {
    async function loadProjects() {
      const projectList = await projectService.getAllWithCustomers();
      setProject(projectList);
      const productList = await configurationService.getAllProducts();
      setProducts(productList);
    }
    loadProjects();
  }, [setProject]);

  React.useEffect(() => {
    document.title = 'Cenvation - Projects';
  }, []);

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  function changeSearchVal(event: string) {
    setSearchName(event);
  }

  function showType(type: any) {
    if (type === undefined || type === null || !isFinite(type)) {
      return null;
    }
    const product = products?.find(p => p.productId === type);
    return product ? product.name : "Product not found";
  }

  const customerActive = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const isUndefined = (input: any) => {
    if (typeof input === 'undefined') {
      return '-';
    } else {
      return input;
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: localized('Id'), width: 150 },
    { field: 'name', headerName: localized('ProjectName'), width: 350 },
    {
      field: 'type',
      headerName: localized('Type'),
      align: 'center',
      headerAlign: 'center',
      width: 130,
      renderCell: (params) => {
        return <div>{showType(params.getValue(params.id, 'type'))}</div>;
      }
    },
    {
      field: 'customer',
      headerName: localized('Customer'),
      align: 'center',
      headerAlign: 'center',
      width: 360,
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : '')
    },
    {
      field: 'budget',
      headerName: localized('Budget'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{isUndefined(params.getValue(params.id, 'budget'))}</div>;
      }
    },
    {
      field: 'active',
      headerName: localized('Active'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{customerActive(params.getValue(params.id, 'active'))}</div>;
      }
    },
    {
      field: 'edit',
      headerName: localized('Update'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/edit-project/${params.getValue(params.id, 'id')}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Update')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'copy',
      headerName: localized('Copy'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/copy-project/${params.getValue(params.id, 'id')}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Copy')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'hours',
      headerName: localized('Hours'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/hours-project/${params.getValue(params.id, 'id')}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Hours')}
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('ViewAllProjects')}
      </Typography>
      {projects && (
        <Box margin={2}>
          <TextField
            id="standard-basic"
            value={searchName}
            label={localized('SearchCustName')}
            onChange={(event) => changeSearchVal(event.target.value)}
          />
        </Box>
      )}
      {projects ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {projects ? (
              <DataGrid
                autoHeight 
                className={classesGrid.root}
                rows={projects}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 100]}
                filterModel={{
                  items: [{ columnField: 'customer', operatorValue: 'contains', value: searchName }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(GetAllProjects);
