import moment from 'moment';

import { CellUnits } from './types/cell-units';
import { SchedulerData } from './scheduler-bindings';
import { Event } from './scheduler-data';

export const getEventText = (schedulerData: SchedulerData, event: Event) => {
  if (!schedulerData.isEventPerspective) {
    return event.title;
  }

  let eventText = event.title;
  schedulerData.resources.forEach((item) => {
    if (item.id === event.resourceId) {
      eventText = item.name;
    }
  });

  return eventText;
};

export const isNonWorkingTime = (schedulerData: SchedulerData, time: string) => {
  if (schedulerData.cellUnit === CellUnits.Hour) {
    const hour = moment(time).hour();
    if (hour < 9 || hour > 18) {
      return true;
    }
  } else {
    const dayOfWeek = moment(time).weekday();
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      return true;
    }
  }

  return false;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getEventTextFunc: getEventText,
  isNonWorkingTimeFunc: isNonWorkingTime
};
