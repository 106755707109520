import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';

import { ProjectSubscriptionDTO } from '../../../services/http-services/api';
import { projectSubscriptionService } from '../../../services/projectsubscription.service';
import { TableStyles, StyledTableRow, StyledTableCell } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function ProjectSubscriptionsForUser() {
  const classes = TableStyles();
  //  const history = useHistory();

  const [projectSubscriptions, setProjectSubscriptions] = React.useState<ProjectSubscriptionDTO[]>();

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [isSubmitted, setIsSubmitted] = React.useState(true);
  const [alertType, setAlertType] = React.useState<Color>('success');

  React.useEffect(() => {
    async function loadUtilizationMonthDTOs() {
      const dataList = await projectSubscriptionService.getAll();
      setProjectSubscriptions(dataList);
    }
    loadUtilizationMonthDTOs();
  }, [setProjectSubscriptions]);

  React.useEffect(() => {
    document.title = 'Cenvation - Time Registration';
  }, []);

  const handleSubscribeChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, index: number) => {
    if (!projectSubscriptions) {
      return;
    }
    const projectSubscriptionsCopy = Object.assign(projectSubscriptions);
    projectSubscriptionsCopy[index].subscribed = checked;
    setProjectSubscriptions([...projectSubscriptionsCopy]);
    setIsSnackBarOpen(true);
    setIsSubmitted(false);
  };

  function fillTableBody() {
    return (
      <TableBody>
        {Array.isArray(projectSubscriptions) &&
          projectSubscriptions.map((object, index) => {
            return (
              <StyledTableRow key={index}>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={object.subscribed}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                      handleSubscribeChange(event, checked, index)
                    }
                  />
                </TableCell>
                <TableCell className={classes.maxWidthMobile} align="center">
                  {object.customerName}
                </TableCell>
                <TableCell className={classes.maxWidthMobile} align="center">
                  {object.projectName}
                </TableCell>
              </StyledTableRow>
            );
          })}
      </TableBody>
    );
  }
  async function handleSubmit() {
    if (projectSubscriptions) {
      setSnackBarMessage('...');
      const result = await projectSubscriptionService.updateProjectSubscriptions(projectSubscriptions);
      if (result) {
        //  history.goBack();
        setAlertType('success');
        setIsSubmitted(true);
        setSnackBarMessage(localized('SuccessUpdateSubs'));
      } else {
        setSnackBarMessage(localized('FailedChanges'));
        setAlertType('warning');
        setIsSnackBarOpen(true);
      }
    }
  }

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  function saveButton() {
    return (
      <Button variant="contained" size={buttonSize} color="primary" onClick={handleSubmit}>
        Save changes
      </Button>
    );
  }

  return (
    <Box>
      <Typography variant="h4" className="App_pageTitle">
        {localized('ProjectSubscriptions')}
      </Typography>
      {projectSubscriptions ? (
        <Box>
          <Grid container direction="row" justify="center" alignItems="center">
            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
              <Table aria-label="datesTabel" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.paddingZero}>{localized('Subscribe')}</StyledTableCell>
                    <StyledTableCell>{localized('Customer')}</StyledTableCell>
                    <StyledTableCell>{localized('Project')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                {fillTableBody()}
              </Table>
            </TableContainer>
          </Grid>

          <Box display="flex" justifyContent="center" padding={2}>
            <Button variant="contained" size={buttonSize} color="primary" onClick={handleSubmit}>
              {localized('UpdateProjectSubs')}
            </Button>
          </Box>
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isSnackBarOpen} onClose={handleCloseSnackbar}>
            {isSubmitted ? <Alert severity={alertType}>{snackBarMessage}</Alert> : saveButton()}
          </Snackbar>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default withRouter(ProjectSubscriptionsForUser);
