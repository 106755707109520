/*
  This file is made with inspiration from 
  https://material-ui.com/components/drawers/#mini-variant-drawer
*/

import { createStyles, Divider, Drawer, Hidden, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import './navbar.scss';

import { useAuth } from '../../utilities/use-auth';
import LoginNavBar from '../login-navbar/login-navbar';
import { useWindowWidth } from '../../utilities/use-window-width';
import DesktopNavBar from '../desktop-navbar/desktop-navbar';
import MobileNavBar from '../mobile-navbar/mobile-navbar';
import { mobileMaxWidth, navBarWidth } from '../../utilities/constants';

// needs to be moved

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${navBarWidth}px)`,
        marginLeft: navBarWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: navBarWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    toolbar: {
      display: 'flex',
      allignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },

    drawerPaper: {
      background: 'transparent',
      color: '#222',
      width: navBarWidth,
      borderRight: 0,
      [theme.breakpoints.down('xs')]: {
        background: 'white'
      }
    }
  })
);

interface NavBarProps {
  changeState: () => void;
  menuState: boolean;
}

function NavBar(props: NavBarProps) {
  const development = process.env.NODE_ENV === 'development';
  const auth = useAuth();
  const classes = useStyles();

  const condition = development || auth.loginState;

  const { width } = useWindowWidth();

  const checkLoginMobile = () => {
    if (condition) {
      if (width > mobileMaxWidth) return <DesktopNavBar />;
      else return <MobileNavBar />;
    } else return <LoginNavBar />;
  };

  const checkDesktop = () => {
    if (condition) {
      return <DesktopNavBar />;
    } else return <LoginNavBar />;
  };

  return (
    <div>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Divider />
          <Drawer
            variant="temporary"
            open={props.menuState}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={props.changeState}
            ModalProps={{
              keepMounted: true
            }}
          >
            {checkLoginMobile()}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          {/* <Divider /> */}
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {checkDesktop()}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default NavBar;
