import moment from 'moment';
import React, { Component } from 'react';
import { rrulestr } from 'rrule';
import parse from 'date-fns/parse';
import { CircularProgress } from '@material-ui/core';

import BookingFormDialog from '../modal-dialogs/booking-form-dialog';
import { Booking, Person, Project } from '../../../services/http-services/api';
import { personService } from '../../../services/person.services';
import { bookingService } from '../../../services/booking.services';
import { projectService } from '../../../services/project.service';
import AlertDialog from '../../alert-dialog/alert-dialog';
import { TypeColorCustomer, TypeColorHoliday, TypeColorInternal } from '../../../utilities/colors';

import { DATETIME_FORMAT } from './types/date-formats';
import { EventGroup, Resource } from './scheduler-data';
import Scheduler, { SchedulerData, SchedulerEvent, SchedulerViewTypes, SCHEDULER_DATE_FORMAT } from './scheduler-bindings';
import withDragDropContext from './with-dnd-context';

export interface IBookingEvent {
  schedulerData: SchedulerData;
  resourceId: string;
  eventId?: string;
  start: Date;
  end: Date;
  type: string;
  description?: string;
  title?: string;
  existingEvent: boolean;
  projectId?: number;
  groupId: number;
}

interface FormDialogState {
  bookingFormModal: boolean;
  inputBooking?: IBookingEvent;
  conflictNeededToBeHandled: boolean;
  conflictBookingRemaining: SchedulerEvent[];
}

interface SchedulerState {
  viewModel: SchedulerData;
  persons: Person[];
  existingBookings: SchedulerEvent[];
  bookingToUpdate: Booking;
  projects: Project[];
  fromDate: Date;
}

interface AlertState {
  alertDialogVisible: boolean;
  alertTitle: string;
  alertDescription: string;
  alertinformation: string;
}

interface IState extends FormDialogState, AlertState, SchedulerState {}

class BookingUI extends Component<{}, IState> {
  getNow = (): string => {
    return moment().format(SCHEDULER_DATE_FORMAT);
  };

  schedulerData = new SchedulerData(this.getNow(), SchedulerViewTypes.Quarter);

  checkType = (type: number | undefined) => {
    if (type !== undefined) return type.toString();
    return '0';
  };

  checkIdNumber = (idNumber: number | undefined) => {
    if (idNumber !== undefined) return idNumber;
    return 0;
  };

  getNewestBookings = async () => {
    if (this.state.fromDate !== null) {
      const bookingList = await bookingService.getBookingFromDate(this.state.fromDate);
      this.setState({ existingBookings: this.convertBookingToEvent(bookingList) });
      this.schedulerData.setEvents(this.convertBookingToEvent(bookingList));
      this.setState({ viewModel: this.schedulerData });
    }
  };

  updateAndConvertSchedulerEventToBooking = (event: SchedulerEvent, newStart?: string, newEnd?: string, ressourceId?: string) => {
    const eventToUpdate = this.state.existingBookings.find((booking) => booking.id === event.id);

    if (eventToUpdate !== undefined) {
      const changedBooking = new Booking();
      changedBooking.title = event.title;
      changedBooking.type = event.type;

      if (event.projectId !== undefined) changedBooking.projectId = event.projectId;
      changedBooking.id = parseInt(event.id, 10);

      if (event.groupId !== undefined) changedBooking.groupId = parseInt(event.groupId, 10);

      if (newStart === undefined) {
        changedBooking.startDate = new Date(event.start);
      } else changedBooking.startDate = new Date(newStart);

      if (newEnd === undefined) {
        changedBooking.endDate = new Date(event.end);
      } else changedBooking.endDate = new Date(newEnd);

      if (ressourceId === undefined) changedBooking.personId = parseInt(event.resourceId, 10);
      else {
        changedBooking.personId = parseInt(ressourceId, 10);
      }

      this.setState({ bookingToUpdate: changedBooking });

      this.setState({ alertDialogVisible: true });
    }
  };

  // Maintains ConflictArray by searching for doublets
  filterSetConflictBookingRemaining = (conflictingBookingArray: SchedulerEvent[]) => {
    const seen = new Set();

    if (this.state.conflictBookingRemaining.length === 0) {
      this.setState({ conflictBookingRemaining: conflictingBookingArray }, () => {
        this.handleConflictArray();
      });
    } else {
      const combinedLists = this.state.conflictBookingRemaining.concat(conflictingBookingArray);
      const filteredArr = combinedLists.filter((booking) => {
        const duplicate = seen.has(booking.id);
        seen.add(booking.id);
        return !duplicate;
      });

      this.setState({ conflictBookingRemaining: filteredArr }, () => {
        this.handleConflictArray();
      });
    }
  };

  // takes conflictBooking from the array and forwards the information to DialogFormModal
  handleConflictArray = () => {
    const index = this.state.conflictBookingRemaining.length - 1;

    if (index > -1) {
      const conflictingBooking = this.state.conflictBookingRemaining[index];

      this.setState((state) => ({
        inputBooking: {
          ...state.inputBooking,
          schedulerData: this.schedulerData,
          resourceId: conflictingBooking.resourceId,
          eventId: conflictingBooking.id,
          start: new Date(conflictingBooking.start),
          end: new Date(conflictingBooking.end),
          type: this.checkType(conflictingBooking.type),
          title: conflictingBooking.title,
          description: conflictingBooking.description,
          existingEvent: true,
          projectId: conflictingBooking.projectId,
          groupId: this.checkIdNumber(conflictingBooking.groupEventId)
        }
      }));
      this.setState({ conflictNeededToBeHandled: true });
      const remainingConflicts = this.state.conflictBookingRemaining.filter(
        (item) => item.start !== conflictingBooking.start && item.end !== conflictingBooking.end
      );
      this.setState({ conflictBookingRemaining: remainingConflicts }, () => {
        this.setState({ bookingFormModal: true });
      });
    }
  };

  // checks the recurringBookings for potential conflicts
  conflictCheckRecurringBookings = (incomingBookings: Booking[]) => {
    let hasConflict = false;
    const conflictingBookingEvent: Booking[] = [];

    incomingBookings.forEach((incomingBooking: Booking) => {
      this.state.existingBookings.forEach((existingBooking) => {
        if (
          incomingBooking.personId === parseInt(existingBooking.resourceId, 10) &&
          incomingBooking.id !== parseInt(existingBooking.id, 10)
        ) {
          const eStart = moment(existingBooking.start).toDate();
          const eEnd = moment(existingBooking.end).toDate();
          if (
            (incomingBooking.startDate >= eStart && incomingBooking.startDate < eEnd) ||
            (incomingBooking.endDate > eStart && incomingBooking.endDate <= eEnd) ||
            (eStart >= incomingBooking.startDate && eStart < incomingBooking.endDate) ||
            (eEnd > incomingBooking.startDate && eEnd <= incomingBooking.endDate)
          ) {
            hasConflict = true;
            conflictingBookingEvent.push(incomingBooking);
          }
        }
      });
    });

    if (hasConflict) {
      // eslint-disable-next-line no-param-reassign
      incomingBookings = incomingBookings.filter((booking) => !conflictingBookingEvent.includes(booking));

      const conflictEvents = this.convertBookingToEvent(conflictingBookingEvent);
      this.setState({ conflictNeededToBeHandled: true });
      this.filterSetConflictBookingRemaining(conflictEvents);
    }
    return incomingBookings;
  };

  // sets the visibility of the FormDialogModal and communicates if existing conflicts needs to be handled
  handleModelVisibility = (
    openModal: boolean,
    hasBookingConflict: boolean,
    existingConflicts: SchedulerEvent[],
    conflictBookingId?: string
  ) => {
    this.setState({ bookingFormModal: openModal });
    this.setState({ conflictBookingRemaining: existingConflicts });

    if (hasBookingConflict) {
      if (conflictBookingId === undefined) {
        this.setState({ conflictNeededToBeHandled: false });
      } else {
        const updatedConflictList = this.state.conflictBookingRemaining.filter((item) => item.id !== conflictBookingId);

        if (updatedConflictList.length === 0) this.setState({ conflictNeededToBeHandled: false });
        else {
          this.setState({ conflictBookingRemaining: updatedConflictList }, () => {
            this.handleConflictArray();
          });
        }
      }
    }
  };

  constructor(props: Readonly<{}>) {
    super(props);
    // To set locale
    moment.locale('da');

    this.state = {
      // SchedulerState
      viewModel: this.schedulerData,
      persons: [],
      existingBookings: [],
      bookingToUpdate: new Booking(),
      projects: [],
      fromDate: new Date(),
      // FormDialogState
      conflictNeededToBeHandled: false,
      conflictBookingRemaining: [],
      bookingFormModal: false,

      // AlertDialog State
      alertDialogVisible: false,
      alertTitle: '',
      alertDescription: '',
      alertinformation: ''
    };
  }

  async componentDidMount() {
    if (this.state.persons && this.state.persons.length) {
      const personList = await personService.getActive();
      this.setState({ persons: personList.filter((person) => person.canBeBooked) });
    }

    if (this.state.persons && this.state.persons.length) {
      const projectList = await projectService.getActiveProjects();
      this.setState({ projects: projectList });
    }

    if (this.state.fromDate !== null) {
      const bookingList = await bookingService.getBookingFromDate(this.state.fromDate);

      this.schedulerData.setResources(this.convertPersonToResource(this.state.persons));
      this.setState({ existingBookings: this.convertBookingToEvent(bookingList) });
      this.schedulerData.setEvents(this.state.existingBookings);
      this.setState({ viewModel: this.schedulerData });
    }
  }

  // Converting database booking into Events used in this module
  convertBookingToEvent(BookingList: Booking[]) {
    const events: SchedulerEvent[] = [];
    BookingList.forEach((booking) => {
      if (booking.id === undefined) {
        booking.id = 0;
      }
      if (
        booking.startDate !== undefined &&
        booking.endDate !== undefined &&
        booking.personId !== undefined &&
        booking.title !== undefined
      ) {
        const event: SchedulerEvent = {
          id: booking.id.toString(),
          start: booking.startDate.toISOString(),
          end: booking.endDate.toISOString(),
          resourceId: booking.personId.toString(),
          title: booking.title
        };
        if (booking.type !== undefined) {
          event.type = booking.type;
          if (this.changeColorByType(booking.type) !== 'false') {
            event.bgColor = this.changeColorByType(booking.type);
          }
        }
        if (booking.rRule !== undefined) {
          event.rrule = booking.rRule;
        }
        if (booking.projectId !== undefined) {
          event.projectId = booking.projectId;
        }
        if (booking.groupId !== undefined) {
          event.groupEventId = booking.groupId;
        }

        events.push(event);
      }
    });
    return events;
  }

  // defining the color of the bookingTypes
  changeColorByType(type: number) {
    switch (type) {
      case 1:
        return TypeColorCustomer;
      case 2:
        return TypeColorHoliday;
      case 3:
        return TypeColorInternal;

      default:
        return 'false';
    }
  }

  // Converting database Persons into "Resources" used in this module
  convertPersonToResource(PersonList: Person[]) {
    const ressources: Resource[] = [];
    PersonList.forEach((person) => {
      if (person.id !== undefined && person.name !== undefined) {
        const resource: Resource = { id: person.id?.toString(), name: person.name };
        ressources.push(resource);
      }
    });
    return ressources;
  }

  async prevClick(schedulerData: SchedulerData) {
    schedulerData.prev();

    this.setState({ fromDate: parse(schedulerData.startDate, 'yyyy-MM-dd', new Date()) });
    const dateFrom = parse(schedulerData.startDate, 'yyyy-MM-dd', new Date());
    if (dateFrom !== null) {
      const bookingList = await bookingService.getBookingFromDate(dateFrom);
      this.setState({ existingBookings: this.convertBookingToEvent(bookingList) }, () =>
        this.schedulerData.setEvents(this.state.existingBookings)
      );
      this.setState({
        viewModel: schedulerData
      });
    }
  }

  nextClick(schedulerData: SchedulerData) {
    schedulerData.next();
    schedulerData.setEvents(this.state.existingBookings);
    this.setState({
      viewModel: schedulerData
    });
  }

  onViewChange(schedulerData: SchedulerData, view: { viewType: any; showAgenda: any; isEventPerspective: any }) {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.state.existingBookings);
    this.setState({
      viewModel: schedulerData
    });
  }

  async onSelectDate(schedulerData: SchedulerData, date: string) {
    schedulerData.setDate(date);

    this.setState({ fromDate: parse(schedulerData.startDate, 'yyyy-MM-dd', new Date()) });
    const dateFrom = parse(schedulerData.startDate, 'yyyy-MM-dd', new Date());
    if (dateFrom !== null) {
      const bookingList = await bookingService.getBookingFromDate(dateFrom);
      this.setState({ existingBookings: this.convertBookingToEvent(bookingList) }, () =>
        this.schedulerData.setEvents(this.state.existingBookings)
      );
      this.setState({
        viewModel: schedulerData
      });
    }
  }

  eventClicked(schedulerData: SchedulerData, event: SchedulerEvent) {
    const eventToUpdate = this.state.existingBookings.find((booking) => booking.id === event.id);
    if (eventToUpdate !== undefined) {
      this.setState((state) => ({
        inputBooking: {
          ...state.inputBooking,
          schedulerData,
          resourceId: event.resourceId,
          eventId: event.id,
          start: new Date(event.start),
          end: new Date(event.end),
          type: this.checkType(event.type),
          title: event.title,
          description: event.description,
          existingEvent: true,
          projectId: event.projectId,
          groupId: this.checkIdNumber(event.groupEventId)
        }
      }));
      this.setState({ bookingFormModal: true });
    }
  }

  async updateDatabaseEvent(booking: Booking) {
    if (booking.id === undefined || booking.id === 0) {
      booking.id = undefined;
      if (booking.rRule === '' || booking.rRule === undefined) {
        await bookingService.create(booking);
        this.getNewestBookings();
      } else {
        this.handleRecurringEvents(booking);
      }
    } else if (booking.rRule === '' || booking.rRule === undefined) {
      await bookingService.update(booking);
      this.getNewestBookings();
    } else {
      this.handleRecurringEvents(booking);
    }
    if (this.state.conflictBookingRemaining.length > 0) {
      this.handleConflictArray();
    }
  }

  async handleAlertDialogAccept(bookingToChange?: Booking) {
    if (bookingToChange !== undefined) {
      await bookingService.update(this.state.bookingToUpdate);
      this.getNewestBookings();
    }
  }

  async deleteBooking(booking: Booking) {
    if (booking.id === undefined) {
      return;
    } else {
      await bookingService.deleteBooking(booking);
    }
    this.getNewestBookings();
  }

  async deleteGroupBookings(groupId: number) {
    if (groupId === 0) {
      return;
    } else {
      await bookingService.deleteGroupedBookings(groupId);
    }
    this.getNewestBookings();
  }

  newEvent(
    schedulerData: SchedulerData,
    slotId: string,
    _slotName: string,
    start: string,
    end: string,
    type: string,
    item: EventGroup | SchedulerEvent
  ) {
    this.setState((state) => ({
      inputBooking: {
        ...state.inputBooking,
        schedulerData,
        resourceId: slotId,
        start: new Date(start),
        end: new Date(end),
        type: '1',
        existingEvent: false,
        title: '',
        description: '',
        projectId: undefined,
        groupId: 0
      }
    }));
    this.setState({ bookingFormModal: true });
  }

  updateEventStart(schedulerData: SchedulerData, event: SchedulerEvent, newStart: string) {
    this.setState({ alertTitle: 'Change Booking Start Time' });
    this.setState({
      alertDescription: `Do you want to adjust the start of the Booking?`
    });
    this.setState({
      alertinformation: `Title: ${event.title}, to have start date: ${newStart}}`
    });
    this.updateAndConvertSchedulerEventToBooking(event, newStart);
  }

  updateEventEnd(schedulerData: SchedulerData, event: SchedulerEvent, newEnd: string) {
    this.setState({ alertTitle: 'Change Booking End Time' });
    this.setState({
      alertDescription: `Do you want to adjust the start of the event?`
    });
    this.setState({
      alertinformation: `Title: ${event.title}, to have end date: ${newEnd}}`
    });
    this.updateAndConvertSchedulerEventToBooking(event, undefined, newEnd);
  }

  moveEvent(schedulerData: SchedulerData, event: SchedulerEvent, slotId: string, slotName: string, start: string, end: string) {
    this.setState({ alertTitle: 'Change Booking Time' });
    this.setState({
      alertDescription: `Do you want to move the booking? `
    });
    this.setState({
      alertinformation: `Title: ${event.title} -  Start: ${start}, End: ${end}`
    });
    this.updateAndConvertSchedulerEventToBooking(event, start, end, slotId);
  }

  async goToConflictingBooking(updateBooking: Booking, conflictingBookingArray: SchedulerEvent[]) {
    await this.updateDatabaseEvent(updateBooking);

    this.filterSetConflictBookingRemaining(conflictingBookingArray);
  }

  // creating multiple Booking if a booking is set to be recurring
  async handleRecurringEvents(booking: Booking) {
    const windowEnd = moment(booking.endDate).add(180, 'days');
    const oldStart = moment(booking.startDate);
    const rule = rrulestr(booking.rRule!);

    rule.options.dtstart = oldStart.toDate();
    if (!rule.origOptions.until) {
      rule.options.until = windowEnd.toDate();
    }

    rule.options.byminute = [booking.startDate.getUTCMinutes()];
    rule.options.byhour = [booking.startDate.getUTCHours()];
    rule.options.bysecond = [0];

    const all = rule.all();
    const newEvents = all.map((time) => {
      return {
        ...booking,
        startDate: moment(time).format(DATETIME_FORMAT),
        endDate: moment(time).add(8, 'h').format(DATETIME_FORMAT)
      };
    });

    const groupId = await bookingService.getLatestID();

    const bookingList: Booking[] = [];
    newEvents.forEach((newEvent) => {
      const newBooking = new Booking();
      newBooking.title = newEvent.title;
      newBooking.type = newEvent.type;
      newBooking.projectId = newEvent.projectId;
      newBooking.personId = newEvent.personId;
      newBooking.endDate = new Date(newEvent.endDate);
      newBooking.startDate = new Date(newEvent.startDate);
      newBooking.groupId = groupId;

      bookingList.push(newBooking);
    });
    const bookingListWithoutConflict = this.conflictCheckRecurringBookings(bookingList);
    if (bookingListWithoutConflict.length > 0) {
      await bookingService.listCreate(bookingListWithoutConflict);
      this.getNewestBookings();
    }
  }

  public render() {
    const { viewModel } = this.state;
    return (
      <div>
        {this.state.persons && this.state.projects ? (
          <div>
            <Scheduler
              schedulerData={viewModel}
              prevClick={this.prevClick.bind(this)}
              nextClick={this.nextClick.bind(this)}
              onSelectDate={this.onSelectDate.bind(this)}
              onViewChange={this.onViewChange.bind(this)}
              eventItemClick={this.eventClicked.bind(this)}
              updateEventStart={this.updateEventStart.bind(this)}
              updateEventEnd={this.updateEventEnd.bind(this)}
              moveEvent={this.moveEvent.bind(this)}
              newEvent={this.newEvent.bind(this)}
            />

            {this.state.bookingFormModal && this.state.inputBooking !== undefined && (
              <BookingFormDialog
                inputBooking={this.state.inputBooking}
                visible={this.state.bookingFormModal}
                setVisible={(openModal, bookingConflict, idString) => this.handleModelVisibility(openModal, bookingConflict, idString)}
                setBookingDetails={(bookingEvent) => this.updateDatabaseEvent(bookingEvent)}
                deleteBooking={(bookingEvent) => this.deleteBooking(bookingEvent)}
                deleteGroupBookings={(bookingEvent) => this.deleteGroupBookings(bookingEvent)}
                persons={this.state.persons}
                projects={this.state.projects}
                possibleConflicts={this.state.existingBookings}
                goToConflictingBooking={(booking, conflictingBookings) => this.goToConflictingBooking(booking, conflictingBookings)}
                conflictMustBeHandled={this.state.conflictNeededToBeHandled}
                existingConflicts={this.state.conflictBookingRemaining}
              />
            )}

            {this.state.alertDialogVisible && this.state.bookingToUpdate !== undefined && (
              <AlertDialog
                acceptRequest={this.handleAlertDialogAccept}
                visible={this.state.alertDialogVisible}
                setVisible={(val) => this.setState({ alertDialogVisible: val })}
                title={this.state.alertTitle}
                description={this.state.alertDescription}
                information={this.state.alertinformation}
                BookingToChange={this.state.bookingToUpdate}
                sendBooking={(booking) => this.handleAlertDialogAccept(booking)}
              />
            )}
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    );
  }
}

export default withDragDropContext(BookingUI);
