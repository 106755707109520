import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { customerService } from '../../../services/customer.services';
import { Customer } from '../../../services/http-services/api';
import { TableStyles, DataGridStyle } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function CustomerList() {
  const [customers, setCustomers] = useState<Customer[] | null>();
  const [savedPage, setSavedPage] = useState(0);
  const [searchName, setSearchName] = useState('');
  async function getCustomers() {
    const customerList = await customerService.getAll();
    setCustomers(customerList);
  }

  const loadSavedPage = () => {
    if (localStorage.page) {
      setSavedPage(parseInt(localStorage.page, 10));
      return savedPage;
    }
    return savedPage;
  };

  React.useEffect(() => {
    loadSavedPage();
  }, []);

  const handlePageChange = (page: number) => {
    localStorage.page = page;
    setSavedPage(page);
  };

  React.useEffect(() => {
    getCustomers();
  }, [setCustomers]);

  React.useEffect(() => {
    document.title = 'Cenvation - Customers';
  }, []);

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  function changeSearchVal(event: string) {
    setSearchName(event);
  }

  const customerActive = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: localized('Id'), width: 200 },
    { field: 'name', headerName: localized('CustomerName'), width: 350 },
    {
      field: 'active',
      headerName: localized('Active'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{customerActive(params.value)}</div>;
      }
    },
    {
      field: 'invoice',
      headerName: localized('SeeInvoice'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <Link className={classes.removeUnderline} to={{ pathname: `/customer-bills/${params.row['id']}` }}>
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Invoice')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'project',
      headerName: localized('SeeProjects'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/customer-projects/${params.row['id']}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Projects')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'update',
      headerName: localized('Update'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/customerEdit/${params.row['id']}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Update')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'copy',
      headerName: localized('MakeCopy'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/customerCopy/${params.row['id']}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Copy')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'budgetOverview',
      headerName: localized('Budget'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        if (params.row['active'] === true) {
          return (
            <Link
              className={classes.removeUnderline}
              to={{
                pathname: `/management-project/${params.row['id']}`
              }}
            >
              <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
                {localized('Budget')}
              </Button>
            </Link>
          );
        }
        return <div></div>;
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('ViewAllCustomer')}
      </Typography>
      {customers && (
        <Box margin={2}>
          <TextField
            id="standard-basic"
            value={searchName}
            label={localized('SearchCustName')}
            onChange={(event) => changeSearchVal(event.target.value)}
          />
        </Box>
      )}
      {customers ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {customers ? (
              <DataGrid
                page={savedPage}
                className={classesGrid.root}
                rows={customers}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageChange={handlePageChange}
                filterModel={{
                  items: [{ columnField: 'name', operatorValue: 'contains', value: searchName }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(CustomerList);
