import { decodeToken, getTokenFromLocaleStorage } from '../utilities/auth-helper';
import { getEmailFromToken, getRoleFromToken, readCookie } from '../utilities/token-util';

import { AuthenticateRequest, Client, RevokeTokenRequest } from './http-services/api';

async function login(email: string, password: string) {
  const client = new Client();

  try {
    const result = await client.authenticate(new AuthenticateRequest({ email, password }));

    if (result.jwtToken !== undefined) {
      localStorage.setItem('currentUser', result.jwtToken);
    }

    return true;
  } catch (error) {
    return false;
  }
}

function isAuthenticated() {
  const token = getTokenFromLocaleStorage();
  if (token === false) {
    return false;
  }

  if (token !== undefined) {
    const jwtToken = decodeToken(token);
    const expiresAt = jwtToken.exp;
    const expiresAtDate = new Date(expiresAt * 1000);

    if (expiresAt && expiresAtDate) {
      const timeToRenewal = expiresAtDate.getTime() - new Date().getTime();
      if (timeToRenewal > 0) {
        return true;
      }
    }
  }
  return false;
}

function getUser() {
  const token = getTokenFromLocaleStorage();
  if (token === false) {
    return false;
  } else {
    const user = getEmailFromToken(token);
    return user;
  }
}

async function getUserId() {
  const client = new Client();
  const userName = getUser();
  if (userName === false) {
    return undefined;
  } else {
    const result = await client.personIdByName(userName);
    return result;
  }
}

function getUserRole() {
  const token = getTokenFromLocaleStorage();
  if (token === false) {
    return '-1';
  } else {
    const role = getRoleFromToken(token);
    return role;
  }
}

async function getNewToken() {
  const client = new Client();

  try {
    const result = await client.refreshToken();

    if (result.jwtToken) localStorage.setItem('currentUser', result.jwtToken);

    return result;
  } catch (error) {
    return error;
  }
}

async function logout() {
  const client = new Client();
  const refreshtoken = new RevokeTokenRequest();
  const token = readCookie('refreshToken');
  if (token) {
    refreshtoken.token = token;
  }
  try {
    await client.revokeToken(refreshtoken);

    localStorage.removeItem('currentUser');

    return null;
  } catch (error) {
    return error;
  }
}

export const authenticationService = {
  login,
  logout,
  isAuthenticated,
  getUser,
  getNewToken,
  getUserRole,
  getUserId
};
