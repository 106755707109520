import { Button, Snackbar, TextField, Grid, Typography, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';

import {
  Person,
  Booking,
  Project,
  ProjectDTO,
  TimerecordRequest,
  Customer,
  ProjectSubscriptionDTO,
  Timerecord
} from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { projectSubscriptionService } from '../../../services/projectsubscription.service';
import { timerecordService } from '../../../services/timerecord.services';
import { authenticationService } from '../../../services/auth.services';
import { customerService } from '../../../services/customer.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { personService } from '../../../services/person.services';
// import { projectSubscriptionService } from '../../../services/projectsubscription.service';

const useStyles = makeStyles({
  centerjust: {
    display: 'flex',
    justifyContent: 'center'
  },
  padding50: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    paddingTop: 10
  }
});

interface TimerecordEditProps {
  timerecordId: string;
}

function TimerecordEdit() {
  const [selectedTimeRecord, setSelectedTimeRecord] = React.useState<Timerecord | null>();
  const [allSubscriptions, setAllSubscriptions] = React.useState<ProjectSubscriptionDTO[] | null>();
  const [allCustomers, setAllCustomers] = React.useState<Customer[] | null>();
  const [allProjects, setAllProjects] = React.useState<Project[] | null>();
  const [selectedConsultant, setSelectedConsultant] = React.useState<Person | null>();
  const [consultants, setConsultants] = React.useState<Person[] | null>();
  const [projects, setProjects] = React.useState<Project[] | null>();
  const [filteredProjects, setFilteredProjects] = React.useState<Project[] | null>();
  const [selectedProject, setSelectedProject] = React.useState<Project | null>();
  //const [selectedName, setSelectedName] = React.useState('');
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer | null>();
  const [filteredCustomers, setFilteredCustomers] = React.useState<Customer[] | null>();
  const [date, setDate] = React.useState<Date | null>(new Date());
  //const [names, setNames] = React.useState(['']);
  //const [selectedProjectName, setSelectedProjectName] = React.useState('');
  //  const [customerName, setCustomerName] = React.useState('');
  const history = useHistory();
  const [bookings] = React.useState<Booking[] | null>();
  const [latestProject] = React.useState<ProjectDTO>();
  const [hours, setHours] = React.useState<number>(8);
  const [apRef, setAPRef] = React.useState<number>(0);
  const [ceRef, setCERef] = React.useState<number>(0);
  const [comment, setComment] = React.useState('');
  const [userId, setUserId] = React.useState<number | undefined>(0);
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  const classes = useStyles();
  const params = useParams<TimerecordEditProps>();
  //  Loads all projects to avoid multiple queries

  function SortAlphabetically(list: Project[]) {
    list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return list;
  }

  function isAdmin() {
    const role = authenticationService.getUserRole();
    if (role === 'Admin') {
      return true;
    } else {
      return false;
    }
  }

  const handleProjectChange = (event: any, value: Project | null) => {
    if (value) {
      setSelectedProject(value);
      //setSelectedProjectName(tempproj[0].name);
    } else {
      //setSelectedProjectName('');
      setSelectedProject(null);
    }
  };

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setSelectedCustomer(value);
      setSelectedProject(null);
    } else {
      //setSelectedName("")
      setFilteredProjects(null);
      setSelectedProject(null);
    }
  };

  React.useEffect(() => {
    async function loadProjects() {
      const projectList = await projectService.getAll();
      const ActiveProjects = projectList.filter((proj) => proj.active);
      setAllProjects(ActiveProjects);
    }
    loadProjects();

    async function loadCustomers() {
      const result = await customerService.getActiveCustomerOrderedByName();
      result.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setAllCustomers(result);
    }
    async function loadConsultants() {
      const result = await personService.getAll();
      setConsultants(result.filter((pers) => pers.active === true));
    }
    async function loadUserId() {
      const tempUserId = await authenticationService.getUserId();
      setUserId(tempUserId);
    }
    loadUserId();
    loadCustomers();
    loadConsultants();
  }, []);

  React.useEffect(() => {
    async function loadSubscriptions() {
      if (!selectedConsultant) {
        return;
      }
      const result = await projectSubscriptionService.getById(selectedConsultant!.id!);
      setAllSubscriptions(result);
    }
    loadSubscriptions();
  }, [selectedConsultant]);

  React.useEffect(() => {
    function loadNames() {
      if (allSubscriptions && allProjects) {
        const Subscriptions = allSubscriptions!
          .filter((subscription) => subscription.subscribed === true)
          .map((subscription) => subscription.projectId);
        const Projects = allProjects!.filter((proj) => Subscriptions.includes(proj.id));
        const retarr: Customer[] = [];
        if (Projects) {
          Projects.forEach((element) => {
            if (element.customer) {
              retarr.push(element.customer);
            }
          });
          const ids = retarr.map((cust) => cust.id);
          const customerids = Array.from(new Set(ids));
          const customers: Customer[] = [];
          customerids.forEach((element) => {
            customers.push(retarr.filter((cust) => cust.id === element)[0]);
          });
          customers.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setFilteredCustomers(customers!);
        }
      }
    }
    loadNames();
    async function loadProjects() {
      if (!allSubscriptions || !allProjects) {
        return;
      }
      const Subscriptions = allSubscriptions!
        .filter((subscription) => subscription.subscribed === true)
        .map((subscription) => subscription.projectId);
      const Projects = allProjects!.filter((proj) => Subscriptions.includes(proj.id) && proj.active);
      if (bookings && bookings[0] && bookings[0].projectId) {
        const currProj = await projectService.getProjectById(bookings[0].projectId);
        if (currProj && Subscriptions.includes(currProj.id)) {
          setSelectedProject(currProj);
          //setSelectedProjectName(currProj.name);
        } else if (latestProject && latestProject.id && Subscriptions.includes(latestProject.id)) {
          const currProj = await projectService.getProjectById(latestProject.id);
          if (currProj) {
            setSelectedProject(currProj);
            //setSelectedProjectName(currProj.name);
          }
        }
      }

      setFilteredProjects(SortAlphabetically(Projects));
      setProjects(SortAlphabetically(Projects));
    }
    loadProjects();
  }, [allSubscriptions, allProjects]);

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(parseFloat(event.target.value));
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  React.useEffect(() => {
    function loadProjects() {
      if (selectedCustomer && allCustomers) {
        const customer = allCustomers.filter((cust) => cust.id === selectedCustomer.id)[0];
        const tempProjectList: Project[] = [];
        if (projects) {
          projects.forEach((element) => {
            if (element.customerId && element.customerId === customer.id) {
              tempProjectList.push(element);
            }
          });
          if (!selectedProject) {
            setSelectedProject(tempProjectList[0]);
            //setSelectedProjectName(tempProjectList[0].name);
          }
          setFilteredProjects(SortAlphabetically(tempProjectList));
        }
      }
      return null;
    }
    loadProjects();
  }, [selectedCustomer, allCustomers]);

  React.useEffect(() => {
    function loadCustomerName() {
      if (selectedProject !== null && selectedProject?.customerId !== undefined && selectedProject?.customerId !== null && projects) {
        const customerNameData = allCustomers!.filter((cust) => cust.id === selectedProject.customerId)[0];
        setSelectedCustomer(customerNameData);
      }
      return null;
    }
    loadCustomerName();
  }, [selectedProject, projects]);

  const handleConsultantChange = (event: any, newConsultant: Person | null) => {
    setSelectedProject(null);
    setSelectedCustomer(null);
    //setSelectedName('');
    //setSelectedProjectName('');
    if (newConsultant) {
      setSelectedConsultant(newConsultant);
    } else {
      setSelectedConsultant(null);
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate);
  };

  const handleSubmit = async (event: any) => {
    if (!date) {
      return;
    }

    event.persist();

    if (selectedProject === null || selectedProject!.id! === undefined) {
      setSnackBarMessage(localized('ProjectIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(hours) || hours === 0) {
      setSnackBarMessage(localized('HoursIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (hours < 0 && !isAdmin()) {
      setSnackBarMessage(localized('HoursNegative'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (selectedProject!.timeRegNeedComment! === true && (comment === undefined || comment === '')) {
      setSnackBarMessage(localized('RegistrationForProjectRequiresComment'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    let result = new Timerecord();

    //  Added because of update issue
    date.setHours(12);
    date.setUTCHours(0);
    if (selectedProject !== null && selectedProject!.id!) {
      result = await timerecordService.updateTimerecord(
        parseInt(params.timerecordId, 10),
        new TimerecordRequest({
          personId: selectedConsultant!.id,
          projectId: selectedProject!.id!,
          date,
          hours,
          comment,
          CEref: ceRef,
          APref: apRef,
          changed: new Date()
        })
      );
    }

    if (result.id) {
      if (userId !== selectedConsultant!.id!) {
        history.push(`/timerecords/${selectedConsultant!.id}`);
      } else {
        history.push(`/create-timerecord-overview`);
      }
    } else {
      setSnackBarMessage(localized('ErrorCreatingTimeRecord'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleCEChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCERef(parseInt(event.target.value, 10));
  };

  const handleAPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAPRef(parseInt(event.target.value, 10));
  };

  React.useEffect(() => {
    async function loadTimerecord() {
      if (allCustomers && consultants && allProjects) {
        const timerecordObject = await timerecordService.getTimerecordById(parseInt(params.timerecordId, 10));
        setSelectedTimeRecord(timerecordObject);
        //  if (timerecordObject.id) setTimerecordId(timerecordObject.id);
      }
    }
    loadTimerecord();
  }, [allCustomers, consultants, allProjects]);

  React.useEffect(() => {
    function setTimerecordValues() {
      if (selectedTimeRecord) {
        setDate(selectedTimeRecord.date);
        setHours(selectedTimeRecord.hours);
        setSelectedProject(selectedTimeRecord!.project!);
        //setSelectedName(allCustomers!.filter(cust => cust.id === selectedTimeRecord!.project!.customerId!)[0].name)
        //setSelectedProjectName(selectedTimeRecord!.project!.name!);
        setSelectedConsultant(consultants!.filter((pers) => pers.id === selectedTimeRecord.personId)[0]);
        if (selectedTimeRecord.comment) setComment(selectedTimeRecord.comment);
        if (selectedTimeRecord.apRef) setAPRef(selectedTimeRecord.apRef);
        if (selectedTimeRecord.ceRef) setCERef(selectedTimeRecord.ceRef);
        if (selectedTimeRecord.hours) setHours(selectedTimeRecord.hours);
      }
    }
    setTimerecordValues();
  }, [selectedTimeRecord]);

  const handleDelete = async (event: any) => {
    await timerecordService.Delete(parseInt(params.timerecordId, 10));
    if (userId !== selectedConsultant!.id!) {
      history.push(`/timerecords/${selectedTimeRecord!.personId!}`);
    } else {
      history.push(`/create-timerecord-overview`);
    }
  };

  const setNameSelector = () => {
    const consultantNames: string[] = [];
    consultants!.forEach((consultant) => {
      consultantNames.push(consultant.name);
    });
    return (
      <Grid item xs={12} className={classes.padding50}>
        <Autocomplete
          id="TimeRecord-select-Name"
          options={consultants ? consultants : []}
          getOptionLabel={(option) => (option ? option.name : '')}
          value={selectedConsultant ? selectedConsultant : null}
          renderInput={(inputParams) => <TextField required {...inputParams} label={localized('SelectConsultant')} variant="outlined" />}
          onChange={handleConsultantChange}
        />
      </Grid>
    );
  };
  
  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <form className="timerecord" noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('RegisterWork')}
      </Typography>
      {projects ? (
        selectedTimeRecord?.status === 'Created' ? (
          <div>
            <Grid container justify="center">
              <Grid item xs={12}></Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} className={classes.padding50}>
                  <KeyboardDatePicker
                    disableToolbar
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={localized('Date')}
                    value={date}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    TextFieldComponent={(inputParams) => <TextField onKeyDown={onKeyDown} fullWidth required {...inputParams} variant="outlined" />}
                  />
                </Grid>
                <Grid item xs={12} className={classes.padding50}>
                  <Autocomplete
                    id="TimeRecord-select-customer"
                    options={filteredCustomers ? filteredCustomers : []}
                    getOptionLabel={(option) => (option ? option.name : '')}
                    value={selectedCustomer ? selectedCustomer : null}
                    renderInput={(inputParams) => (
                      <TextField required {...inputParams} label={localized('SelectCustomer')} variant="outlined" />
                    )}
                    onChange={handleCustomerChange}
                  />
                </Grid>
                <Grid item xs={12} className={classes.padding50}>
                  <div className={classes.centerjust}>
                    <Autocomplete
                      id="TimeRecord-select-project"
                      style={{ width: '100%' }}
                      renderInput={(inputParams) => (
                        <TextField required {...inputParams} label={localized('SelectProject')} variant="outlined" />
                      )}
                      onChange={handleProjectChange}
                      value={selectedProject ? selectedProject : null}
                      getOptionLabel={(option) => (option ? option.name : '')}
                      options={filteredProjects ? filteredProjects : []}
                      //  getOptionLabel={(option: Project) => option.name || ''}
                      //  getOptionSelected={(option: Project, value: Project) => option.id === value.id}
                    />
                  </div>
                </Grid>
                {isAdmin() ? setNameSelector() : null}
              </MuiPickersUtilsProvider>
              <Grid item xs={12} className={classes.padding50}>
                <TextField
                  fullWidth
                  required
                  id="hours-number"
                  name="hours"
                  defaultValue={8}
                  label={localized('NumberOfHours')}
                  type="number"
                  variant="outlined"
                  onChange={handleChangeHours}
                  value={hours}
                />
              </Grid>
              <Grid item xs={12} className={classes.padding50}>
                <TextField
                  fullWidth
                  id="comment-text"
                  name="comment"
                  label={localized('Comment')}
                  variant="outlined"
                  type="text"
                  onChange={handleChangeComment}
                  value={comment}
                />
              </Grid>
              <Grid item xs={12} className={classes.padding50}>
                <TextField
                  fullWidth
                  id="CEref-text"
                  name="ceRef"
                  label={localized('CERef')}
                  variant="outlined"
                  type="number"
                  defaultValue={null}
                  value={ceRef}
                  onChange={handleCEChange}
                />
              </Grid>

              <Grid item xs={12} className={classes.padding50}>
                <TextField
                  fullWidth
                  id="APref-text"
                  name="apRef"
                  label={localized('APRef')}
                  variant="outlined"
                  type="number"
                  value={apRef}
                  defaultValue={null}
                  onChange={handleAPChange}
                />
              </Grid>
              <Grid item xs={12} className={classes.padding50}>
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('Update')}
                </Button>
                <Button
                  style={{ marginLeft: 8 }}
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={handleDelete}
                >
                  {localized('Delete')}
                </Button>
              </Grid>
              <Grid>
                <p className="footnote">{localized('Created') + ': ' + selectedTimeRecord!.created.toLocaleString()}</p>
                <p className="footnote">
                  {localized('Changed') + ': ' + (selectedTimeRecord.changed ? selectedTimeRecord!.changed!.toLocaleString() : '----')}
                </p>
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity={snackBarSeverity} variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid item xs={12} className={classes.padding50}>
              <TextField
                fullWidth
                id="APref-text"
                name="apRef"
                label={localized('APRef')}
                variant="outlined"
                type="number"
                value={apRef}
                defaultValue={null}
                onChange={handleAPChange}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <Button
                variant="contained"
                color="primary"
                size={buttonSize}
                className="button"
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              >
                {localized('Update')}
              </Button>
            </Grid>
          </div>
        )
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default withRouter(TimerecordEdit);
