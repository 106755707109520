import { Button, Snackbar, TextField, Grid, Typography, CircularProgress } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import { intervalToDuration, subWeeks } from 'date-fns';
import parse from 'date-fns/parse';

import {
  Person,
  Booking,
  Project,
  ProjectDTO,
  TimerecordRequest,
  Customer,
  ProjectSubscriptionDTO
} from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { projectSubscriptionService } from '../../../services/projectsubscription.service';
import { timerecordService } from '../../../services/timerecord.services';
import { authenticationService } from '../../../services/auth.services';
import { bookingService } from '../../../services/booking.services';
import MultipleBookings from '../../../components/time-record-modal/multiple-bookings';
import { customerService } from '../../../services/customer.services';
import { buttonSize, mobileMaxWidth } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { personService } from '../../../services/person.services';
import { useWindowWidth } from '../../../utilities/use-window-width';
// import { projectSubscriptionService } from '../../../services/projectsubscription.service';

const useStyles = makeStyles({
  centerjust: {
    display: 'flex',
    justifyContent: 'center'
  },
  padding50: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    paddingTop: 10
  }
});

interface CreateTimerecordPros {
  dateString: string | undefined;
}

function CreateTimerecord() {
  const [allSubscriptions, setAllSubscriptions] = React.useState<ProjectSubscriptionDTO[] | null>();
  const [allCustomers, setAllCustomers] = React.useState<Customer[] | null>();
  const [allProjects, setAllProjects] = React.useState<Project[] | null>();
  const [selectedConsultant, setSelectedConsultant] = React.useState<Person | null>();
  const [consultants, setConsultants] = React.useState<Person[] | null>();
  const [projects, setProjects] = React.useState<Project[] | null>();
  const [filteredProjects, setFilteredProjects] = React.useState<Project[] | null>();
  const [selectedProject, setSelectedProject] = React.useState<Project | null>();
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer | null>();
  const [filteredCustomers, setFilteredCustomers] = React.useState<Customer[] | null>();
  const [date, setDate] = React.useState<Date | null>(new Date());
  const history = useHistory();
  const [bookings, setBookings] = React.useState<Booking[] | null>();
  const [multipleBookingModal, setMultipleBookingModal] = React.useState(false);
  const [possibleBookingChoices, setPossibleChoices] = React.useState<Booking[]>();
  const [latestProject, setLatestProject] = React.useState<ProjectDTO>();
  const [hours, setHours] = React.useState('8');
  const [apRef, setAPRef] = React.useState<number>();
  const [ceRef, setCERef] = React.useState<number>();
  const [comment, setComment] = React.useState('');
  const [userId, setUserId] = React.useState<number>();
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  const classes = useStyles();
  const params = useParams<CreateTimerecordPros>();
  //  Loads all projects to avoid multiple queries

  function SortAlphabetically(list: Project[]) {
    list.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return list;
  }

  const isAdmin = () => {
    const role = authenticationService.getUserRole();
    if (role === 'Admin') {
      return true;
    } else {
      return false;
    }
  };
  const handleProjectChange = (event: any, value: Project | null) => {
    if (value) {
      setSelectedProject(value);
      //setSelectedProjectName(tempproj[0].name);
    } else {
      //setSelectedProjectName('');
      setSelectedProject(null);
    }
  };

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setSelectedCustomer(value);
      setSelectedProject(null);
    } else {
      //setSelectedName("")
      setFilteredProjects(null);
      setSelectedProject(null);
    }
  };

  React.useEffect(() => {
    async function loadProjects() {
      const projectList = await projectService.getAll();
      const ActiveProjects = projectList.filter((proj) => proj.active);
      setAllProjects(ActiveProjects);
    }
    loadProjects();

    async function loadCustomers() {
      const result = await customerService.getActiveCustomerOrderedByName();
      result.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setAllCustomers(result);
    }
    async function loadConsultants() {
      if (!isAdmin()) {
        return;
      }
      const result = await personService.getAll();
      setConsultants(result.filter((pers) => pers.active === true));
    }
    async function loadUserId() {
      const tempUserId = await authenticationService.getUserId();
      setUserId(tempUserId);
      if (!isAdmin() && tempUserId) {
        const user = await personService.getPersonById(tempUserId);
        setConsultants([user]);
      }
    }
    loadUserId();
    loadCustomers();
    loadConsultants();
  }, []);

  React.useEffect(() => {
    async function loadSubscriptions() {
      if (!selectedConsultant) {
        return;
      }
      const result = await projectSubscriptionService.getById(selectedConsultant!.id!);
      setAllSubscriptions(result);
    }
    loadSubscriptions();
  }, [selectedConsultant]);

  React.useEffect(() => {
    function loadNames() {
      if (allSubscriptions && allProjects) {
        const Subscriptions = allSubscriptions!
          .filter((subscription) => subscription.subscribed === true)
          .map((subscription) => subscription.projectId);
        const Projects = allProjects!.filter((proj) => Subscriptions.includes(proj.id));
        const retarr: Customer[] = [];
        if (Projects) {
          Projects.forEach((element) => {
            if (element.customer) {
              retarr.push(element.customer);
            }
          });
          const ids = retarr.map((cust) => cust.id);
          const customerids = Array.from(new Set(ids));
          var customers: Customer[] = [];
          customerids.forEach((element) => {
            customers.push(retarr.filter((cust) => cust.id === element)[0]);
          });
          customers.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          setFilteredCustomers(customers);
        }
      }
    }
    loadNames();
    async function loadProjects() {
      if (!allSubscriptions || !allProjects) {
        return;
      }
      const Subscriptions = allSubscriptions!
        .filter((subscription) => subscription.subscribed === true)
        .map((subscription) => subscription.projectId);
      const Projects = allProjects!.filter((proj) => Subscriptions.includes(proj.id) && proj.active);
      if (latestProject && latestProject.id && Subscriptions.includes(latestProject.id)) {
        const currProj = await projectService.getProjectById(latestProject.id);
        if (currProj) {
          setSelectedProject(currProj);
          //setSelectedProjectName(currProj.name);
        }
      }
      if (bookings && bookings[0] && bookings[0].projectId) {
        const currProj = await projectService.getProjectById(bookings[0].projectId);
        if (currProj && Subscriptions.includes(currProj.id)) {
          setSelectedProject(currProj);
          //setSelectedProjectName(currProj.name);
        }
      }

      //setFilteredProjects(SortAlphabetically(Projects));
      setProjects(SortAlphabetically(Projects));
    }
    loadProjects();
  }, [allSubscriptions, allProjects, latestProject]);

  const daregex = /^-?\d*,?5?$/;
  const enregex = /^-?\d*\.?5?$/;
  const zeroStart = /^0[0-9]/;

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentLanguage = localized('Language');
    var eventVal = event.target.value;
    if (zeroStart.test(eventVal)) {
      eventVal = eventVal.slice(1);
    }

    if (currentLanguage == 'en' && enregex.test(eventVal)) {
      setHours(eventVal);
    } else if (currentLanguage == 'da' && daregex.test(eventVal)) {
      setHours(eventVal);
    } else if (event.target.value == '') {
      setHours(eventVal);
    } else {
      setHours('0');
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  React.useEffect(() => {
    function loadProjects() {
      if (selectedCustomer && allCustomers) {
        const customer = allCustomers.filter((cust) => cust.id === selectedCustomer.id)[0];
        const tempProjectList: Project[] = [];
        if (projects) {
          projects.forEach((element) => {
            if (element.customerId && element.customerId === customer.id) {
              tempProjectList.push(element);
            }
          });
          if (!selectedProject || (selectedProject && selectedProject.customerId !== customer.id && tempProjectList.length > 0)) {
            setSelectedProject(tempProjectList[0]);
            //setSelectedProjectName(tempProjectList[0].name);
          }
          setFilteredProjects(SortAlphabetically(tempProjectList));
        }
      }
      return null;
    }
    loadProjects();
  }, [selectedCustomer, allCustomers]);

  React.useEffect(() => {
    function loadCustomerName() {
      if (
        selectedProject !== null &&
        selectedProject?.customerId !== undefined &&
        selectedProject?.customerId !== null &&
        allCustomers &&
        projects
      ) {
        const customerNameData = allCustomers!.filter((cust) => cust.id === selectedProject.customerId)[0];
        setFilteredProjects(projects!.filter((p) => p.customerId === customerNameData.id));
        //setSelectedName(customerNameData.name);
        setSelectedCustomer(customerNameData);
      }
      return null;
    }
    loadCustomerName();
  }, [selectedProject, projects]);

  React.useEffect(() => {
    async function loadInitialProject() {
      if (userId !== undefined && consultants !== undefined) {
        setSelectedConsultant(consultants!.filter((consultant) => consultant.id === userId)[0]);
        const projDTO = await timerecordService.getLatestRegistrationFromUser();
        if (projDTO) {
          setLatestProject(projDTO);
        }
        const fromDate = subWeeks(new Date(), 4);
        const bookingResult = await bookingService.getBookingDateAndPersonID(fromDate, userId);
        if (selectedConsultant && selectedConsultant.BookingSuggestion) setBookings(bookingResult);
      }
    }
    document.title = 'Cenvation - Time Registration';
    loadInitialProject();
  }, [consultants, userId]);

  const findSelectProject = (projectId: number) => {
    if (projects === undefined) {
      return null;
    } else {
      const matchingProjs = projects?.find((proj) => proj.id === projectId);
      if (matchingProjs !== undefined) {
        setSelectedProject(matchingProjs);
      }
      return null;
    }
  };

  const deploySugggestions = (suggestion: Booking) => {
    findSelectProject(suggestion.projectId);
    if (suggestion.title !== undefined && selectedConsultant!.BookingSuggestion) {
      const project = allProjects!.filter((p) => p.id === suggestion.projectId)[0];
      const customer = allCustomers!.filter((cust) => cust.id === project.customerId)[0];
      setSelectedProject(project);
      setSelectedCustomer(customer);
    }
    const duration = intervalToDuration({
      start: suggestion.startDate,
      end: suggestion.endDate
    });

    if (duration.hours !== undefined) {
      setHours(duration.hours.toString());
    }
  };

  const checkBookingbyDate = (newDate: Date | null) => {
    if (bookings !== undefined && bookings !== null && bookings.length > 0 && newDate !== undefined && newDate !== null) {
      const personBookings: Booking[] = [];
      bookings.forEach((element) => {
        if (element.startDate.getDate() === newDate.getDate()) {
          personBookings.push(element);
        }
      });

      if (personBookings.length === 1 && allProjects) {
        deploySugggestions(personBookings[0]);
      } else if (personBookings.length > 1) {
        setPossibleChoices(personBookings);
        setMultipleBookingModal(true);
      } else if (latestProject !== undefined && latestProject.id !== undefined) {
        findSelectProject(latestProject.id);
      }
    } else if (latestProject !== undefined && latestProject.id !== undefined) {
      findSelectProject(latestProject.id);
    }
  };

  const handleModalVisibility = () => {
    setMultipleBookingModal(false);
  };

  const handleConsultantChange = (event: any, newConsultant: Person | null) => {
    setSelectedProject(null);
    setSelectedCustomer(null);
    //setSelectedName('');
    //setSelectedProjectName('');
    if (newConsultant) {
      setSelectedConsultant(newConsultant);
    } else {
      setSelectedConsultant(null);
    }
  };

  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate);
    checkBookingbyDate(newDate);
  };

  React.useEffect(() => {
    let dateTime = new Date();
    if (params.dateString) {
      let paramsDate = parse(params.dateString, 'dd-MM-yy', new Date());
      paramsDate = new Date(Date.UTC(paramsDate.getFullYear(), paramsDate.getMonth(), paramsDate.getDate()));
      setDate(paramsDate);
      dateTime = paramsDate;
    } else {
      let today = new Date();
      today = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
      dateTime = today;
    }

    handleDateChange(dateTime);
  }, [bookings, allProjects]);

  const handleSubmit = async (event: any, createNew: boolean) => {
    if (!date) {
      return;
    }

    event.persist();

    if (selectedProject === null || selectedProject!.id! === undefined) {
      setSnackBarMessage(localized('ProjectIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    var hours_f = 0;

    if (localized('Language') == 'da') {
      hours_f = parseFloat(hours.replace(/,/g, '.'));
    } else {
      hours_f = parseFloat(hours);
    }

    if (isNaN(hours_f) || hours_f === 0) {
      setSnackBarMessage(localized('HoursIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (!isAdmin() && hours_f < 0) {
      setSnackBarMessage(localized('HoursNegative'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (selectedProject!.timeRegNeedComment! === true && (comment === undefined || comment === '')) {
      setSnackBarMessage(localized('RegistrationForProjectRequiresComment'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    let result = false;

    //  Added because of update issue
    date.setUTCHours(0);
    if (selectedProject !== null && selectedProject!.id!) {
      result = await timerecordService.createTimerecord(
        new TimerecordRequest({
          personId: selectedConsultant!.id,
          projectId: selectedProject!.id!,
          date,
          hours: hours_f,
          comment,
          CEref: ceRef,
          APref: apRef,
          changed: undefined
        })
      );
    }

    if (result === true && !createNew) {
      if (isAdmin() && selectedConsultant!.role !== 'Admin') {
        history.push(`/timerecords/${selectedConsultant!.id}`);
      } else {
        history.push(`/create-timerecord-overview`);
      }
    } else if (result === true && createNew) {
      setHours('8');
      setComment('');
      setSnackBarMessage(localized('TimeCreated'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    } else {
      setSnackBarMessage(localized('ErrorCreatingTimeRecord'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleCEChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCERef(parseInt(event.target.value, 10));
  };

  const handleAPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAPRef(parseInt(event.target.value, 10));
  };

  const setNameSelector = () => {
    const consultantNames: string[] = [];
    consultants!.forEach((consultant) => {
      consultantNames.push(consultant.name);
    });
    return (
      <Grid item xs={12} className={classes.padding50}>
        <Autocomplete
          id="TimeRecord-select-Name"
          options={consultants ? consultants : []}
          getOptionLabel={(option) => (option ? option.name : '')}
          value={selectedConsultant ? selectedConsultant : null}
          renderInput={(inputParams) => <TextField required {...inputParams} label={localized('SelectConsultant')} variant="outlined" />}
          onChange={handleConsultantChange}
        />
      </Grid>
    );
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const { width } = useWindowWidth();

  return (
    <form className="timerecord" noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('RegisterWork')}
      </Typography>
      {projects ? (
        <div>
          <Grid container>
            <Grid item xs={12}></Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} className={classes.padding50}>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label={localized('Date')}
                  value={date}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  TextFieldComponent={(inputParams) => (
                    <TextField onKeyDown={onKeyDown} fullWidth required {...inputParams} variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.padding50}>
                <Autocomplete
                  id="TimeRecord-select-customer"
                  options={filteredCustomers ? filteredCustomers : []}
                  getOptionLabel={(option) => (option ? option.name : '')}
                  value={selectedCustomer ? selectedCustomer : null}
                  renderInput={(inputParams) => (
                    <TextField required {...inputParams} label={localized('SelectCustomer')} variant="outlined" />
                  )}
                  onChange={handleCustomerChange}
                />
              </Grid>
              <Grid item xs={12} className={classes.padding50}>
                <div className={classes.centerjust}>
                  <Autocomplete
                    id="TimeRecord-select-project"
                    style={{ width: '100%' }}
                    renderInput={(inputParams) => (
                      <TextField required {...inputParams} label={localized('SelectProject')} variant="outlined" />
                    )}
                    onChange={handleProjectChange}
                    value={selectedProject ? selectedProject : null}
                    getOptionLabel={(option) => (option ? option.name : '')}
                    options={filteredProjects ? filteredProjects : []}
                    //  getOptionLabel={(option: Project) => option.name || ''}
                    //  getOptionSelected={(option: Project, value: Project) => option.id === value.id}
                  />
                </div>
              </Grid>
              {isAdmin() ? setNameSelector() : null}
            </MuiPickersUtilsProvider>
            <Grid item xs={12} className={classes.padding50}>
              <TextField
                fullWidth
                required
                id="hours-number"
                name="hours"
                label={localized('NumberOfHours')}
                type="text"
                variant="outlined"
                value={hours}
                onChange={handleChangeHours}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <TextField
                fullWidth
                id="comment-text"
                name="comment"
                label={localized('Comment')}
                variant="outlined"
                type="text"
                required={selectedProject && selectedProject.timeRegNeedComment ? true : false}
                onChange={handleChangeComment}
                value={comment}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <TextField
                fullWidth
                id="CEref-text"
                name="ceRef"
                label={localized('CERef')}
                variant="outlined"
                type="number"
                value={ceRef}
                onChange={handleCEChange}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <TextField
                fullWidth
                id="APref-text"
                name="apRef"
                label={localized('APRef')}
                variant="outlined"
                type="number"
                value={apRef}
                onChange={handleAPChange}
              />
            </Grid>
            {width < mobileMaxWidth ? (
              <Grid container spacing={2} className={classes.padding50}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={(event) => handleSubmit(event, false)}
                  >
                    {localized('Register')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={(event) => handleSubmit(event, true)}
                  >
                    {localized('RegisterOgNy')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.padding50}>
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={(event) => handleSubmit(event, false)}
                >
                  {localized('Register')}
                </Button>
                <Button
                  style={{ marginLeft: 8 }}
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={(event) => handleSubmit(event, true)}
                >
                  {localized('RegisterOgNy')}
                </Button>
              </Grid>
            )}
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={isSnackBarOpen}
              autoHideDuration={2500}
              onClose={handleCloseSnackbar}
            >
              <Alert severity={snackBarSeverity} variant="filled">
                {snackBarMessage}
              </Alert>
            </Snackbar>

            {multipleBookingModal === true && selectedConsultant && selectedConsultant.BookingSuggestion && (
              <MultipleBookings
                visible={multipleBookingModal}
                setVisible={() => handleModalVisibility()}
                bookingPossibilities={possibleBookingChoices}
                setChosenIndex={(selectedBooking) => deploySugggestions(selectedBooking)}
                projects={projects}
              />
            )}
          </Grid>
        </div>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default withRouter(CreateTimerecord);
