import { Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

import BookingUI from '../../components/booking-component/booking-module/booking-ui';
import { localized } from '../../utilities/language/i18n';

const BookingPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Cenvation - Booking';
  }, []);

  return (
    <Box>
      <Typography variant="h4" className="App_pageTitle">
        {localized('PersonBooking')}
      </Typography>

      <Box paddingTop={3} borderColor={'white'} style={{ overflow: 'auto' }}>
        <BookingUI />
      </Box>
    </Box>
  );
};

export default BookingPage;
