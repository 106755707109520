import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TableRow, TableContainer, Table, TableHead, TableBody, CircularProgress, Button, Box, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import { economicServices } from '../../services/economic.services';
import { BillingDTO } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

function BillingOverview() {
  const [billingDtos, setBillingDtos] = React.useState<BillingDTO[] | null>();
  //  const [currentConstant, setCurrentConstant] = React.useState(0);
  async function loadBillingDtos() {
    const billingList = await economicServices.getBillingOverview();
    setBillingDtos(billingList);
  }

  React.useEffect(() => {
    document.title = 'Cenvation - Billing';
  }, []);

  React.useEffect(() => {
    loadBillingDtos();
  }, [setBillingDtos]);

  const ConvertState = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const classes = TableStyles();

  const getTotal = (item: BillingDTO) => {
    const totals = item.projectForBillingDtos?.map((proj) => (proj.total ? proj.total : 0));
    const reducer = (accumulator: number | undefined, curr: number | undefined) => accumulator! + curr!;
    return totals?.reduce(reducer).toLocaleString('da-DK', { maximumFractionDigits: 2 });
  };

  return (
    <Box>
      {billingDtos ? (
        <div>
          {billingDtos.length > 0 ? (
            billingDtos.map(
              (item) =>
                item.projectForBillingDtos!.length > 0 && (
                  <div key={item.customerId}>
                    <Box marginTop={2}>
                      <Typography variant="h4" className="App_pageTitle" style={{ borderRadius: 0 }}>
                        {item.customerName}
                      </Typography>
                      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="right"> {localized('ProjectName')}</StyledTableCell>
                              <StyledTableCell align="right">{localized('Active')}</StyledTableCell>
                              <StyledTableCell align="right">{localized('Hours')}</StyledTableCell>
                              <StyledTableCell align="right">{localized('Rate')}</StyledTableCell>
                              <StyledTableCell align="right">{localized('Total')}</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item &&
                              item.projectForBillingDtos &&
                              item.projectForBillingDtos.map(
                                (row) =>
                                  row.hours! > 0 && (
                                    <StyledTableRow key={row.projectId}>
                                      <StyledTableCell align="right">{row.name}</StyledTableCell>
                                      <StyledTableCell align="right">{ConvertState(row.active)}</StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row.hours!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row.rate ? row.rate.toLocaleString(localized('Format'), { maximumFractionDigits: 2 }) : row.rate}
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {row.total
                                          ? row.total.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })
                                          : row.total}
                                        kr.
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                              )}
                            <StyledTableRow>
                              <StyledTableCell align="right"></StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                              <StyledTableCell align="right"></StyledTableCell>
                              <StyledTableCell align="right">
                                <b>{getTotal(item)} kr.</b>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box marginTop={1} marginBottom={4} display="flex" justifyContent="flex-end" padding={1}>
                        {item.oneBillPerProject ? (
                          <Link
                            to={{ pathname: '/billing/one-per-project/'.concat(item.customerId ? item.customerId.toString() : '') }}
                            className={classes.removeUnderline}
                          >
                            <Button variant="contained" color="primary" size={buttonSize}>
                              {localized('View')}
                            </Button>
                          </Link>
                        ) : (
                          <Link
                            to={{ pathname: '/billing/'.concat(item.customerId ? item.customerId.toString() : '') }}
                            className={classes.removeUnderline}
                          >
                            <Button variant="contained" color="primary" size={buttonSize}>
                              {localized('View')}
                            </Button>
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </div>
                )
            )
          ) : (
            <Typography variant="body1"> {localized('NoNewTimerecords')}</Typography>
          )}
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default withRouter(BillingOverview);
