import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormGroup,
  Checkbox,
  Snackbar,
  Grid,
  Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';

import { personService } from '../../../services/person.services';
import { teamService } from '../../../services/team.services';
import { Person } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

import { Team } from '../../../services/http-services/api';

function CreatePerson() {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [personRole, setPersonRole] = useState('');
  const [active, setActive] = useState(false);
  const [canBeBooked, setCanBeBooked] = useState(false);
  const [team, setTeam] = useState(0);
  const [teams, setTeams] = useState<Team[] | null>(null);

  const classes = ApplicationUseStyles();

  const history = useHistory();

  async function loadTeams() {
    const TeamList = await teamService.getAll();
    setTeams(TeamList);
  }

  React.useEffect(() => {
    loadTeams();
    document.title = 'Cenvation - Persons';
  }, [setTeams]);

  // https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript

  const handleSubmit = async (event: any) => {
    event.persist();
    const role = personRole;

    if (name === '') {
      setSnackBarMessage(localized('NameIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (email === '') {
      setSnackBarMessage(localized('EmailIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (personRole === '') {
      setSnackBarMessage(localized('RoleIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    const result = await personService.create(
      new Person({ name, email, role, active, canBeBooked, BookingSuggestion: true, CommentColumn: false, Dashboard: true, team })
    );
    if (result === true) {
      history.push('/get-persons');
    } else {
      setSnackBarMessage(localized('EmailAlreadyInUse'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleChangeTeam = (event: any, value: Team | null) => {
    const newTeam = value!.id ? value!.id : 0;
    setTeam(newTeam);
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          {localized('CreatePerson')}{' '}
        </Typography>
        <div className={classes.paperPadding}>
          <Grid container>
            <div>
              <Grid container>
                <TextField
                  required
                  id="outlined-input"
                  name="name"
                  label={localized('Name')}
                  type="text"
                  defaultValue={name}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={(event: any) => {
                    event.persist();
                    setName(event.target.value);
                  }}
                />

                <TextField
                  required
                  id="outlined-input"
                  name="email"
                  label={localized('Email')}
                  type="text"
                  defaultValue={email}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={(event: any) => {
                    event.persist();
                    setEmail(event.target.value);
                  }}
                />
              </Grid>
              {/* Radio buttons made with inspiration from  https://material-ui.com/components/radio-buttons/#radiogroup */}
              <Grid container>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel required component="legend">
                      {localized('Role')}
                    </FormLabel>
                    <RadioGroup
                      aria-label="role"
                      name="role"
                      value={personRole}
                      onChange={(event: any) => {
                        event.persist();
                        setPersonRole(event.target.value);
                      }}
                    >
                      <FormControlLabel value="User" control={<Radio required={true} />} label="User" />
                      <FormControlLabel value="Admin" control={<Radio required={true} />} label="Administrator" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label={localized('Active')}
                          labelPlacement="start"
                          style={{ display: 'table' }}
                          control={
                            <div style={{ display: 'table-cell' }}>
                              <Checkbox
                                checked={active}
                                onChange={(event: any) => {
                                  event.persist();
                                  setActive(!active);
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          label={localized('CanBeBooked')}
                          labelPlacement="start"
                          style={{ display: 'table' }}
                          control={
                            <div style={{ display: 'table-cell' }}>
                              <Checkbox
                                checked={canBeBooked}
                                onChange={(event: any) => {
                                  event.persist();
                                  setCanBeBooked(!canBeBooked);
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </div>
                          }
                        />
                      </Grid>
                      <Grid container>
                        <Autocomplete
                          id="TeamChange"
                          options={teams!}
                          getOptionLabel={(option: Team) => option.name}
                          renderInput={(params) => <TextField required {...params} label={localized('SelectTeam')} variant="outlined" />}
                          onChange={handleChangeTeam}
                          defaultValue={teams?.find(t => t.id === team)}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                size={buttonSize}
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              >
                {localized('Create')}
              </Button>

              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity={snackBarSeverity} variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>
            </div>
          </Grid>
        </div>
      </div>
    </form>
  );
}

export default withRouter(CreatePerson);
