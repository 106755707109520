import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
  Tooltip
} from '@material-ui/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import format from 'date-fns/format';

import { CreateTimerecordOverviewDTO, Customer, Person, UtilizationUserDTO } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';
import { customerService } from '../../../services/customer.services';
import { personService } from '../../../services/person.services';
import { authenticationService } from '../../../services/auth.services';
import { economicServices } from '../../../services/economic.services';

import { TableStyles, StyledTableCell, StyledTableRow } from '../../../styles/use-styles';
import { useWindowWidth } from '../../../utilities/use-window-width';
import { buttonSize, mobileMaxWidth } from '../../../utilities/constants';
import { localized, localizedDynamic } from '../../../utilities/language/i18n';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';
import './timerecord-overview.scss';
import { async } from 'rxjs';
import { configurationService } from '../../../services/configuration.service';

function OverviewCreateTimerecord() {
  const [createTimerecordOverviewDTOs, setCreateTimerecordOverviewDTOs] = React.useState<CreateTimerecordOverviewDTO[] | null>();
  const [customers, setCustomers] = React.useState<Customer[] | null>();
  const [user, setUser] = React.useState<Person | null>();
  const [userId, setUserId] = React.useState<number>(0);
  const [weekView, setWeekView] = React.useState<CreateTimerecordOverviewDTO[] | null>();
  const [monthView, setMonthView] = React.useState<CreateTimerecordOverviewDTO[] | null>();
  const [dashboard, setDashboard] = React.useState<Boolean | null>();
  const [utilizationDTO, setUtilizationDTO] = React.useState<UtilizationUserDTO | null>();
  const [weeklyHours, setWeeklyHours] = React.useState<number | null>(40);
  const [holidays, setHolidays] = React.useState<number | null>();

  React.useEffect(() => {
    async function LoadUser() {
      const userId = await authenticationService.getUserId();
      if (userId) setUserId(userId);
    }
    async function GetCustomers() {
      const customerList = await customerService.getAll();
      setCustomers(customerList);
    }
    async function GetWeeklyHours() {
      const hours = await configurationService.getWeeklyHours();
      setWeeklyHours(hours);
    }
    GetWeeklyHours();
    LoadUser();
    GetCustomers();
  }, []);

  React.useEffect(() => {
    async function loadUtilizationUserDTOs() {
      let date = new Date();
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dataList = await economicServices.getUtilizationByUserId(date, userId);
      setUtilizationDTO(dataList);
    }
    async function GetHolidaysCurrentMonth() {
      let date = new Date();
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const hours = await timerecordService.getHolidaysCurrentMonth(date, userId);
      setHolidays(hours);
    }
    GetHolidaysCurrentMonth();
    loadUtilizationUserDTOs();
  }, [userId]);

  React.useEffect(() => {
    async function LoadUser() {
      const curruser = await personService.getPersonById(userId);
      setUser(curruser);
    }
    LoadUser();
  }, [userId]);

  React.useEffect(() => {
    setDashboard(user?.Dashboard);
  }, [user]);

  React.useEffect(() => {
    async function CreateTimerecordOverviewDTOs() {
      const dataListWeek = await timerecordService.getOverviewTimerecordCreate('week');
      const dataListMonth = await timerecordService.getOverviewTimerecordCreate('month');
      setMonthView(dataListMonth);
      setWeekView(dataListWeek);
      setCreateTimerecordOverviewDTOs(dataListWeek);
    }
    CreateTimerecordOverviewDTOs();
    document.title = 'Cenvation - Time Registration';
  }, [customers]);

  const handleWeek = () => {
    setCreateTimerecordOverviewDTOs(weekView);
  };

  const handleMonth = () => {
    setCreateTimerecordOverviewDTOs(monthView);
  };

  function countWeekDays() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    let workingDays = 0;

    for (let day = firstDayOfMonth; day <= today; day.setDate(day.getDate() + 1)) {
      const dayOfWeek = day.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        workingDays++;
      }
    }

    return workingDays;
  }

  const weekDays = countWeekDays() * 8 - holidays! * 8;

  const classes = TableStyles();
  const { width } = useWindowWidth();
  const COLORS = ['#1e9bd3', '#808080'];
  const sumHours = weekView?.reduce((sum, rec) => sum + rec.hours!, 0);
  const data = [
    { name: 'Group A', value: sumHours! },
    { name: 'Group B', value: Math.max(0, weeklyHours! - sumHours!) }
  ];

  const dataUtil = utilizationDTO
    ? [
        { name: 'Group A', value: (utilizationDTO.externalHours! / weekDays) * 100 },
        { name: 'Group B', value: Math.max(0, 100 - (utilizationDTO.externalHours! / weekDays) * 100) }
      ]
    : [];

  return (
    <Box className="timerecord-overview">
      <Typography className="App_pageTitle" variant="h4">
        {localized('RegistrationOfHours')}
      </Typography>
      {createTimerecordOverviewDTOs ? (
        <div>
          {dashboard ? (
            <div className="row">
              <div className="column1">
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', margin: '10px', textAlign: 'center', display: 'inline-block' }}>
                  {localized('Hours this week')}
                </h3>
              </div>
              <div className="column1">
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', margin: '10px', textAlign: 'center', display: 'inline-block' }}>
                  {localized('Utilization this month')}
                </h3>
              </div>
            </div>
          ) : (
            <> </>
          )}
          {dashboard ? (
            <ResponsiveContainer height={150} width="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="25%"
                  innerRadius={55}
                  outerRadius={70}
                  startAngle={90}
                  endAngle={450}
                  fill="#1e9bd3"
                  paddingAngle={2}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label
                    value={`${sumHours!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}/${weeklyHours}`}
                    position="center"
                    fill="#000000"
                    fontSize={18}
                  />
                </Pie>
                <Pie
                  data={dataUtil}
                  cx="75%"
                  innerRadius={55}
                  outerRadius={70}
                  startAngle={90}
                  endAngle={450}
                  fill="#1e9bd3"
                  paddingAngle={2}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label
                    value={
                      ((utilizationDTO!.externalHours! / weekDays) * 100).toLocaleString(localized('Format'), {
                        maximumFractionDigits: 2
                      }) + '%'
                    }
                    position="center"
                    fill="#000000"
                    fontSize={18}
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <></>
          )}
          <Paper elevation={3} className={classes.paper}>
            <Grid container direction="row" justify="center" alignItems="center">
              <TableContainer key={1} component={Paper} style={{ boxShadow: 'none' }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <Button style={{ marginLeft: 8 }} onClick={handleWeek} variant="outlined" color="primary" size={buttonSize}>
                        {localized('Week')}
                      </Button>
                      <Button style={{ marginLeft: 8 }} onClick={handleMonth} variant="outlined" color="primary" size={buttonSize}>
                        {localized('Month')}
                      </Button>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell>{localized('Date')}</StyledTableCell>
                      <StyledTableCell>{width > mobileMaxWidth ? localized('TotalHours') : localized('Hours')}</StyledTableCell>
                      <StyledTableCell>{localized('Time Records')}</StyledTableCell>
                      {user && user.CommentColumn ? <StyledTableCell>{localized('Comment')}</StyledTableCell> : null}
                      <StyledTableCell>{localized('Create')}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {createTimerecordOverviewDTOs.map((item, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          <span className="timerecord-overview__row">{localized('Date')}:</span>
                          {width > mobileMaxWidth
                            ? item.date &&
                              localizedDynamic(format(item.date, 'EEEE dd-MM-yyyy').split(' ')[0]) +
                                ' ' +
                                localizedDynamic(format(item.date, 'EEEE dd-MM-yyyy').split(' ')[1])
                            : item.date && format(item.date, 'd/M/yy')}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <span className="timerecord-overview__row">{localized('Hours')}:</span>
                          {item.hours?.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <span className="timerecord-overview__row">{localized('Time Records')}:</span>
                          {item.timerecords &&
                            item.timerecords.length > 0 &&
                            item.timerecords.map((tr, index1) => (
                              <Tooltip
                                title={
                                  tr.ceRef
                                    ? 'CeRef:' + tr.ceRef + ', ' + localized('Comment') + ': ' + tr.comment
                                    : tr.apRef
                                    ? 'APRef:' + tr.apRef + ', ' + localized('Comment') + ': ' + tr.comment
                                    : tr.comment
                                    ? localized('Comment') + ': ' + tr.comment
                                    : ''
                                }
                              >
                                <Box key={index1}>
                                  {tr.status ? (
                                    <Link
                                      to={{
                                        pathname: '/edit-timerecord/'.concat(tr.id ? tr.id.toString() : '')
                                      }}
                                    >
                                      {tr.project &&
                                        tr.customer &&
                                        tr.customer.name.concat(
                                          ` - ${tr.project.name}(${tr.hours.toLocaleString(localized('Format'), {
                                            maximumFractionDigits: 2
                                          })})`
                                        )}
                                    </Link>
                                  ) : (
                                    tr.project &&
                                    tr.customer &&
                                    tr.customer.name.concat(
                                      ` - ${tr.project.name}(${tr.hours.toLocaleString(localized('Format'), {
                                        maximumFractionDigits: 2
                                      })})`
                                    )
                                  )}
                                </Box>
                              </Tooltip>
                            ))}
                        </StyledTableCell>
                        {user && user.CommentColumn ? (
                          <StyledTableCell component="th" scope="row">
                            <span className="timerecord-overview__row">{localized('Time Records')}:</span>
                            {item.timerecords &&
                              item.timerecords.length > 0 &&
                              item.timerecords.map((tr, index1) => <Box key={index1}>{tr.comment ? tr.comment : " "}</Box>)}
                          </StyledTableCell>
                        ) : null}
                        <StyledTableCell component="th" scope="row">
                          <Link
                            className={classes.removeUnderline}
                            to={{
                              pathname: '/create-timerecord/'.concat(item.date ? format(item.date, 'dd-MM-yy') : '')
                            }}
                          >
                            <Button variant="contained" color="primary" size={buttonSize}>
                              {localized('Create')}
                            </Button>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default withRouter(OverviewCreateTimerecord);
