/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Component, CSSProperties } from 'react';
import moment from 'moment';

import { CellUnits } from './types/cell-units';
import { SchedulerData } from './scheduler-bindings';
import { Header } from './scheduler-data';

interface HeaderViewProps {
  schedulerData: SchedulerData;
  nonAgendaCellHeaderTemplateResolver?: (
    schedulerData: SchedulerData,
    item: Header,
    formattedDateItems: any,
    style: CSSProperties
  ) => JSX.Element;
}

class HeaderView extends Component<HeaderViewProps> {
  public render() {
    const { schedulerData } = this.props;
    const { headers, cellUnit, config } = schedulerData;
    const headerHeight = schedulerData.getTableHeaderHeight();
    const cellWidth = schedulerData.getContentCellWidth();

    let headerList: any[] = [];
    let style = {};

    headerList = headers.map((item, index) => {
      const datetime = moment(item.time);
      style = item.nonWorkingTime
        ? { width: cellWidth, color: config.nonWorkingTimeHeadColor, backgroundColor: config.nonWorkingTimeHeadBgColor }
        : { width: cellWidth };
      if (index === headers.length) {
        style = item.nonWorkingTime ? { color: config.nonWorkingTimeHeadColor, backgroundColor: config.nonWorkingTimeHeadBgColor } : {};
      }

      const pFormattedList = config.nonAgendaOtherCellHeaderFormat.split('|').map((i) => datetime.format(i));

      if (typeof this.props.nonAgendaCellHeaderTemplateResolver === 'function') {
        return this.props.nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
      }

      const pList = pFormattedList.map((i, ind) => <div key={ind}>{i}</div>);

      return (
        <th key={item.time} className="header3-text" style={style}>
          <div>{pList}</div>
        </th>
      );
    });

    return (
      <thead>
        <tr style={{ height: headerHeight, width: cellWidth }}>{headerList}</tr>
      </thead>
    );
  }
}

export default HeaderView;
