/* eslint-disable no-unused-vars */
/*
  This file is made with inspiration from 
  https://material-ui.com/components/drawers/#mini-variant-drawer
*/
import clsx from 'clsx';
import {
  AppBar,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';

//  import { Person } from '../../services/http-services/api';
import NavBar from '../navbar/navbar';
import { personService } from '../../services/person.services';
import { authenticationService } from '../../services/auth.services';
import { useAuth } from '../../utilities/use-auth';
import { changeLanguage, Languages, localized } from '../../utilities/language/i18n';
import { buttonSize, mobileMaxWidth } from '../../utilities/constants';
import { useWindowWidth } from '../../utilities/use-window-width';
import { ThemePrimaryColor } from '../../utilities/colors';

// needs to be moved
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      background: 'transparent',
      boxShadow: 'none',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    accountBtn: {
      background: ThemePrimaryColor,
      borderRadius: '50%'
    },
    menuButton: {
      marginRight: theme.spacing(1),
      color: '#222',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
        marginRight: theme.spacing(2)
      }
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    toolbar: {
      display: 'flex',
      allignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content2: {
      flexGrow: 1
    },

    content: {
      flexGrow: 1,
      marginLeft: '150px'
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },

    drawerPaper: {
      width: drawerWidth
    },
    logOffButton: {
      border: '2px solid #222',
      color: '#222',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2)
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

const TopBar: React.FC = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //  const [consultant, setConsultant] = React.useState<Person>();
  const open = Boolean(anchorEl);

  const auth = useAuth();
  const history = useHistory();
  const { width } = useWindowWidth();
  const [name, setName] = useState('');
  const isLoggedIn = auth.loginState;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        const id = await authenticationService.getUserId();
        const consultant = id ? await personService.getPersonById(id) : null;
        setName(consultant ? consultant.name : 'No Name');
      } catch (error) {
        setName('');
      }
    }
    fetchMyAPI();
  }, [isLoggedIn]);

  const logout = () => {
    setName('');
    authenticationService.logout();
    auth.signOut();
    document.getElementById('App-header-overall')!.style.marginTop = '64x';
    history.push('/');
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEnglish = () => {
    changeLanguage(Languages.en);

    setAnchorEl(null);
    //  location.reload();
  };

  const handleDanish = () => {
    changeLanguage(Languages.da);
    setAnchorEl(null);
    //  location.reload();
  };

  // const showLogOutButton = () => {
  //   if (isLoggedIn === true) {
  //     //  loadName();
  //     return (
  //       <Button variant="outlined" size={buttonSize} color="inherit" onClick={logout} className={classes.logOffButton}>
  //         {localized('Logout') + ' (' + name + ')'}
  //       </Button>
  //     );
  //   } else return null;
  // };

  const setLanguageButton = () => {
    return (
      <div className={clsx(classes.accountBtn)}>
        <Tooltip title="Change Language" aria-label="changeLanguage">
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Menu
          style={{ width: 240 }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDanish}>{localized('LanguageDanish')}</MenuItem>
          <MenuItem onClick={handleEnglish}>{localized('LanguageEnglish')}</MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <div id="topbar" className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" noWrap className={width > mobileMaxWidth ? classes.content : classes.content2}>
            {/* {width > mobileMaxWidth ? localized('TitelNormal') : localized('TitelMobile')} */}
          </Typography>
          {setLanguageButton()}
          {/* {showLogOutButton()} */}
        </Toolbar>
      </AppBar>

      <NavBar menuState={mobileOpen} changeState={handleDrawerToggle} />
    </div>
  );
};

export default TopBar;
