import {
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Grid,
  Box,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ProjectHoursDTO } from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function GetHoursForProject() {
  const [projectHours, setProjectHours] = React.useState<ProjectHoursDTO[] | null>();

  const params = useParams<props>();
  const classes = TableStyles();
  const history = useHistory();

  interface props {
    projectId: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const projectId = JSON.parse(params.projectId);
      if (projectId) {
        const projectHoursFromApi = await projectService.getProjectHours(parseInt(projectId, 10));
        setProjectHours(projectHoursFromApi);
      }
    }
    dataFromProps();
  }, [setProjectHours]);

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('Hours')}
      </Typography>
      {projectHours ? (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">{localized('Date')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Name')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Hours')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Comment')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Status')}</StyledTableCell>
                <StyledTableCell align="right">{localized('APRef')}</StyledTableCell>
                <StyledTableCell align="right">{localized('CERef')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectHours.map((row: ProjectHoursDTO, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="right">{row.date && format(row.date, 'EEEE dd-MM-yyyy')}</StyledTableCell>
                  <StyledTableCell align="right">{row.name}</StyledTableCell>
                  <StyledTableCell align="right">{row.hours}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.status === 'Created' ? (
                      <Link
                        to={{
                          pathname: `/edit-timerecord/${row.id}`
                        }}
                      >
                        {row.comment}
                      </Link>
                    ) : (
                      row.comment
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.status}</StyledTableCell>
                  <StyledTableCell align="right">{row.APRef}</StyledTableCell>
                  <StyledTableCell align="right">{row.CERef}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
      <Grid>
        <Box margin={2}>
          <Button
            variant="contained"
            color="primary"
            className="button"
            size={buttonSize}
            onClick={handleGoBackClick}
            startIcon={<ArrowBackIcon />}
          >
            {localized('GoBack')}
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

export default GetHoursForProject;
