import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { billService } from '../../services/bill.service';
import { Bill } from '../../services/http-services/api';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { TableStyles, DataGridStyle } from '../../styles/use-styles';

function BillsGetAll() {
  const [bills, setBills] = useState<Bill[] | null>();
  const [searchNumber, setSearchNumber] = useState('');

  async function getBills() {
    const billList = await billService.getAll();
    billList.sort((_a, _b) => _b.number - _a.number);
    setBills(billList);
  }

  React.useEffect(() => {
    getBills();
    document.title = 'Cenvation - Bills';
  }, []);

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  const billPaid = (paid: any) => {
    if (paid) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  async function markBillAsPaid(bill: any) {
    if (bill === undefined || bills === null) {
      return;
    }
    bill.paid = true;
    const updatedBill = await billService.updateBill(bill);
    const billsCopy = Object.assign(bills!);
    if (!billsCopy) {
      return;
    }
    const indexToUpdate = billsCopy.findIndex((existingBill: Bill) => existingBill.id === updatedBill.id);
    billsCopy[indexToUpdate] = updatedBill;

    setBills([...billsCopy]);
  }

  function changeSearchVal(event: string) {
    setSearchNumber(event);
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: localized('Id'),
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'number',
      headerName: localized('InvoiceNumber'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <Link to={'/timerecords-by-bill/' + params.getValue(params.id, 'id')}>{params.getValue(params.id, 'number')}</Link>
          </div>
        );
      }
    },
    {
      field: 'paid',
      headerName: localized('Paid'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => {
        return <div>{billPaid(params.getValue(params.id, 'paid'))}</div>;
      }
    },
    {
      field: 'mark',
      headerName: localized('MarkasPaid'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        if (params.getValue(params.id, 'paid') === false) {
          return (
            <Button
              className={classes.buttonWithoutCaps}
              variant="contained"
              size={buttonSize}
              color="primary"
              onClick={() => markBillAsPaid(params.row)}
            >
              {localized('MarkasPaid')}
            </Button>
          );
        }
        return <div></div>;
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('Bills')}
      </Typography>
      {bills && (
        <Box margin={2}>
          <TextField
            id="standard-basic"
            value={searchNumber}
            label={localized('Searchforinvoicenumber')}
            onChange={(event) => changeSearchVal(event.target.value)}
          />
        </Box>
      )}
      {bills ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {bills ? (
              <DataGrid
                className={classesGrid.root}
                rows={bills}
                columns={columns}
                filterModel={{
                  items: [{ columnField: 'number', operatorValue: 'contains', value: searchNumber }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(BillsGetAll);
