import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Switch,
  TextField,
  CircularProgress,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert, Autocomplete } from '@material-ui/lab';

import { Bill, VoucherRequest, Customer, Project } from '../../services/http-services/api';
import { voucherService } from '../../services/voucher.service';
import { customerService } from '../../services/customer.services';
import { billService } from '../../services/bill.service';
import { projectService } from '../../services/project.service';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

interface VoucherEditProps {
  voucherId: string;
}

function VoucherEdit() {
  const [voucherId, setVoucherId] = React.useState(0);
  const [customers, setCustomers] = React.useState<Customer[] | null>();
  const [bills, setBills] = React.useState<Bill[] | null>();
  const [projects, setProjects] = React.useState<Project[] | null>();
  const [originalSize, setOriginalSize] = React.useState(0);
  const [remainingHours, setRemainingHours] = React.useState(0);
  const [active, setActive] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();
  const [selectedBill, setSelectedBill] = React.useState<Bill>();
  const [selectedProject, setSelectedProject] = React.useState<Project>();
  const [bindToProject, setBindToProject] = React.useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const history = useHistory();
  const classes = ApplicationUseStyles();
  const params = useParams<VoucherEditProps>();

  React.useEffect(() => {
    async function loadVoucher() {
      const voucherObject = await voucherService.getVoucherById(parseInt(params.voucherId, 10));
      const customer = await customerService.getCustomerById(voucherObject.customerId);
      if (customer) setSelectedCustomer(customer);
      const bill = await billService.getBillById(voucherObject.billId);
      if (bill) setSelectedBill(bill);

      if (voucherObject.id) setVoucherId(voucherObject.id);
      setOriginalSize(voucherObject.originalSize);
      setRemainingHours(voucherObject.remainingHours);
      setActive(voucherObject.active);
      if (voucherObject.projectId) {
        const project = await projectService.getProjectById(voucherObject.projectId);
        setSelectedProject(project);
        setBindToProject(true);
      }
    }
    async function loadCustomers() {
      const customerList = await customerService.getAll();
      setCustomers(customerList);
    }
    async function loadBills() {
      const billList = await billService.getAll();
      setBills(billList);
    }
    async function loadProjects() {
      const projectList = await projectService.getAll();
      setProjects(projectList);
    }
    loadBills();
    loadCustomers();
    loadProjects();
    loadVoucher();
  }, []);
  // }, [setCustomers, setBills, setProjects, setSelectedCustomer, setSelectedBill, setSelectedProject]);

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setSelectedCustomer(value);
    }
  };

  const handleBillChange = (event: any, value: Bill | null) => {
    if (value !== null) {
      setSelectedBill(value);
    }
  };

  const handleProjectChange = (eevent: any, value: Project | null) => {
    if (value !== null) {
      setSelectedProject(value);
    }
  };

  const handleOriginalSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOriginalSize(parseInt(event.target.value, 10));
  };

  const handleRemainingHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemainingHours(parseInt(event.target.value, 10));
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleChangeProjectCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBindToProject(event.target.checked);
  };

  const includeProject = () => {
    return (
      <div>
        {projects && (
          <Autocomplete
            id="filter-demo"
            options={projects}
            getOptionLabel={(option: Project) => option.name}
            style={{ width: '100%' }}
            renderInput={(inputParams) => <TextField {...inputParams} label="Select project" variant="outlined" />}
            onChange={handleProjectChange}
            value={selectedProject}
          />
        )}
      </div>
    );
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (isNaN(originalSize) || originalSize === 0) {
      setSnackBarMessage('Original size is required');
      setIsSnackBarOpen(true);
      return;
    }
    if (isNaN(remainingHours) || remainingHours === 0) {
      setSnackBarMessage('Remaining hours is required');
      setIsSnackBarOpen(true);
      return;
    }
    if (selectedCustomer === undefined || selectedCustomer.id === undefined) {
      setSnackBarMessage('Customer is required');
      setIsSnackBarOpen(true);
      return;
    }
    if (selectedBill === undefined || selectedBill.id === undefined) {
      setSnackBarMessage('Bill is required');
      setIsSnackBarOpen(true);
      return;
    }
    if (bindToProject && (selectedProject === undefined || selectedProject.id === undefined)) {
      setSnackBarMessage('Project is required');
      setIsSnackBarOpen(true);
      return;
    }

    await voucherService.updateVoucher(
      voucherId,
      new VoucherRequest({
        originalSize,
        active,
        remainingHours,
        customerId: selectedCustomer.id,
        billId: selectedBill.id,
        projectId: selectedProject ? selectedProject.id : undefined
      })
    );
    history.push('/get-vouchers');
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdateVoucher')}
      </Typography>
      {customers && bills && selectedCustomer && selectedBill ? (
        <div className={classes.root}>
          <div className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <Autocomplete
                    id="Voucher-Customer"
                    options={customers}
                    getOptionLabel={(option: Customer) => option.name}
                    renderInput={(renderParams) => <TextField {...renderParams} label={localized('SelectCustomer')} variant="outlined" />}
                    onChange={handleCustomerChange}
                    defaultValue={undefined}
                    value={selectedCustomer}
                    getOptionSelected={(option: Customer, value: Customer) => option.id === value.id}
                  />
                  <Autocomplete
                    id="Voucher-Bill"
                    options={bills}
                    getOptionLabel={(option: Bill) => option.number.toString()}
                    renderInput={(renderParams) => <TextField {...renderParams} label={localized('SelectBill')} variant="outlined" />}
                    onChange={handleBillChange}
                    defaultValue={undefined}
                    value={selectedBill}
                    getOptionSelected={(option: Bill, value: Bill) => option.id === value.id}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    required
                    id="VoucherSize"
                    name="original"
                    label={localized('OriginalHours')}
                    type="number"
                    variant="outlined"
                    onChange={handleOriginalSizeChange}
                    value={originalSize}
                  />
                  <TextField
                    required
                    id="RemainingHours"
                    name="remaining"
                    label={localized('HoursLeft')}
                    type="number"
                    variant="outlined"
                    onChange={handleRemainingHoursChange}
                    value={remainingHours}
                  />
                </Grid>
                <FormGroup>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('Active')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('BindToSpecificProjekt')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Switch
                              checked={bindToProject}
                              onChange={handleChangeProjectCheckbox}
                              color="primary"
                              name="bindToProject"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                {bindToProject ? includeProject() : ''}
                <Grid container justify="space-evenly">
                  <Button variant="contained" color="primary" size={buttonSize} onClick={handleGoBackClick} startIcon={<ArrowBackIcon />}>
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Update')}
                  </Button>
                </Grid>
              </div>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity="warning" variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default withRouter(VoucherEdit);
