import { Button, CircularProgress, Typography, Grid, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { configurationService } from '../../../services/configuration.service';
import { Configuration } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function UpdateConfiguration() {
  const [id, setId] = React.useState(0);
  const [label, setConfigurationLabel] = React.useState('');
  const [value, setConfigurationValue] = React.useState('');

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    editConfiguration: string;
  }

  const classes = ApplicationUseStyles();
  React.useEffect(() => {
    async function dataFromProps() {
      const editConfigurationId = JSON.parse(params.editConfiguration);
      if (editConfigurationId) {
        const editConfiguration = await configurationService.getConfigurationById(parseInt(editConfigurationId, 10));
        console.log(editConfiguration);
        if (editConfiguration.id) setId(editConfiguration.id);
        if (editConfiguration.label) setConfigurationLabel(editConfiguration.label);
        if (editConfiguration.value) setConfigurationValue(editConfiguration.value);
      }
    }
    dataFromProps();
  }, []);

  const handleChangeConfigurationLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationLabel(event.target.value);
  };

  const handleConfigurationValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationValue(event.target.value);
  };

  const handleDeleteClick = async () => {
    await configurationService.Delete(new Configuration({ id, label, value }));
    history.goBack();
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleSubmit = async (event: any) => {
    event.persist();
    await configurationService.editConfiguration(new Configuration({ id, label, value }));
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdateConfiguration')}
      </Typography>
      {id ? (
        <div className={classes.root}>
          <div className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    required
                    id="outlined-label"
                    name="label"
                    label={localized('Label')}
                    variant="outlined"
                    value={label}
                    onChange={handleChangeConfigurationLabel}
                  />
                  <TextField
                    required
                    id="outlined-value"
                    name="value"
                    label={localized('Value')}
                    variant="outlined"
                    value={value}
                    onChange={handleConfigurationValue}
                  />
                </Grid>
                <Grid container justify="space-evenly">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBackClick}
                  >
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Update')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleDeleteClick}
                  >
                    {localized('Delete')}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default UpdateConfiguration;
