import {
  TextField,
  FormControlLabel,
  Button,
  FormGroup,
  Checkbox,
  Typography,
  Snackbar,
  Box,
  Grid,
  Switch,
  Divider
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';

import { customerService } from '../../../services/customer.services';
import { Customer, Project, Product } from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { configurationService } from '../../../services/configuration.service';

function CustomerSpecific() {
  // Customer Properties
  const [name, setCustomerName] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [active, setActive] = useState(false);
  const [project, setProject] = useState(false);
  const [cvr, setCVR] = useState('');
  const [cc, setCC] = useState('');
  const [oneBill, setOneBill] = React.useState(false);
  const [secret, setSecret] = useState('');
  const [custPrice, setCustPrice] = useState(0);

  // Projects Properties
  const [nameProject, setProjectName] = React.useState('');
  const [orgPrice, setOrginalPrice] = React.useState(0);
  const [discount, setDiscountPrice] = React.useState<string | number>('');
  const [price, setActualPrice] = React.useState(0);
  const [budget, setBudget] = React.useState(0);
  const [timeRegNeedComment, setTimeRegNeedComment] = React.useState(false);
  const [activeProject] = React.useState(true);
  const [fixedPrice, setFixedPrice] = React.useState(false);
  const [sla, setCheckedSLA] = React.useState(false);
  const [slaVal, setSlaVal] = React.useState<number>(1.5);
  const [products, setProducts] = React.useState<Product[] | null>();
  const [selectedProduct, setProduct] = React.useState<Product | undefined>();

  // Snackbar
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const classes = ApplicationUseStyles();
  const history = useHistory();

  const handleChangeContractName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactName(event.target.value);
  };

  const handleChangeCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value);
  };

  const handleChangeCCEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCC(event.target.value);
  };

  const handleChangeCustomerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value.replace(/(\r\n|\n|\r)/gm, ''));
  };

  const handleChangeCVR = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 8) {
      setSnackBarMessage(localized('CVRmustbe8digits'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else {
      setCVR(event.target.value);
    }
  };

  React.useEffect(() => {
    document.title = 'Cenvation - Customers';
    async function getSla() {
      var con_lst = await configurationService.getAll();
      var _sla = con_lst.filter((con) => con.label === 'SLA');
      if (_sla.length === 1) {
        setSlaVal(parseFloat(_sla[0].value.replace(',', '.')));
      }
    }
    getSla();
    async function loadProducts() {
      const productList = await configurationService.getAllProducts();
      setProducts(productList);
    } 
    loadProducts();
  }, []);

  const handleChangeProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProject(event.target.checked);
  };

  const handleChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleChangeOrginalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustPrice(parseInt(event.target.value, 10));
    setOrginalPrice(parseInt(event.target.value, 10));
  };

  const handleChangeDiscountPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setDiscountPrice(event.target.value);
      setActualPrice(orgPrice);
    } else {
      const value = parseFloat(event.target.value);
      if (value >= 0 && value <= 100) {
        setDiscountPrice(parseFloat(event.target.value));
        if (value === 0) {
          setActualPrice(orgPrice);
        } else {
          const newActualPrice = (orgPrice * (1 - value / 100)).toFixed(2);
          setActualPrice(parseFloat(newActualPrice));
        }
      } else {
        setSnackBarMessage(localized('Discount0100'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      }
    }
  };

  const handleChangeActualPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (value > orgPrice && sla === false) {
      setSnackBarMessage(localized('ActualPriceHigherThanOri'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else if (value < 0) {
      setSnackBarMessage(localized('ActualPriceNegative'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else if (value === 0 && selectedProduct?.name === "Internal") {
      setSnackBarMessage(localized('ActualPrice0'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else {
      setActualPrice(value);
      const newDiscount = (((orgPrice - value) / orgPrice) * 100).toFixed(2);
      setDiscountPrice(parseFloat(newDiscount));
    }
  };

  const handleChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudget(parseInt(event.target.value, 10));
  };

  const handleChangeProduct = (event: any, value: Product | null) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    var product = value ? value : undefined;
    setProduct(product);
    // Remove SLA if new product type can not be SLA
    if (!product?.canBeSLA && sla) { 
      setCheckedSLA(false);
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeRegNeedComment(event.target.checked);
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleChangeFixed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedPrice(event.target.checked);
  };

  const handleChangeSLA = (event: React.ChangeEvent<HTMLInputElement>) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    // If the Checkbox is not disabled, update sla based on the user's action.
    setCheckedSLA(event.target.checked);
    if (event.target.checked) {
      setOrginalPrice(orgPrice * slaVal);
      setActualPrice(orgPrice * slaVal * discountNumber);
    } else {
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleOneBill = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOneBill(event.target.checked);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('CustomerNameisrequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (contactEmail === '') {
      setSnackBarMessage(localized('EmailIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (cvr === '' || cvr.length !== 8) {
      setSnackBarMessage(localized('CVRmustbe8digits'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(custPrice) || custPrice === 0) {
      setSnackBarMessage('Customer price is required');
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    const result = await customerService.create(
      new Customer({
        name,
        contactEmail,
        contactName,
        cvr,
        defaultZendeskProject: 0,
        cc,
        secret,
        active,
        price: custPrice,
        oneBillPerProject: oneBill,
        customerNumber: parseInt(cvr, 10)
      })
    );

    if (result.id === undefined) {
      setSnackBarMessage(localized('ErrorCreatignCustomer'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    } else {
      setSnackBarMessage(localized('CustomerhasbeenCreated'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
      history.push('/customer');
    }
  };

  const handleSubmitWithProject = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('CustomerNameisrequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (cvr === '') {
      setSnackBarMessage(localized('CVRmustbe8digits'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (nameProject === '') {
      setSnackBarMessage(localized('ProjectNameisrequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(orgPrice) || (orgPrice === 0 && selectedProduct?.name === "Internal")) {
      setSnackBarMessage(localized('CustomerPriceIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(price) || (price === 0 && selectedProduct?.name === "Internal")) {
      setSnackBarMessage(localized('PriceIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (!selectedProduct?.productId) {
      setSnackBarMessage(localized('TypeIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    const customer = await customerService.create(
      new Customer({
        name,
        contactEmail,
        contactName,
        cvr,
        defaultZendeskProject: 0,
        cc,
        secret,
        active,
        price: custPrice,
        oneBillPerProject: oneBill
      })
    );

    if (customer === undefined || customer.id === undefined || customer.id === null) {
      setSnackBarMessage(localized('ErrorCreatignCustomer'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    }

    let discountNumber = 0;
    if (discount === '') {
      discountNumber = 0;
    } else {
      discountNumber = parseFloat(discount.toString());
    }

    if (customer.id !== undefined) {
      const result = await projectService.createProject(
        new Project({
          name: nameProject,
          type: selectedProduct!.productId!,
          orgPrice,
          discount: discountNumber,
          price,
          customerId: customer.id,
          budget,
          timeRegNeedComment,
          active: activeProject,
          fixedPrice,
          sla
        })
      );
      if (result.id === undefined) {
        setSnackBarMessage(localized('ErrorCreatingCustomerAndProject'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
      } else {
        setSnackBarMessage(localized('CustomerAndProjectCreated'));
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
        history.push('/customer');
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className="App_pageTitle">
        {localized('CreateCustomer')}
      </Typography>
      <div className={classes.paperPadding}>
        <Grid container>
          <div>
            <Grid container>
              <TextField
                required
                id="Customer name"
                name="Customer name"
                label={localized('CustomerName')}
                type="text"
                value={name}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeCustomerName}
              />
              <TextField
                required
                id="E-mail"
                name="email"
                label={localized('Email')}
                type="text"
                value={contactEmail}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeCustomerEmail}
              />
            </Grid>
            <Grid container>
              <TextField
                id="Contact name"
                name="Contact name"
                label={localized('ContactName')}
                type="text"
                value={contactName}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeContractName}
              />
              <TextField
                id="Contact-cc-email"
                name="Contact cc email"
                label={localized('CCmail')}
                type="text"
                value={cc}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeCCEmail}
              />
            </Grid>
            <Grid container>
              <TextField
                id="Secret"
                name="Secret"
                label={localized('Secret')}
                type="text"
                value={secret}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeSecret}
              />
              <TextField
                required
                id="CVR"
                name="CVR"
                label={localized('CVR')}
                type="number"
                value={cvr}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeCVR}
              />
            </Grid>
            <Grid container>
              <TextField
                required
                id="Price"
                name="Price"
                label={localized('Price')}
                type="number"
                value={custPrice}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeOrginalPrice}
              />
            </Grid>
            <FormGroup>
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel
                    label={localized('Active')}
                    labelPlacement="start"
                    style={{ display: 'table' }}
                    control={
                      <div style={{ display: 'table-cell' }}>
                        <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label={localized('OneBillPerProject')}
                    labelPlacement="start"
                    style={{ display: 'table' }}
                    control={
                      <div style={{ display: 'table-cell' }}>
                        <Checkbox checked={oneBill} onChange={handleOneBill} inputProps={{ 'aria-label': 'primary checkbox' }} />
                      </div>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <FormControlLabel
                  label={localized('CreateWithProject')}
                  labelPlacement="start"
                  style={{ display: 'table' }}
                  control={
                    <div style={{ display: 'table-cell' }}>
                      <Switch
                        checked={project}
                        onChange={handleChangeProject}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div>
                  }
                />
              </Grid>
            </FormGroup>
            {project && products ? (
              <div>
                <Box marginTop={2} marginBottom={2}>
                  <Divider />
                </Box>
                <Grid container>
                  <TextField
                    required
                    id="ProctedName"
                    name="nameProject"
                    label={localized('ProjectName')}
                    variant="outlined"
                    onChange={handleChangeProjectName}
                  />

                  <Autocomplete
                    id="TypeChange"
                    options={products}
                    getOptionLabel={(option: Product) => option.name}
                    renderInput={(params) => <TextField required {...params} label={localized('SelectProjectType')} variant="outlined" />}
                    onChange={handleChangeProduct}
                  />
                </Grid>

                <Grid container>
                  <TextField
                    disabled
                    id="origPrice-required"
                    label={localized('CustomerPrice')}
                    name="orgPrice"
                    type="number"
                    value={orgPrice}
                    variant="outlined"
                    onChange={handleChangeOrginalPrice}
                  />

                  <TextField
                    required
                    id="discount-number"
                    name="discount"
                    label={localized('DiscountPercent')}
                    type="number"
                    variant="outlined"
                    onChange={handleChangeDiscountPrice}
                    value={discount}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    required
                    id="Actual-price-required"
                    name="price"
                    label={localized('ActualPrice')}
                    type="number"
                    variant="outlined"
                    onChange={handleChangeActualPrice}
                    value={price}
                  />
                  <TextField
                    id="Budget-number"
                    label={localized('Budget')}
                    type="number"
                    variant="outlined"
                    onChange={handleChangeBudget}
                  />
                </Grid>
                <FormGroup>
                  <Grid container alignContent="flex-start">
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('RegistrationsMust')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={timeRegNeedComment}
                              onChange={handleChangeComment}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </div>
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('FixedPrice')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={fixedPrice} onChange={handleChangeFixed} inputProps={{ 'aria-label': 'primary checkbox' }} />
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('SLA-Project')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={selectedProduct?.canBeSLA ? sla : false}
                              onChange={handleChangeSLA}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={!selectedProduct?.canBeSLA}
                            />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleSubmitWithProject}
                  >
                    {localized('CreateWithProject')}
                  </Button>
                </Grid>
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size={buttonSize}
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              >
                {localized('Create')}
              </Button>
            )}
          </div>
        </Grid>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CustomerSpecific;
