import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import ChangePriceModal from '../../../components/change-price-modal/change-price-modal';
import { customerService } from '../../../services/customer.services';
import { Customer } from '../../../services/http-services/api';
import { StyledTableCell, StyledTableRow } from '../../../styles/use-styles';
import { localized } from '../../../utilities/language/i18n';

const useStyles = makeStyles({
  table: {
    minWidth: 400
  },
  paper: {
    padding: 40
  },
  padding50: {
    padding: 50,
    paddingTop: 10
  },
  centerjust: {
    display: 'flex',
    justifyContent: 'center'
  }
});

function PriceChangeCustomer() {
  const classes = useStyles();
  const [customers, setCustomers] = React.useState<Customer[]>();
  const [checkedCustomers, setCheckedCustomers] = React.useState<Number[]>([]);
  const [selectedCustomers, setSelectedCustomers] = React.useState<Customer[]>();
  const [multipleBookingModal, setMultipleBookingModal] = React.useState(false);

  async function loadCustomers() {
    const customerList = await customerService.getActiveCustomerOrderedByName();
    setCustomers(customerList);
  }

  React.useEffect(() => {
    loadCustomers();
    document.title = 'Cenvation - Annual Price Change';
  }, []);

  const handleOnClick = (index: number) => {
    if (customers === undefined) {
      return null;
    } else {
      setSelectedCustomers([customers[index]]);
      setMultipleBookingModal(true);
      return null;
    }
  };

  const handleSelectMarked = () => {
    if (customers === undefined) {
      return null;
    } else {
      const filteredCustomers = customers.filter((customer) => checkedCustomers.includes(customer.id!));
      setSelectedCustomers(filteredCustomers);
      setMultipleBookingModal(true);
      return null;
    }
  };

  const handleCheckboxChange = (customerId: Number) => {
    // Check if the customer is already checked
    if (checkedCustomers.includes(customerId)) {
      // Remove the customer ID from the checked list
      setCheckedCustomers(checkedCustomers.filter((id) => id !== customerId));
    } else {
      // Add the customer ID to the checked list
      setCheckedCustomers([...checkedCustomers, customerId]);
    }
  };

  const handleCheckAll = () => {
    if (checkedCustomers.length === customers!.length) setCheckedCustomers([]);
    else if (customers && customers.length > 0) {
      const allCustomerIds = customers.map((customer) => customer.id).filter((id) => id !== undefined) as number[];
      if (allCustomerIds.length > 0) {
        setCheckedCustomers(allCustomerIds);
      }
    }
  };

  const handleModalVisibility = () => {
    setMultipleBookingModal(false);
  };

  const hasBeenChangedThisYear = (date: Date | undefined) => {
    const currentDate = new Date();

    if (date !== undefined && date.getFullYear() === currentDate.getFullYear()) {
      return <DoneIcon />;
    } else return <NotInterestedIcon />;
  };

  const rowSuggestions = () => {
    if (customers === undefined) {
      return null;
    } else {
      return (
        <TableBody>
          {customers.map((row: Customer, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="right">
                <Checkbox
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  checked={checkedCustomers.includes(row.id!)}
                  onChange={() => handleCheckboxChange(row.id!)}
                />
              </StyledTableCell>
              <StyledTableCell align="right" onClick={() => handleOnClick(index)}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right" onClick={() => handleOnClick(index)}>
                {hasBeenChangedThisYear(row.priceChangeDate)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {' '}
        {localized('ChangeCustomerPrices')}
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 'bold', margin: 12 }}>
        {localized('OverviewAnnualPriceChange')}
      </Typography>
      {customers ? (
        <Box>
          <Grid container direction="column" justify="center" alignItems="center"></Grid>
          <Divider />
          <Grid container direction="row" justify="center" alignItems="center">
            <TableContainer component={Paper}>
              <Table aria-label="customerTabel" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">
                      <Button onClick={handleCheckAll} size="small" color="primary" variant="contained">
                        {localized('Mark all')}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="right">{localized('Project')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('ChangedThisYear')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                {rowSuggestions()}
              </Table>
            </TableContainer>
          </Grid>
          {multipleBookingModal && (
            <ChangePriceModal
              setVisible={() => handleModalVisibility()}
              visible={multipleBookingModal}
              selectedCustomers={selectedCustomers}
              updatedPrice={() => {
                loadCustomers();
                setCheckedCustomers([]);
              }}
            />
          )}
          {checkedCustomers.length > 0 ? (
            <div className="floating-button">
              <Button onClick={handleSelectMarked} size="large" color="primary" variant="contained">
                {localized('Select marked')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default PriceChangeCustomer;
