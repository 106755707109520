import { TableRow, TableContainer, Table, TableHead, TableBody, CircularProgress, Box, Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import format from 'date-fns/format';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Bill, TimerecordsPerUserDTO } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';
import { TableStyles, StyledTableCell, StyledTableRow } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { billService } from '../../../services/bill.service';

interface TimerecordForProjectParams {
  billId: string;
}

function TimerecordForBill() {
  const [timerecordOverviewDTO, setTimerecordOverviewDTO] = useState<TimerecordsPerUserDTO[] | null>();
  const [bill, setBill] = useState<Bill | null>();

  const params = useParams<TimerecordForProjectParams>();

  React.useEffect(() => {
    async function loadTimerecordOverviewDTOs() {
      const dtoObject = await timerecordService.getSpecificationByBillId(parseInt(params.billId, 10));
      const tempBill = await billService.getBillById(parseInt(params.billId, 10));
      setBill(tempBill);
      setTimerecordOverviewDTO(dtoObject);
    }
    loadTimerecordOverviewDTOs();
  }, []);

  const classes = TableStyles();
  const history = useHistory();

  const handleGoBackClick = () => {
    history.goBack();
  };

  if (timerecordOverviewDTO) {
    return (
      <Box>
        <Typography variant="h4" className="App_pageTitle">
          {localized('TimerecordOverview') + ' for'} {bill?.number}
        </Typography>
        <div>
          <TableContainer key={1} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> {localized('Date')}</StyledTableCell>
                  <StyledTableCell> {localized('Hours')}</StyledTableCell>
                  <StyledTableCell>{localized('Comment')}</StyledTableCell>
                  <StyledTableCell>{localized('Project')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {timerecordOverviewDTO.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {format(item.date!, 'dd MMMM yyyy')}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {item.hours}
                    </StyledTableCell>

                    <StyledTableCell>{item.comment}</StyledTableCell>
                    <StyledTableCell>{item.project!.name!}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box marginTop={2}>
            <Button variant="contained" color="primary" size={buttonSize} onClick={handleGoBackClick} startIcon={<ArrowBackIcon />}>
              {localized('GoBack')}
            </Button>
          </Box>
        </div>
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
}

export default withRouter(TimerecordForBill);
