import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

import { ThemePrimaryColor } from '../utilities/colors';

export function colorTheme() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: ThemePrimaryColor
      }
    },
    typography: {
      fontFamily: '-apple-system,Hind'
    }
  });
  return theme;
}
