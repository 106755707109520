import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormGroup,
  Checkbox,
  Snackbar,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';


import AlertDialog from '../../../components/alert-dialog/alert-dialog';
import { personService } from '../../../services/person.services';
import { teamService } from '../../../services/team.services';
import { Person } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

import { Team } from '../../../services/http-services/api';

function EditPerson() {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const [id, setId] = React.useState(0);
  const [name, setPersonName] = React.useState('');

  const [email, setEmail] = useState('');
  const [personRole, setPersonRole] = useState('');
  const [active, setActive] = useState(false);
  const [canBeBooked, setCanBeBooked] = useState(false);
  const [commentColumn, setCommentColumn] = useState(false);
  const [bookingSuggestion, setBookingSuggestion] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [team, setTeam] = useState<number>(0);
  const [teams, setTeams] = useState<Team[] | null>(null);
  const [test, setTest] = useState(false);

  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const classes = ApplicationUseStyles();
  const history = useHistory();
  const params = useParams<props>();

  interface props {
    personId: string;
  }

  async function loadTeams() {
    const TeamList = await teamService.getAll();
    setTeams(TeamList);
  }

  async function dataFromProps() {
    const editPersonId = JSON.parse(params.personId);
    if (editPersonId) {
      const editPerson = await personService.getPersonById(parseInt(editPersonId, 10));
      if (editPerson.id) setId(editPerson.id);
      if (editPerson.name) setPersonName(editPerson.name);
      if (editPerson.email) setEmail(editPerson.email);
      if (editPerson.role) setPersonRole(editPerson.role);
      if (editPerson.active) setActive(editPerson.active);
      if (editPerson.canBeBooked) setCanBeBooked(editPerson.canBeBooked);
      if (editPerson.BookingSuggestion) setBookingSuggestion(editPerson.BookingSuggestion);
      if (editPerson.team) {
        const newTeam = editPerson.team;
        setTeam(newTeam); 
        setTest(true);
      }
      if (editPerson.Dashboard) setDashboard(editPerson.Dashboard);
      if (editPerson.CommentColumn) setCommentColumn(editPerson.CommentColumn);
    }
  }

  React.useEffect(() => {
    dataFromProps();
    loadTeams();
  }, []);

  const handleSubmit = async (event: any) => {
    event.persist();
    const role = personRole;

    if (name === '') {
      setSnackBarMessage(localized('NameIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (email === '') {
      setSnackBarMessage(localized('EmailIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (personRole === '') {
      setSnackBarMessage(localized('RoleIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (active === false) {
      // eslint-disable-next-line prefer-template
      setPersonName('Konsulent ' + id.toString(10));
    }

    await personService.update(
      new Person({
        id,
        name,
        email,
        role,
        active,
        canBeBooked,
        BookingSuggestion: bookingSuggestion,
        CommentColumn: commentColumn,
        Dashboard: dashboard,
        team
      })
    );
    history.push('/get-persons');
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSetInactive = () => {
    // eslint-disable-next-line prefer-template
    setPersonName('Konsulent ' + id.toString(10));
    setActive(false);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleChangeTeam = (event: any, value: Team | null) => {
    const newTeam = value!.id ? value!.id : 0;
    setTeam(newTeam);
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          {localized('UpdatePerson')}{' '}
        </Typography>
        {id ? (
          <div className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    id="outlined-input"
                    name="name"
                    label={localized('Name')}
                    type="text"
                    value={name}
                    className={classes.formInput}
                    variant="outlined"
                    onChange={(event: any) => {
                      event.persist();
                      setPersonName(event.target.value);
                    }}
                  />
                  <TextField
                    id="outlined-input"
                    name="email"
                    label={localized('Email')}
                    type="text"
                    value={email}
                    className={classes.formInput}
                    variant="outlined"
                    onChange={(event: any) => {
                      event.persist();
                      setEmail(event.target.value);
                    }}
                  />
                </Grid>
                {/* Radio buttons made with inspiration from  https://material-ui.com/components/radio-buttons/#radiogroup */}
                <Grid container>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{localized('Role')}</FormLabel>
                      <RadioGroup
                        aria-label="role"
                        name="role0"
                        value={personRole}
                        onChange={(event: any) => {
                          event.persist();
                          setPersonRole(event.target.value);
                        }}
                      >
                        <FormControlLabel value="User" control={<Radio />} label="User" />
                        <FormControlLabel value="Admin" control={<Radio />} label="Administrator" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup>
                      <Grid container>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label={localized('Active')}
                            labelPlacement="start"
                            style={{ display: 'table' }}
                            control={
                              <div style={{ display: 'table-cell' }}>
                                <Checkbox
                                  checked={active}
                                  onChange={(event: any) => {
                                    event.persist();
                                    if (active) {
                                      setAlertDialogVisible(true);
                                    } else {
                                      setActive(true);
                                    }
                                  }}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                {alertDialogVisible === true && active == true && (
                                  <AlertDialog
                                    acceptRequest={handleSetInactive}
                                    visible={alertDialogVisible}
                                    setVisible={(val) => setAlertDialogVisible(val)}
                                    title={localized('Anonymisation')}
                                    description={localized('AnonymisationText')}
                                  />
                                )}
                              </div>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label={localized('CanBeBooked')}
                            labelPlacement="start"
                            style={{ display: 'table' }}
                            control={
                              <div style={{ display: 'table-cell' }}>
                                <Checkbox
                                  checked={canBeBooked}
                                  onChange={(event: any) => {
                                    event.persist();
                                    setCanBeBooked(!canBeBooked);
                                  }}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </div>
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Autocomplete
                    id="TeamChange"
                    options={teams!}
                    getOptionLabel={(option: Team) => option.name}
                    renderInput={(params) => <TextField required {...params} label={localized('SelectTeam')} variant="outlined" />}
                    onChange={handleChangeTeam}
                    defaultValue={teams?.find(t => t.id === team)}
                  />
                </Grid>
                <Grid container justify="space-evenly">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className={classes.button}
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBackClick}
                  >
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className={classes.button}
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Update')}
                  </Button>
                </Grid>
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={isSnackBarOpen}
                  autoHideDuration={2500}
                  onClose={handleCloseSnackbar}
                >
                  <Alert severity={snackBarSeverity} variant="filled">
                    {snackBarMessage}
                  </Alert>
                </Snackbar>
              </div>
            </Grid>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </form>
  );
}

export default withRouter(EditPerson);
