import { SummaryPos } from './types/summary-pos';
import { ViewTypes } from './types/view-types';

export const Config = {
  schedulerWidth: '100%',
  besidesWidth: 30,
  schedulerMaxHeight: 650,
  tableHeaderHeight: 60,

  agendaResourceTableWidth: 160,
  agendaMaxEventWidth: 100,

  dayResourceTableWidth: 160,
  weekResourceTableWidth: '16%',
  monthResourceTableWidth: 160,
  quarterResourceTableWidth: 160,
  yearResourceTableWidth: 160,
  customResourceTableWidth: 160,

  dayCellWidth: 30,
  weekCellWidth: '12%',
  monthCellWidth: 90,
  quarterCellWidth: 90,
  yearCellWidth: 90,
  customCellWidth: 90,

  dayMaxEvents: 99,
  weekMaxEvents: 99,
  monthMaxEvents: 99,
  quarterMaxEvents: 99,
  yearMaxEvents: 99,
  customMaxEvents: 99,

  eventItemHeight: 22,
  eventItemLineHeight: 24,
  nonAgendaSlotMinHeight: 0,
  dayStartFrom: 0,
  dayStopTo: 23,
  defaultEventBgColor: '#80C5F6',
  selectedAreaColor: '#7EC2F3',
  nonWorkingTimeHeadColor: '#999999',
  nonWorkingTimeHeadBgColor: '#fff0f6',
  nonWorkingTimeBodyBgColor: '#fff0f6',
  summaryColor: '#666',
  summaryPos: SummaryPos.TopRight,
  groupOnlySlotColor: '#F8F8F8',

  startResizable: true,
  endResizable: true,
  movable: true,
  creatable: true,
  crossResourceMove: true,
  checkConflict: false,
  scrollToSpecialMomentEnabled: true,
  eventItemPopoverEnabled: true,
  calendarPopoverEnabled: true,
  recurringEventsEnabled: true,
  headerEnabled: true,
  displayWeekend: true,
  relativeMove: true,
  defaultExpanded: true,

  resourceName: 'Personer',
  eventItemPopoverDateFormat: 'MMM D',
  nonAgendaDayCellHeaderFormat: 'ha',
  nonAgendaOtherCellHeaderFormat: 'ddd D/M',

  minuteStep: 30,

  views: [
    { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
    { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
    { viewName: 'Quarter', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false },
    { viewName: 'HalfYear', viewType: ViewTypes.HalfYear, showAgenda: false, isEventPerspective: false }
  ]
};
