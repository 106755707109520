import { decodeToken } from './auth-helper';

type TokenProps = 'name' | 'exp' | 'role';
const AccessTokenKey = 'currentUser';

export function getAccessTokenFromLocalStorage() {
  return '';
}

function getFieldFromToken(field: TokenProps, token?: string | null) {
  let newtoken;
  if (token) {
    newtoken = token;
  } else {
    newtoken = localStorage.getItem(AccessTokenKey);
  }

  const decodedToken = decodeToken(newtoken);
  let tokenField = '';

  if (decodedToken) {
    tokenField = decodedToken[field] || '';
  }

  return tokenField;
}

export function getEmailFromToken(token?: string | null) {
  return getFieldFromToken('name', token);
}

export function getTimeForExpFromToken(token?: string | null) {
  return getFieldFromToken('exp', token);
}

export function getRoleFromToken(token?: string | null) {
  return getFieldFromToken('role', token);
}

export function readCookie(name: string) {
  const nameEQ = name.concat('=');
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let ch = ca[i];
    while (ch.charAt(0) === ' ') ch = ch.substring(1, ch.length);
    if (ch.indexOf(nameEQ) === 0) return ch.substring(nameEQ.length, ch.length);
  }
  return null;
}
