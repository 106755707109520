function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }
  return decodeURIComponent((window as any).escape(window.atob(output)));
}

export function decodeToken(token: string | null) {
  if (token === null || token === '') {
    return { upn: '' };
  }

  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

export function getTokenFromLocaleStorage() {
  const currentUser = localStorage.getItem('currentUser');
  if (!currentUser) {
    return false;
  }

  return currentUser;
}
