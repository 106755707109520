import { Box, Typography } from '@material-ui/core';
import React from 'react';

import WeekPicker from '../../../components/weekpicker/weekpicker';
import { localized } from '../../../utilities/language/i18n';

function TimerecordOverview() {
  React.useEffect(() => {
    document.title = 'Cenvation - Registreringsoversigt';
  }, []);

  return (
    <Box>
      <Typography variant="h4" className="App_pageTitle">
        {localized('TimerecordOverview')}
      </Typography>
      <WeekPicker />
    </Box>
  );
}

export default TimerecordOverview;
