// https://material-ui-pickers.dev/demo/datepicker#customization

import clsx from 'clsx';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import endOfWeek from 'date-fns/endOfWeek';
import React from 'react';
import startOfWeek from 'date-fns/startOfWeek';
import isWithinInterval from 'date-fns/isWithinInterval';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/da';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createStyles } from '@material-ui/styles';
import { IconButton, Theme, makeStyles } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

import { localized } from '../../../utilities/language/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayWrapper: {
      position: 'relative'
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit'
    },
    customDayHighlight: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '2px',
      right: '2px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%'
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
      color: '#676767'
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    firstHighlight: {
      extend: 'highlight',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%'
    },
    endHighlight: {
      extend: 'highlight',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%'
    }
  })
);

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

interface weekpickerProps {
  onChange: (date: any) => void;
  startDate: Date;
}

function BookingWeekPicker(props: weekpickerProps) {
  const classes = useStyles();

  const handleWeekChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    props.onChange(date);
  };

  const formatWeekSelectLabel = (date: MaterialUiPickersDate, invalidLabel: string) => {
    if (!date) {
      return invalidLabel;
    }

    const dateClone = date;

    return dateClone && isValid(dateClone) ? `Start Date: ${moment(dateClone).format('Do MMMM YYYY')}` : invalidLabel;
  };

  const renderWrappedWeekDay = (date: MaterialUiPickersDate, newSelectedDate: MaterialUiPickersDate, dayInCurrentMonth: boolean) => {
    if (!date || !newSelectedDate) {
      return (
        <div>
          <p>An error occurred.</p>
        </div>
      );
    }
    const dateClone = date;
    const selectedDateClone = newSelectedDate;

    const start = startOfWeek(selectedDateClone, { locale });
    const end = endOfWeek(selectedDateClone, { locale });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <DatePicker
          label={localized('DateSelector')}
          value={props.startDate}
          onChange={handleWeekChange}
          renderDay={renderWrappedWeekDay}
          labelFunc={formatWeekSelectLabel}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default BookingWeekPicker;
