import { Configuration, Product, Client } from './http-services/api';

async function createConfiguration(configuration: Configuration) {
  const client = new Client();

  const result = await client.configuration(configuration);
  return result;
}

async function createProduct(product: Product) {
  const client = new Client();

  const result = await client.createProduct(product);
  return result;
}

async function Delete(configuration: Configuration) {
  const client = new Client();

  const result = await client.configuration4(configuration);
  return result;
}

async function deleteProduct(product: Product) {
  const client = new Client();

  const result = await client.deleteProduct(product);
  return result;
}

async function getAll() {
  const client = new Client();

  const result = await client.configurationAll();
  return result;
}

async function getAllProducts() {
  const client = new Client();

  const result = await client.productsAll();
  return result;
}

async function editConfiguration(configuration: Configuration) {
  const client = new Client();

  const result = await client.configuration2(configuration);
  return result;
}

async function editProduct(product: Product) {
  const client = new Client();

  const result = await client.editProduct(product);
  return result;
}

async function getConfigurationById(configurationId: number) {
  const client = new Client();

  const result = await client.configuration3(configurationId);
  return result;
}

async function getProductById(productId: number) {
  const client = new Client();

  const result = await client.getProductById(productId);
  return result;
}

async function getWeeklyHours() {
  const client = new Client();

  const result = await client.getWeeklyHours();
  return result;
}

export const configurationService = {
  createConfiguration,
  getAll,
  editConfiguration,
  getConfigurationById,
  Delete,
  getWeeklyHours,
  getAllProducts,
  createProduct,
  getProductById,
  deleteProduct,
  editProduct
};
