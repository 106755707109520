import {
  TextField,
  FormControlLabel,
  Button,
  FormGroup,
  Checkbox,
  Typography,
  Snackbar,
  Paper,
  Grid,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';

import { customerService } from '../../../services/customer.services';
import { Customer, Project } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { projectService } from '../../../services/project.service';
import { localized } from '../../../utilities/language/i18n';

function CustomerCopy() {
  const [name, setCustomerName] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [active, setActive] = useState(false);
  const [cvr, setCVR] = useState('');
  const [cc, setCC] = useState('');
  const [secret, setSecret] = useState('');
  const [defaultZendeskProject, setZenDesk] = useState(0);
  const [price, setPrice] = useState(0);
  const [oneBill, setOneBill] = useState(false);
  const [dataIsPresent, setDataIsPresent] = useState(false);

  const [selectedProject, setSelectedProject] = React.useState<Project>(new Project());
  const [customerProjects, setCustomerProjects] = React.useState<Project[] | null>();
  const [noCustomerProjects, setNoCustomerProjects] = useState(false);
  const [customerToCopy, setCustomerToCopy] = useState<Customer>();
  const [projects, setProjects] = React.useState<Project[] | null>();

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const classes = ApplicationUseStyles();
  const history = useHistory();
  const params = useParams<props>();

  function ZendeskView(zendeskNumber: number | undefined) {
    if (zendeskNumber === undefined || !isFinite(zendeskNumber)) {
      return null;
    } else setZenDesk(zendeskNumber);
  }

  interface props {
    copyCustomer: string;
  }

  React.useEffect(() => {
    function findCustomerProjects() {
      if (customerToCopy !== undefined) {
        setNoCustomerProjects(false);
        const customerProj = projects?.filter((project) => project.customerId === customerToCopy.id);
        if (customerProj === undefined) {
          setNoCustomerProjects(true);
        }
        setCustomerProjects(customerProj);
        if (defaultZendeskProject !== undefined && defaultZendeskProject !== 0) {
          const zenDefaultProj = customerProj?.find((customerProject) => customerProject.id === defaultZendeskProject);
          if (zenDefaultProj !== undefined) setSelectedProject(zenDefaultProj);
        }
      }
      return null;
    }

    findCustomerProjects();
  }, [customerToCopy]);

  React.useEffect(() => {
    async function dataFromProps() {
      const projectList = await projectService.getAll();
      setProjects(projectList);

      const copyCustomerId = JSON.parse(params.copyCustomer);
      if (copyCustomerId) {
        const copyCustomer = await customerService.getCustomerById(parseInt(copyCustomerId, 10));
        if (copyCustomer.name) setCustomerName(copyCustomer.name);
        if (copyCustomer.contactEmail) setEmail(copyCustomer.contactEmail);
        if (copyCustomer.contactName) setContactName(copyCustomer.contactName);
        if (copyCustomer.active) setActive(copyCustomer.active);
        if (copyCustomer.cvr) setCVR(copyCustomer.cvr);
        if (copyCustomer.cc) setCC(copyCustomer.cc);
        if (copyCustomer.secret) setSecret(copyCustomer.secret);
        if (copyCustomer.price) setPrice(copyCustomer.price);
        if (copyCustomer.oneBillPerProject) setOneBill(copyCustomer.oneBillPerProject);
        setDataIsPresent(true);
        setCustomerToCopy(copyCustomer);

        ZendeskView(copyCustomer.defaultZendeskProject);
      }
    }
    dataFromProps();
  }, []);

  const handleChangeContractName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactName(event.target.value);
  };

  const handleChangeCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value);
  };

  const handleChangeCCEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCC(event.target.value);
  };

  const handleChangeCustomerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeCVR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCVR(event.target.value);
  };

  const handleChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPrice(parseInt(event.target.value, 10));
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleOneBill = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOneBill(event.target.checked);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('CustomerNameisrequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (contactEmail === '') {
      setSnackBarMessage(localized('EmailIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (cvr === '' || cvr.length !== 8) {
      setSnackBarMessage(localized('CVRmustbe8digits'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(price) || price === 0) {
      setSnackBarMessage(localized('PriceIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    await customerService.create(
      new Customer({ name, contactEmail, contactName, cvr, defaultZendeskProject, cc, secret, active, price, oneBillPerProject: oneBill })
    );
    history.goBack();
  };

  const handleProjectChange = (eevent: any, value: Project | null) => {
    if (value !== null) {
      setSelectedProject(value);
    }
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const includeZendeskProject = () => {
    if (!noCustomerProjects) {
      if (defaultZendeskProject === 0) {
        return (
          <div>
            {customerProjects && (
              <Autocomplete
                id="SelectZendeskProject"
                options={customerProjects}
                getOptionLabel={(option: Project) => option.name}
                renderInput={(param) => <TextField {...param} label={localized('SelectZenDesk')} variant="outlined" />}
                onChange={handleProjectChange}
                value={selectedProject}
              />
            )}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className="App_pageTitle">
        {localized('CopyCustomer')}
      </Typography>
      {dataIsPresent ? (
        <Paper className={classes.paperPadding}>
          <Grid container>
            <div>
              <Grid container>
                <TextField
                  required
                  id="Customer name"
                  name="Customer name"
                  label={localized('CustomerName')}
                  type="text"
                  value={name}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCustomerName}
                />
                <TextField
                  id="Contact name"
                  name="Contact name"
                  label={localized('ContactName')}
                  type="text"
                  value={contactName}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeContractName}
                />
              </Grid>
              <Grid container>
                <TextField
                  id="Contact-cc-email"
                  name="Contact cc email"
                  label={localized('CCmail')}
                  type="text"
                  value={cc}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCCEmail}
                />
                <TextField
                  id="E-mail"
                  name="email"
                  label={localized('Email')}
                  type="text"
                  value={contactEmail}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCustomerEmail}
                />
              </Grid>
              <Grid container>
                <TextField
                  required
                  id="CVR"
                  name="CVR"
                  label={localized('CVR')}
                  type="text"
                  value={cvr}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCVR}
                />
                <TextField
                  id="Secret"
                  name="Secret"
                  label={localized('Secret')}
                  type="text"
                  value={secret}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeSecret}
                />
              </Grid>
              <Grid container>
                {includeZendeskProject()}
                <TextField
                  required
                  id="Price"
                  name="Price"
                  label={localized('Price')}
                  type="number"
                  value={price}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangePrice}
                />
              </Grid>
              <FormGroup>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      label={localized('Active')}
                      labelPlacement="start"
                      style={{ display: 'table' }}
                      control={
                        <div style={{ display: 'table-cell' }}>
                          <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      label={localized('OneBillPerProject')}
                      labelPlacement="start"
                      style={{ display: 'table' }}
                      control={
                        <div style={{ display: 'table-cell' }}>
                          <Checkbox checked={oneBill} onChange={handleOneBill} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </FormGroup>
              <Grid container justify="space-evenly">
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className={classes.button}
                  startIcon={<ArrowBackIcon />}
                  onClick={handleGoBackClick}
                >
                  {localized('GoBack')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('Create')}
                </Button>
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity={snackBarSeverity} variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>
            </div>
          </Grid>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default CustomerCopy;
