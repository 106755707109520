/* eslint-disable */
import { CircularProgress, Paper, TextField ,Typography, Snackbar, Box, TableContainer, Table, TableHead, TableRow, TableBody} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { Alert, Color, Autocomplete } from '@material-ui/lab';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../styles/use-styles';
import { useHistory } from 'react-router-dom';

import { personService } from '../../services/person.services';
import { economicServices } from '../../services/economic.services';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { localized } from '../../utilities/language/i18n';
import {Customer, Project, Timerecord} from '../../services/http-services/api';
import {customerService} from '../../services/customer.services';
import {timerecordService} from '../../services/timerecord.services';
import { projectService } from '../../services/project.service';
import { profileEnd, time } from 'console';

function AdminPage() {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');


  // Const's for sending status tp specific customer
  
  const [customers, setCustomers] = React.useState<Customer[]>();
  const [projectsForCustomer, setProjectsForCustomer] = React.useState<Project[]>();
  const [isSpecificCustomerSelected, setSpecificCustomerSelected] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();
  const [normalBillList, setNormalBillList] = React.useState<any[]>([]);
  const [otherBillList, setOtherBillList] = React.useState<any[]>([]);


  //

  const [loadingSendStatusMail, setLoadingSendStatusMail] = useState(false);
  const [loadingSendAdminMail, setLoadingSendAdminMail] = useState(false);
  const [loadingCustomerSyncronize, setCustomerSyncronizeLoading] = useState(false);
  const [loadingBillsSyncronize, setBillsSyncronizeLoading] = useState(false);
  const [loadingSendStatus, setSendStatus] = useState(false);
  const [loadingReminderStatus, setReminderStatus] = useState(false);

  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [alertDialogVisibleSpecific, setAlertDialogVisibleSpecific] = useState(false);

  const classes = ApplicationUseStyles();

  const classesTable = TableStyles();

  const history = useHistory();



  const handleCustomerChange = (event: any, value: Customer | null) => {

    if (value !== null) {
      setSelectedCustomer(value);
      setSpecificCustomerSelected(true);
      setNormalBillList([]);
    }
  };


useEffect(() => {
    async function getProjects()
    {
      if (selectedCustomer && selectedCustomer.id){
        const projects = await projectService.getCustomerProjects(selectedCustomer.id);
        setProjectsForCustomer(projects);
      } 
    };
    getProjects();
  },[selectedCustomer]);

useEffect(() => {
    async function getTimerecords()
    {
      if (projectsForCustomer){
        const TempNormalbillList : any [] = [];
        const TempOtherbillList : any [] = [];
        
        projectsForCustomer.forEach( async function(proj) {

          if(proj.active && proj.type && proj.type === 3 && proj.id){
            
            const timerecords : Timerecord[] = await timerecordService.getTimerecordsForProject(proj.id);
            const reducer = (accumulator : number, curr : number) => accumulator + curr;
            const totalhours = timerecords.length> 0 ? timerecords.map((innertr)=> (innertr.hours)).reduce(reducer) : 0;
            const newhours = timerecords.filter(innertr => innertr.status === "Created").length> 0 ? timerecords.filter(innertr => innertr.status === "Created").map(tr=> {return tr.hours}).reduce(reducer): 0;
            const billRecord = {
              totalhours : totalhours,
              newhours : newhours,
              name : proj.name
            };
            TempNormalbillList.push(billRecord);
            setNormalBillList(TempNormalbillList)
            }
          if(proj.active && proj.type && proj.type !== 3 && proj.id)
          {
            const othertimerecords : Timerecord[] = await timerecordService.getTimerecordsForProject(proj.id);
            const reducer = (accumulator : number, curr : number) => accumulator + curr;
            const totalhours = othertimerecords.length> 0 ? othertimerecords.map((innertr)=> (innertr.hours)).reduce(reducer) : 0;
            const newhours = othertimerecords.filter(innertr => innertr.status === "Created").length> 0 ? othertimerecords.filter(innertr => innertr.status === "Created").map(tr=> {return tr.hours}).reduce(reducer): 0;
            const billRecord = {
              totalhours : totalhours,
              newhours : newhours,
              name : proj.name
            };
            TempOtherbillList.push(billRecord);
            setOtherBillList(TempOtherbillList)
          }

          }
        );
      //  setNormalBillList(TempNormalbillList)
      ;}
    };
    getTimerecords();
  },[projectsForCustomer]);




  useEffect(() => {
    document.title = 'Cenvation - Administration';
    async function getCustomers(){
      const customerList = await customerService.getAll();
      setCustomers(customerList);
    };
    getCustomers();
  }, []);

  //  const handleSendStatusSpecificToSelf() = async () =>{
  //    setSendStatus(true);
  //    
  //    if (selectedCustomer && selectedCustomer.id){
  //      const status = await economicServices.sendStatusToSpecificCustomer(selectedCustomer.id);
  //      if (status) {
  //        setSnackBarMessage(localized('StatusHaveBeenSentouttoallActiveCustomers'));
  //        setIsSnackBarOpen(true);
  //        setSendStatus(false);
  //      } else {
  //        setSnackBarMessage(localized('ErrorStatusToActiveCustomers'));
  //        setSnackBarSeverity('error');
  //        setIsSnackBarOpen(true);
  //        setSendStatus(false);
  //      }
  //    }
  //  };

  const handleSendStatusSpecificClick = async () =>{
    setLoadingSendStatusMail(true);
    if (selectedCustomer && selectedCustomer.id){
      const status = await economicServices.sendStatusToSpecificCustomer(selectedCustomer.id);
      if (status) {
        setSnackBarMessage(localized("MailSentToCustomer"));
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
        setLoadingSendStatusMail(false);
        await customerService.updateStatusSent(selectedCustomer.id);
      } else {
        setSnackBarMessage(localized('ErrorStatusToActiveCustomers'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        setLoadingSendStatusMail(false);
      }
    }
  };

  const handleSendAdminStatusSpecificClick = async () =>{
    setLoadingSendAdminMail(true);
    if (selectedCustomer && selectedCustomer.id){
      const status = await economicServices.sendStatusAdminToSpecificCustomer(selectedCustomer.id);
      if (status) {
        setSnackBarMessage(localized('MailSentToAdmin'));
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
        setLoadingSendAdminMail(false);
      } else {
        setSnackBarMessage(localized('ErrorStatusToActiveCustomers'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        setLoadingSendAdminMail(false);
      }
    }
  };

  const handleSyncBillsButtonClick = async () => {
    setBillsSyncronizeLoading(true);
    const status = await economicServices.synchroniseBills();
    if (status) {
      setSnackBarMessage(localized('BillshavebeenSynchronized'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
      setBillsSyncronizeLoading(false);
    } else {
      setSnackBarMessage(localized('Errorinsynchronizingbills'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
      setBillsSyncronizeLoading(false);
    }
  };

  const handleSyncCustomersButtonClick = async () => {
    setCustomerSyncronizeLoading(true);
    const status = await economicServices.synchroniseCustomers();
    if (status) {
      setSnackBarMessage(localized('CustomersHaveBeenSynchronized'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
      setCustomerSyncronizeLoading(false);
    } else {
      setSnackBarMessage(localized('ErrorInSynchronizingCustomers'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
      setCustomerSyncronizeLoading(false);
    }
  };

  const handleSendStatusToAll = async () => {
    setSendStatus(true);
    const status = await economicServices.sendStatusToAll();
    if (status) {
      setSnackBarSeverity('success');
      setSnackBarMessage(localized('StatusHaveBeenSentouttoallActiveCustomers'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
      setSendStatus(false);
    } else {
      setSnackBarMessage(localized('ErrorStatusToActiveCustomers'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
      setSendStatus(false);
    }
  };

   const handleSendReminderToAll = async () => {
    setReminderStatus(true);
    const status = await personService.sendReminderToAll();
    if (status) {
      setSnackBarMessage(localized("ReminderMailSend"));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
      setReminderStatus(false);
    } else {
      setSnackBarMessage(localized('ErrorSendingReminder'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
      setReminderStatus(false);
    }
  };

  const handleAlertDialogVisible = () => {
    setAlertDialogVisible(true);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  
  const handleAlertDialogSpecificVisible = () => {
    setAlertDialogVisibleSpecific(true);
  };


  return (
    <Box  alignItems="center" justifyContent="center">
      <Typography variant="h4" className="App_pageTitle">{localized('AdminFunctions')}</Typography>

      <div className={classes.paperPadding}>
        <div className={classes.wrapper2} style={{ margin: 16 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {history.push('/administration/get-holidays');}}
          >
            {localized("Registrations")}
          </Button>
          {loadingCustomerSyncronize && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <div className={classes.wrapper2} style={{ margin: 16 }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loadingCustomerSyncronize}
            onClick={handleSyncCustomersButtonClick}
          >
            {localized('SynchronizeCustomers')}
          </Button>
          {loadingCustomerSyncronize && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>

        <div className={classes.wrapper2} style={{ margin: 16 }}>
          <Button fullWidth variant="contained" color="primary" onClick={handleSyncBillsButtonClick} disabled={loadingBillsSyncronize}>
            {localized('SynchronizeBills')}
          </Button>
          {loadingBillsSyncronize && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>

        <div className={classes.wrapper2} style={{ margin: 16 }}>
          <Button fullWidth variant="contained" color="primary" disabled={loadingSendStatus} onClick={() => handleAlertDialogVisible()}>
            {localized('SendStatusToCustomers')}
          </Button>
          {loadingSendStatus && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>

        <div className={classes.wrapper2} style={{ margin: 16 }}>
          <Button fullWidth variant="contained" color="primary" disabled={loadingReminderStatus} onClick={() => handleSendReminderToAll()}>
            {localized('SendReminderToAll')}
          </Button>
          {loadingReminderStatus && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <Typography variant="h5">{localized('ReminderToSpecificCustomer')}</Typography>
        <Autocomplete
          id="customerChange"
          options={customers ? customers : []}
          getOptionLabel={(option: Customer) => option.name}
          renderInput={(params) => <TextField required {...params} label={localized('SelectCustomer')} variant="outlined" />}
          onChange={handleCustomerChange}
        />

        { selectedCustomer && projectsForCustomer && projectsForCustomer.length > 0? 
        <Paper className={classes.paperPadding}>
        <Typography variant="h4"> {selectedCustomer.name} : {localized("Projects")}</Typography>
        <TableContainer component={Paper}>
          <Table className={classesTable.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right"> {localized('Name')}</StyledTableCell>
                <StyledTableCell align="right">{localized("HoursSinceLastInvoice")}</StyledTableCell>
                <StyledTableCell align="right">{localized("TotalHours")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {normalBillList.map((item, index) =>  { return [
                  <StyledTableRow >
                    <StyledTableCell align="right">{item.name}</StyledTableCell>
                    <StyledTableCell align="right">{String(item.newhours)}</StyledTableCell>
                    <StyledTableCell align="right">{item.totalhours}</StyledTableCell>
                  </StyledTableRow> ]}) }
            </TableBody>
            </Table>
          </TableContainer>
          
          <Typography variant="h4"> {selectedCustomer.name} : {localized("OtherProjects")}</Typography>
          <TableContainer component={Paper}>
          <Table className={classesTable.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right"> {localized('Name')}</StyledTableCell>
                <StyledTableCell align="right">{localized("HoursSinceLastInvoice")}</StyledTableCell>
                <StyledTableCell align="right">{localized("TotalHours")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {otherBillList.map((item, index) =>  { return [
                  <StyledTableRow >
                    <StyledTableCell align="right">{item.name}</StyledTableCell>
                    <StyledTableCell align="right">{String(item.newhours)}</StyledTableCell>
                    <StyledTableCell align="right">{item.totalhours}</StyledTableCell>

                  </StyledTableRow> ]}) }
            </TableBody>
            </Table>
          </TableContainer>
          
          
          <div className={classes.wrapper2}>
            <Button fullWidth variant="contained" color="primary" disabled={loadingReminderStatus} onClick={() => handleAlertDialogSpecificVisible()}>
              {localized('SendStatusMail')} : {selectedCustomer.name}
            </Button>
            {loadingSendStatusMail && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          <div className={classes.wrapper2}>
            <Button fullWidth variant="contained" color="primary" disabled={loadingReminderStatus} onClick={() => handleSendAdminStatusSpecificClick()}>
              {localized('SendStatusAdmin')} : {selectedCustomer.name}
            </Button>
            {loadingSendAdminMail && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Paper>
         
        : <Box></Box> }
        

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isSnackBarOpen}
          autoHideDuration={2500}
          onClose={handleCloseSnackbar}
        >
          <Alert severity={snackBarSeverity} variant="filled">
            {snackBarMessage}
          </Alert>
        </Snackbar>
        {alertDialogVisible === true && (
          <AlertDialog
            acceptRequest={handleSendStatusToAll}
            visible={alertDialogVisible}
            setVisible={(val) => setAlertDialogVisible(val)}
            title={localized('SendStatusMail')}
            description={localized('WantToSendStatusMailToAllActiveCustomers')}
          />
        )}
        {alertDialogVisibleSpecific === true && (
          <AlertDialog
            acceptRequest={handleSendStatusSpecificClick}
            visible={alertDialogVisibleSpecific}
            setVisible={(val) => setAlertDialogVisible(val)}
            title={localized('SendStatusMail')}
            description={localized('WantToSendStatusMail')}
          />
        )}
      </div>
    </Box>
  );
}

export default AdminPage;
