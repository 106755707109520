import { Client, Person } from './http-services/api';

async function create(person: Person) {
  const client = new Client();
  try {
    await client.person(person);
    return true;
  } catch (error) {
    return false;
  }
}

async function changePassword(email: string, newPassword: string, oldPassword: string) {
  const client = new Client();
  try {
    await client.changePassword(newPassword, oldPassword, email);
    return true;
  } catch (error) {
    return false;
  }
}

async function sendReminderToAll() {
  const client = new Client();
  try {
    await client.SendConsultantReminder();
    return true;
  } catch (error) {
    return false;
  }
}

async function update(person: Person) {
  const client = new Client();
  try {
    await client.person2(person);
    return true;
  } catch (error) {
    return false;
  }
}

async function getAll() {
  const client = new Client();

  const result = await client.personAll();
  return result;
}

async function getPersonById(personId: number) {
  const client = new Client();

  const result = await client.personById(personId);
  return result;
}

async function getActive() {
  const client = new Client();

  const result = await client.activePersons();
  return result;
}

async function resetPassword(personId: number) {
  const client = new Client();
  try {
    await client.resetPassword(personId);
    return true;
  } catch (error) {
    return false;
  }
}

async function sendReminder(personId: number) {
  const client = new Client();
  try {
    await client.reminder(personId);
    return true;
  } catch (error) {
    return false;
  }
}

export const personService = {
  create,
  getActive,
  getAll,
  changePassword,
  getPersonById,
  update,
  resetPassword,
  sendReminder,
  sendReminderToAll
};
