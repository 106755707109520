import React from 'react';
import { Box, Dialog, Button } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { SchedulerEvent } from '../booking-module/scheduler-bindings';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

interface HandleConflictsProps {
  IgnoreConflict: () => void;
  GoToConflictingBooking: (conflictingBooking: SchedulerEvent[]) => void;
  visible: boolean;
  setVisible: (val: boolean) => void;
  conflictingBookings: SchedulerEvent[];
}

export default function HandleConflictingDialog(props: HandleConflictsProps) {
  const { visible, setVisible, IgnoreConflict, GoToConflictingBooking } = props;
  const [open, setOpen] = React.useState(visible);

  const handleDisagree = () => {
    setOpen(false);
    setVisible(false);
  };

  const handleGoToConflictingBooking = () => {
    GoToConflictingBooking(props.conflictingBookings);
    setOpen(false);
    setVisible(false);
  };

  const handleIgnore = () => {
    IgnoreConflict();
    setOpen(false);
    setVisible(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleDisagree} aria-labelledby="confirm-modal-title" aria-describedby="confirm-modal-description">
        <DialogTitle id="confirm-modal-title">{localized('OverlappingBooking')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-modal-description" align="center">
            <b>
              {props.conflictingBookings.length} {localized('FollowingConflictsHaveOccured')}
            </b>
            <br />
            {props.conflictingBookings.map(({ title }) => (
              <Box margin={1}>{title}</Box>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary" variant="contained" size={buttonSize}>
            {localized('IWillChangeThisBooking')}
          </Button>
          <Button onClick={handleGoToConflictingBooking} variant="contained" color="primary" size={buttonSize}>
            {localized('GoToConflictingBooking')}
          </Button>
          <Button onClick={handleIgnore} color="primary" variant="contained" size={buttonSize}>
            {localized('IgnoreConflict')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
