// This file is made with inspiration from https://www.barrymichaeldoyle.com/use-viewport/

import { createContext, FC, useContext, useEffect, useState } from 'react';

interface IWindowWidth {
  width: number;
}

const WindowWidthContext = createContext<IWindowWidth>({
  width: window.innerWidth
});

export const WindowWidthProvider: FC = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <WindowWidthContext.Provider value={{ width }}>{children}</WindowWidthContext.Provider>;
};

export function useWindowWidth() {
  return useContext<IWindowWidth>(WindowWidthContext);
}
