import { CircularProgress, Table, TableContainer, TableHead, TableRow, TableBody, Button, Grid, Box, Typography } from '@material-ui/core';
import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ProjectManagementDTO } from '../../../services/http-services/api';
import { economicServices } from '../../../services/economic.services';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function ProjectManagement() {
  const [view, setView] = React.useState('active');
  const [allProjects, setAllProjects] = React.useState<ProjectManagementDTO[] | null>();
  const [activeProjects, setActiveProjects] = React.useState<ProjectManagementDTO[] | null>();
  const [inactiveProjects, setInactiveProjects] = React.useState<ProjectManagementDTO[] | null>();
  const [projects, setProjects] = React.useState<ProjectManagementDTO[] | null>();

  const params = useParams<props>();
  const classes = TableStyles();
  const history = useHistory();

  interface props {
    customerId: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const customerId = JSON.parse(params.customerId);
      if (customerId) {
        const allProjectsAPI = await economicServices.getProjectManagement(customerId, 'all');
        setAllProjects(allProjectsAPI);
        const activeProjectsAPI = await economicServices.getProjectManagement(customerId, 'active');
        setActiveProjects(activeProjectsAPI);
        setProjects(activeProjectsAPI);
        const inactiveProjectsAPI = await economicServices.getProjectManagement(customerId, 'inactive');
        setInactiveProjects(inactiveProjectsAPI);
      }
    }
    dataFromProps();
  }, [setProjects, setActiveProjects, setInactiveProjects]);

  const customerActive = (active: boolean | undefined) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const handleView = () => {
    const optionsSelect = document.getElementById('select-view') as HTMLSelectElement;
    const newView = optionsSelect!.value;
    if (newView == 'all') setProjects(allProjects);
    else if (newView == 'inactive') setProjects(inactiveProjects);
    else setProjects(activeProjects);
  };

  function totalInvoiced(data: ProjectManagementDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.hoursInvoiced === undefined) {
        return;
      }
      total += item.hoursInvoiced;
    });
    return total;
  }

  function totalSent(data: ProjectManagementDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.hoursSent === undefined) {
        return;
      }
      total += item.hoursSent;
    });
    return total;
  }

  function totatLast(data: ProjectManagementDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.hoursSinceLastInvoice === undefined) {
        return;
      }
      total += item.hoursSinceLastInvoice;
    });
    return total;
  }

  function totatBudget(data: ProjectManagementDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.budget === undefined) {
        return;
      }
      total += item.budget;
    });
    return total;
  }

  function totatTotal(data: ProjectManagementDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.total === undefined) {
        return;
      }
      total += item.total;
    });
    return total;
  }

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <Box marginTop={2}>
      {projects ? (
        <TableContainer>
          <div className="custom-select">
            <label>
              {localized('Show')}
              <select id="select-view" onChange={handleView}>
                <option value="active">{localized('Active')}</option>
                <option value="inactive">{localized('Inactive')}</option>
                <option value="all">{localized('All')}</option>
              </select>
            </label>
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">{localized('ProjectName')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Active')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Paid')}</StyledTableCell>
                <StyledTableCell align="right">{localized('HoursSent')}</StyledTableCell>
                <StyledTableCell align="right">{localized('HoursSinceLastInvoice')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Budget')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Total')}</StyledTableCell>
                <StyledTableCell align="right">{localized('HoursPerMonth')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((row: ProjectManagementDTO, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="right">
                    <Link
                      className={classes.removeUnderline}
                      to={{
                        pathname: `/hours-project/`.concat(row.id ? row.id.toString() : '')
                      }}
                    >
                      <Button className={classes.buttonWithoutCaps} variant="contained" fullWidth color="primary" size={buttonSize}>
                        {row.name}
                      </Button>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align="right">{customerActive(row.active)}</StyledTableCell>
                  <StyledTableCell align="right">{row.hoursInvoiced}</StyledTableCell>
                  <StyledTableCell align="right">{row.hoursSent}</StyledTableCell>
                  <StyledTableCell align="right">{row.hoursSinceLastInvoice}</StyledTableCell>
                  <StyledTableCell align="right">{row.budget}</StyledTableCell>
                  <StyledTableCell align="right">{row.total}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Link
                      className={classes.removeUnderline}
                      to={{
                        pathname: `/timerecords-for-project/`.concat(row.id ? row.id.toString() : '')
                      }}
                    >
                      <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
                        {localized('HoursPerMonth')}
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell align="left">{localized('Total')}: </StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
                <StyledTableCell align="right">{totalInvoiced(projects)}</StyledTableCell>
                <StyledTableCell align="right">{totalSent(projects)}</StyledTableCell>
                <StyledTableCell align="right">{totatLast(projects)}</StyledTableCell>
                <StyledTableCell align="right">{totatBudget(projects)}</StyledTableCell>
                <StyledTableCell align="right">{totatTotal(projects)}</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
      <Grid>
        <Box margin={2}>
          <Button
            variant="contained"
            color="primary"
            className="button"
            size={buttonSize}
            onClick={handleGoBackClick}
            startIcon={<ArrowBackIcon />}
          >
            {localized('GoBack')}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default ProjectManagement;
