/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Component, CSSProperties } from 'react';

import { SchedulerData } from './scheduler-bindings';
import { RenderData } from './scheduler-data';

interface ResourceViewProps {
  schedulerData: SchedulerData;
  contentScrollbarHeight: number;
  slotClickedFunc?: (schedulerData: SchedulerData, item: RenderData) => void;
  slotItemTemplateResolver?: (
    schedulerData: SchedulerData,
    item: RenderData,
    slotClickedFunc: (schedulerData1: SchedulerData, item1: RenderData) => void,
    width: number,
    css: string
  ) => JSX.Element;
  toggleExpandFunc?: (schedulerData: SchedulerData, slotId: string) => void;
}

class ResourceView extends Component<ResourceViewProps> {
  public render() {
    const { schedulerData, contentScrollbarHeight, slotClickedFunc, slotItemTemplateResolver, toggleExpandFunc } = this.props;
    const { renderData } = schedulerData;

    const width = schedulerData.getResourceTableWidth() - 2;
    const paddingBottom = contentScrollbarHeight;
    const displayRenderData = renderData.filter((ooo) => ooo.render);
    const resourceList = displayRenderData.map((item) => {
      const indents = [];
      for (let i = 0; i < item.indent; i++) {
        indents.push(<span key={`es${i}`} className="expander-space"></span>);
      }
      const indent = <span key={`es${item.indent}`} className="expander-space"></span>;

      indents.push(indent);

      const abc =
        slotClickedFunc === undefined ? (
          <span className="slot-cell">
            {indents}
            <span className="slot-text">{item.slotName}</span>
          </span>
        ) : (
          <span className="slot-cell">
            {indents}
            <a
              className="slot-text"
              onClick={() => {
                slotClickedFunc(schedulerData, item);
              }}
            >
              {item.slotName}
            </a>
          </span>
        );
      let slotItem = (
        <div title={item.slotName} className="overflow-text header2-text" style={{ textAlign: 'left' }}>
          {abc}
        </div>
      );
      if (slotItemTemplateResolver && slotClickedFunc !== undefined) {
        const temp = slotItemTemplateResolver(schedulerData, item, slotClickedFunc, width, 'overflow-text header2-text');
        if (temp) {
          slotItem = temp;
        }
      }

      const tdStyle: CSSProperties = { height: item.rowHeight };
      if (item.groupOnly) {
        tdStyle.backgroundColor = schedulerData.config.groupOnlySlotColor;
      }

      return (
        <tr key={item.slotId}>
          <td data-resource-id={item.slotId} style={tdStyle}>
            {slotItem}
          </td>
        </tr>
      );
    });

    return (
      <div style={{ paddingBottom }}>
        <table className="resource-table">
          <tbody>{resourceList}</tbody>
        </table>
      </div>
    );
  }
}

export default ResourceView;
