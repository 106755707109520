import {
  TextField,
  FormControlLabel,
  Button,
  FormGroup,
  Typography,
  Checkbox,
  Snackbar,
  Paper,
  Grid,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert, { Color } from '@material-ui/lab/Alert';

import { customerService } from '../../../services/customer.services';
import { Customer, Project } from '../../../services/http-services/api';
import AlertDialog from '../../../components/alert-dialog/alert-dialog';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { projectService } from '../../../services/project.service';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function CustomerEdit() {
  const [id, setId] = useState(0);
  const [name, setCustomerName] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [active, setActive] = useState(false);
  const [oneBill, setOneBill] = useState(false);
  const [cvr, setCVR] = useState('');
  const [cc, setCC] = useState('');
  const [price, setPrice] = useState(0);
  const [customerToEdit, setCustomerToEdit] = useState<Customer>();
  const [secret, setSecret] = useState('');

  const [projects, setProjects] = React.useState<Project[] | null>();
  const [selectedProject, setSelectedProject] = React.useState<Project>(new Project());
  const [customerProjects, setCustomerProjects] = React.useState<Project[] | null>();
  const [noCustomerProjects, setNoCustomerProjects] = useState(false);
  const [defaultZenProjectId, setDefualtZenProjectId] = useState(0);

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const [alertDialogVisible, setAlertDialogVisible] = React.useState(false);

  const classes = ApplicationUseStyles();

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    editCustomer: string;
  }

  React.useEffect(() => {
    function findCustomerProjects() {
      if (customerToEdit !== undefined) {
        setNoCustomerProjects(false);
        const customerProj = projects?.filter((project) => project.customerId === customerToEdit.id);
        if (customerProj === undefined) {
          setNoCustomerProjects(true);
        }
        setCustomerProjects(customerProj);
        if (defaultZenProjectId !== undefined && defaultZenProjectId !== 0) {
          const zenDefaultProj = customerProj?.find((customerProject) => customerProject.id === defaultZenProjectId);
          if (zenDefaultProj !== undefined) setSelectedProject(zenDefaultProj);
        }
      }
      return null;
    }

    findCustomerProjects();
  }, [customerToEdit]);

  React.useEffect(() => {
    async function dataFromProps() {
      const projectList = await projectService.getAll();
      setProjects(projectList);

      const editCustomerId = JSON.parse(params.editCustomer);
      if (editCustomerId) {
        const customer = await customerService.getCustomerById(parseInt(editCustomerId, 10));
        if (customer.secret) setSecret(customer.secret);
        if (customer.name) setCustomerName(customer.name);
        if (customer.oneBillPerProject) setOneBill(customer.oneBillPerProject);
        if (customer.id) setId(customer.id);
        if (customer.contactEmail) setEmail(customer.contactEmail);
        if (customer.contactName) setContactName(customer.contactName);
        if (customer.active) setActive(customer.active);
        if (customer.cvr) setCVR(customer.cvr.replace(/ /g, ''));
        if (customer.cc) setCC(customer.cc);
        if (customer.price) setPrice(customer.price);
        if (customer.defaultZendeskProject) setDefualtZenProjectId(customer.defaultZendeskProject);
        setCustomerToEdit(customer);
      }
    }

    dataFromProps();
  }, []);

  const handleProjectChange = (eevent: any, value: Project | null) => {
    if (value !== null) {
      setSelectedProject(value);
    }
  };

  const handleChangeContractName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactName(event.target.value);
  };

  const handleChangeCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value);
  };

  const handleChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value);
  };

  const handleChangeCCEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCC(event.target.value);
  };

  const handleChangeCustomerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeCVR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCVR(event.target.value);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    var value = parseFloat(event.target.value);
    setPrice(parseFloat(value.toFixed(2)));
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleOneBill = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOneBill(event.target.checked);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleAcceptDelete = async () => {
    if (customerToEdit !== undefined) {
      const result = await customerService.deleteCustomer(customerToEdit);

      if (result === false) {
        setSnackBarMessage(localized('CustomerCannotBeDeleted'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }
      history.push('/customer');
    }
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (customerToEdit !== undefined) {
      const updatedCustomer = customerToEdit;

      if (name === '') {
        setSnackBarMessage(localized('NameIsRequired'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }
      updatedCustomer.name = name;

      if (cvr === '') {
        setSnackBarMessage(localized('CVRIsRequired'));
        setIsSnackBarOpen(true);
        return;
      }
      updatedCustomer.cvr = cvr;

      updatedCustomer.secret = secret;

      if (contactEmail === '') {
        setSnackBarMessage(localized('ContactEmailIsRequired'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }

      updatedCustomer.contactEmail = contactEmail;

      if (contactName === '') {
        setSnackBarMessage(localized('ContactNameIsRequired'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }

      if (secret === '') {
        setSnackBarMessage(localized('SecretIsRequired'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }

      updatedCustomer.contactName = contactName;
      updatedCustomer.cc = cc;
      // updatedCustomer.secret = contactEmail;

      if (price === 0 || price === undefined) {
        setSnackBarMessage(localized('PriceIsRequired'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
        return;
      }
      if (customerToEdit.price !== price) {
        updatedCustomer.price = price;
        updatedCustomer.priceChangeDate = new Date();
      }

      if (selectedProject.id !== null || selectedProject.id !== undefined) {
        updatedCustomer.defaultZendeskProject = selectedProject.id;
      }

      updatedCustomer.id = id;
      updatedCustomer.active = active;
      updatedCustomer.oneBillPerProject = oneBill;

      await customerService.update(updatedCustomer);

      history.goBack();
    }
  };

  const handleAlertDialogVisible = () => {
    setAlertDialogVisible(true);
  };

  const includeZendeskProject = () => {
    if (!noCustomerProjects) {
      return (
        <div>
          {customerProjects && (
            <Autocomplete
              id="SelectZendeskProject"
              options={customerProjects}
              getOptionLabel={(option: Project) => option.name}
              renderInput={(param) => <TextField {...param} label={localized('SelectZenDesk')} variant="outlined" />}
              onChange={handleProjectChange}
              value={selectedProject}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdateCustomer')}
      </Typography>
      {id ? (
        <Paper className={classes.paperPadding}>
          <Grid container>
            <div>
              <Grid container>
                <TextField
                  required
                  id="Customer name"
                  name="Customer name"
                  label={localized('CustomerName')}
                  type="text"
                  value={name}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCustomerName}
                />
                <TextField
                  id="Contact name"
                  name="Contact name"
                  label={localized('ContactName')}
                  type="text"
                  value={contactName}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeContractName}
                />
              </Grid>
              <Grid container>
                <TextField
                  id="Contact-cc-email"
                  name="Contact cc email"
                  label={localized('CCmail')}
                  type="text"
                  value={cc}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCCEmail}
                />
                <TextField
                  id="E-mail"
                  name="email"
                  label={localized('Email')}
                  type="text"
                  value={contactEmail}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCustomerEmail}
                />
              </Grid>
              <Grid container>
                <TextField
                  required
                  id="CVR"
                  name="CVR"
                  label={localized('CVR')}
                  type="text"
                  value={cvr}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangeCVR}
                />
                <TextField
                  required
                  id="Price"
                  name="Price"
                  label={localized('Price')}
                  type="number"
                  value={price}
                  className={classes.formInput}
                  variant="outlined"
                  onChange={handleChangePrice}
                />
              </Grid>

              {includeZendeskProject()}
              <TextField
                id="Secret"
                name="Secret"
                label={localized('Secret')}
                type="text"
                value={secret}
                className={classes.formInput}
                variant="outlined"
                onChange={handleChangeSecret}
              />
              <FormGroup>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      label={localized('Active')}
                      labelPlacement="start"
                      style={{ display: 'table' }}
                      control={
                        <div style={{ display: 'table-cell' }}>
                          <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      label={localized('OneBillPerProject')}
                      labelPlacement="start"
                      style={{ display: 'table' }}
                      control={
                        <div style={{ display: 'table-cell' }}>
                          <Checkbox checked={oneBill} onChange={handleOneBill} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </FormGroup>

              <Grid container justify="space-evenly">
                <Button
                  variant="contained"
                  color="secondary"
                  size={buttonSize}
                  className={classes.button}
                  startIcon={<DeleteForeverIcon />}
                  onClick={handleAlertDialogVisible}
                >
                  {localized('DeleteCustomer')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className={classes.button}
                  onClick={handleGoBackClick}
                  startIcon={<ArrowBackIcon />}
                >
                  {localized('GoBack')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('UpdateCustomer')}
                </Button>
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity={snackBarSeverity} variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>

              {alertDialogVisible === true && (
                <AlertDialog
                  acceptRequest={handleAcceptDelete}
                  visible={alertDialogVisible}
                  setVisible={(val) => setAlertDialogVisible(val)}
                  title={localized('DeleteCustomer')}
                  description={localized('WantToDeleteCustomer')}
                />
              )}
            </div>
          </Grid>
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default CustomerEdit;
