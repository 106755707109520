import { makeStyles, createStyles, Theme, withStyles, TableCell, TableRow } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const ApplicationUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 150
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 300,
        display: 'flex',
        padding: 10
      }
    },
    wrapper: {
      width: '100%'
    },
    formInput: {
      width: '100%'
    },
    button: {
      margin: theme.spacing(1),
      textTransform: 'none'
    },
    paperPadding: {
      padding: 20,
      marginTop: 10
    },
    removeUnderline: {
      textDecoration: 'none'
    },
    wrapper2: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })
);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      textAlign: 'center',
      fontWeight: 'bold'
    },
    body: {
      fontSize: 14,
      textAlign: 'center'
    }
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow);

export const TableStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    table: {
      [theme.breakpoints.down('sm')]: {
        minWidth: 300
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 650
      },
      whiteSpace: 'pre'
    },
    button: {
      margin: theme.spacing(1),
      textTransform: 'none'
    },
    buttonWithoutCaps: {
      textTransform: 'none'
    },
    removeUnderline: {
      textDecoration: 'none'
    },
    paper: {
      margin: '20px 0',
      boxShadow: 'none'
    },
    maxwidth: {
      maxWidth: 300,
      overflow: 'auto'
    },
    maxWidthMobile: {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 135,
        overflow: 'auto'
      }
    },
    paddingZero: {
      paddingLeft: 0,
      paddingRight: 0
    }
  })
);

export const DataGridStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '0 !important',
      backgroundColor: 'rgba(255,255,255,0.65)',
      '& .MuiDataGrid-row': {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover
        }
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: 'white',
        color: 'black'
      },
      '& .MuiDataGrid-footer': {
        backgroundColor: 'white',
        color: 'black'
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center !important'
      },
      '& .MuiTypography-colorInherit': {
        backgroundColor: 'white',
        color: 'black'
      },
      '& .MuiIconButton-colorInherit': {
        backgroundColor: 'white',
        color: 'black'
      },
      '& .MuiSelect-select.MuiSelect-select': {
        backgroundColor: 'white',
        color: 'black'
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 150
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      },
      table: {
        [theme.breakpoints.down('sm')]: {
          minWidth: 300
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 650
        },
        whiteSpace: 'pre'
      },
      button: {
        margin: theme.spacing(1),
        textTransform: 'none'
      },
      buttonWithoutCaps: {
        textTransform: 'none'
      },
      removeUnderline: {
        textDecoration: 'none'
      },
      paper: {
        margin: 20
      },
      maxwidth: {
        maxWidth: 300,
        overflow: 'auto'
      },
      maxWidthMobile: {
        [theme.breakpoints.down('xs')]: {
          maxWidth: 135,
          overflow: 'auto'
        }
      },
      paddingZero: {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  })
);
