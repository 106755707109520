import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import format from 'date-fns/format';
import { CircularProgress, Button, Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';

import { timerecordService } from '../../services/timerecord.services';
import { TimerecordOverviewResponse } from '../../services/http-services/api';
import { ThemePrimaryColor } from '../../utilities/colors';
import { StyledTableCell, StyledTableRow } from '../../styles/use-styles';
import { localized, localizedDynamic } from '../../utilities/language/i18n';
import { personService } from '../../services/person.services';

interface TimerecordOverviewTableProps {
  startDate: Date;
  endDate: Date;
}

// https://www.tutorialspoint.com/javascript/javascript_boolean_object.htm
// Will always be false when loading data in
interface TimerecordOverviewResponseWithStatus extends TimerecordOverviewResponse {
  sent?: boolean;
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    whiteSpace: 'pre'
  },
  totalRow: {
    backgroundColor: ThemePrimaryColor,
    color: '#ffffff',
    borderBottomColor: ThemePrimaryColor
  }
});

export default function TimerecordOverviewTable(props: TimerecordOverviewTableProps) {
  const [timerecordOverviewResponse, setTimerecordOverviewResponse] = useState<TimerecordOverviewResponseWithStatus[] | null>();
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  function calculateTotalHours() {
    if (!timerecordOverviewResponse) return [0, 0, 0, 0, 0, 0, 0, 0];

    const newTotalHours = [0, 0, 0, 0, 0, 0, 0, 0];

    for (let index = 0; index < newTotalHours.length - 1; index++) {
      timerecordOverviewResponse.forEach((element) => {
        if (element.dateAndHours && element.dateAndHours[index].hour) newTotalHours[index]! += element.dateAndHours![index].hour!;
      });
    }

    newTotalHours[7] = newTotalHours.reduce((currentTotal, newValue) => currentTotal + newValue, 0);

    return newTotalHours;
  }

  React.useEffect(() => {
    async function loadTimerecordOverviewResponse() {
      if (!props.startDate || !props.endDate) {
        return;
      }

      const startDate = props.startDate;
      const endDate = props.endDate;
      startDate.setHours(startDate.getHours() + 2);
      endDate.setHours(endDate.getHours());
      const dataList = await timerecordService.getOverview(startDate, endDate);
      setTimerecordOverviewResponse(dataList);
    }
    loadTimerecordOverviewResponse();
  }, [props.startDate]);

  const classes = useStyles();

  const handleReminder = async (personId: number | undefined) => {
    if (personId !== undefined) {
      const status = await personService.sendReminder(personId);
      if (!status) {
        setSnackBarMessage(localized('ErrorSendingReminder'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
      }
      setSnackBarMessage(localized('ReminderMailSend'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    } else if (personId === undefined) {
      setSnackBarMessage(localized('PersonIdNotFound'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  return (
    <div>
      {timerecordOverviewResponse && timerecordOverviewResponse[0] && timerecordOverviewResponse[0].dateAndHours ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{localized('Consultant')}</StyledTableCell>
                {timerecordOverviewResponse[0].dateAndHours &&
                  timerecordOverviewResponse[0].dateAndHours.map((dateAndHours) => (
                    <StyledTableCell key={dateAndHours.date?.toString()}>
                      {
                        // eslint-disable-next-line prefer-template
                        dateAndHours.date &&
                          localizedDynamic(format(dateAndHours.date, 'EEEE dd-MM-yyyy').split(' ')[0]) +
                            '\n' +
                            format(dateAndHours.date, 'EEEE dd-MM-yyyy').split(' ')[1]
                      }
                    </StyledTableCell>
                  ))}
                <StyledTableCell className={classes.totalRow}>Total</StyledTableCell>
                <StyledTableCell className={classes.totalRow}>{localized('SendReminder')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timerecordOverviewResponse.map((timerecord) => (
                <StyledTableRow key={timerecord.consultantName}>
                  <StyledTableCell component="th" scope="row">
                    {timerecord.consultantName}
                  </StyledTableCell>
                  {timerecord.dateAndHours &&
                    timerecord.dateAndHours.map((dateAndHour) => (
                      <StyledTableCell align="right" key={dateAndHour.date?.toString()}>
                        {dateAndHour.hour?.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                      </StyledTableCell>
                    ))}
                  <StyledTableCell component="th" scope="row" className={classes.totalRow}>
                    {timerecord.dateAndHours
                      ?.reduce((currentTotal, newValue) => Number(currentTotal) + Number(newValue.hour), 0)
                      .toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={timerecord.sent}
                      onClick={() => {
                        handleReminder(timerecord.personId);
                        timerecord.sent = true;
                      }}
                    >
                      {localized('SendReminder')}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <StyledTableCell>{localized('Total')}</StyledTableCell>
                {calculateTotalHours().map((item, index) => (
                  <StyledTableCell key={index}>{item.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
