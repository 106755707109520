import React from 'react';
import {
  Snackbar,
  Theme,
  TableRow,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Button,
  TextField,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import { createStyles } from '@material-ui/styles';
import { useHistory, useParams, withRouter } from 'react-router-dom';

import { economicServices } from '../../services/economic.services';
import { projectService } from '../../services/project.service';
import { BillingForCustomerDTO, BillingRequest, BillingDTO, Project, Voucher } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow } from '../../styles/use-styles';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

interface BillingSpecificProps {
  customerId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    button: {
      margin: theme.spacing(1)
    }
  })
);

function BillingSpecific() {
  const [billingForCustomerDto, setBillingForCustomerDto] = React.useState<BillingForCustomerDTO | null>();
  const [billNumber, setBillNumber] = React.useState<number | undefined>(undefined);
  const [projects, setProjects] = React.useState<Project[]>();
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  const [vouchers, setVouchers] = React.useState<Voucher[] | null>();
  const [selectedVoucher, setSelectedVoucher] = React.useState<Voucher | null>();
  const [alertDialogVisible, setAlertDialogVisible] = React.useState(false);
  const [warningDialogVisible, setWarningDialogVisible] = React.useState(false);

  const params = useParams<BillingSpecificProps>();

  async function loadProjects() {
    const tempProjs = await projectService.getCustomerProjects(parseInt(params.customerId, 10));
    setProjects(tempProjs);
  }

  async function loadBillingForCustomerDto() {
    const billingDto = await economicServices.getCustomerBilling(parseInt(params.customerId, 10));
    setBillingForCustomerDto(billingDto);
    setVouchers(billingDto.vouchers);
  }

  React.useEffect(() => {
    loadProjects();
  }, []);

  React.useEffect(() => {
    loadBillingForCustomerDto();
    loadProjects();
  }, [setBillingForCustomerDto, snackBarMessage]);

  const classes = useStyles();
  const history = useHistory();

  const handleBillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setBillNumber(undefined);
    } else {
      const value = parseInt(event.target.value, 10);
      if (value > 0) {
        setBillNumber(value);
      } else {
        setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      }
    }
  };

  function FindProjectSubtracted(voucherListOne: Voucher[], voucherListTwo: Voucher[]) {
    var name = '';
    if (voucherListOne.length === voucherListTwo.length) {
      voucherListOne.forEach((element) => {
        const comparedElm = voucherListTwo.filter((voucher) => voucher.id === element.id)[0];
        if (comparedElm && element.remainingHours !== comparedElm.remainingHours && comparedElm.projectId)
          name = projects!.filter((proj) => proj.id === element.projectId)[0].name;
      });
    } else {
      voucherListOne.forEach((element) => {
        const comparedElm = voucherListTwo.filter((voucher) => voucher.id === element.id)[0];
        if (!comparedElm && element.projectId) name = projects!.filter((proj) => proj.id === element.projectId)[0].name;
      });
    }
    return name;
  }

  async function handleBillSpecificVoucher(voucherId: number | undefined) {
    //
    if (projects && voucherId) {
      const voucher = vouchers?.filter((elm) => elm.id == voucherId)[0];
      const projectIds = voucher!.projectId ? [voucher!.projectId] : projects.map((elm) => (elm.id ? elm.id : -1));
      await economicServices.billHoursWithSpecificVoucher(voucherId, projectIds!);
      setSnackBarMessage('Testing Voucher Functiuons');
      await loadProjects();
      await loadBillingForCustomerDto();
    }
  }

  const handleClickNewBill = async () => {
    var response;

    if (!selectedVoucher) {
      response = await economicServices.createNewBillFromTimerecords(parseInt(params.customerId, 10));
    } else {
      response = await economicServices.createNewBillFromTimerecordsSpecificProject(
        selectedVoucher!.projectId!,
        parseInt(params.customerId, 10)
      );
    }
    const billingDtoNew = await economicServices.getCustomerBilling(parseInt(params.customerId, 10));
    const ProjectName = vouchers && billingDtoNew.vouchers ? FindProjectSubtracted(vouchers, billingDtoNew.vouchers) : null;
    setSelectedVoucher(null);
    if (response === null || response === undefined) {
      setSnackBarMessage(localized('ErrorCreatingNewInvoice'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else if (response.hoursRemainingOnVouchers! > 0) {
      setSnackBarMessage(
        localized('VoucherInvoice1')
          .concat(`${response.totalHoursForCustomer}`)
          .concat(localized('VoucherInvoice2').concat(`${response.hoursRemainingOnVouchers}`).concat(localized('VoucherInvoice3')))
          .concat(ProjectName ? ', '.concat(localized('Voucher').concat(': ').concat(ProjectName)) : '')
      );
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    } else if (response.hoursOnVouchers! > 0) {
      setSnackBarMessage(
        localized('DraftAndVoucher1')
          .concat(`${response.hoursOnVouchers}`)
          .concat(
            localized('DraftAndVoucher2')
              .concat(`${response.hoursSentToEconomic}`)
              .concat(localized('DraftAndVoucher3'))
              .concat(ProjectName ? ', '.concat(localized('Voucher').concat(': ').concat(ProjectName)) : '')
          )
      );
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    } else {
      setSnackBarMessage(localized('DraftToEconomic1').concat(`${response.hoursSentToEconomic}`).concat(localized('DraftToEconomic2')));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    }
    setBillNumber(undefined);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleWarningDialogVisible = () => {
    //const projectIds = projects?.map(elm => elm.id)
    if (vouchers && vouchers!.map((elm) => elm.remainingHours).reduce((a, b) => a + b, 0) > 0) {
      setWarningDialogVisible(true);
    } else {
      handleClickNewBill();
    }
  };

  const handleAlertDialogVisible = () => {
    if (billNumber && billNumber > 0) {
      setAlertDialogVisible(true);
    } else {
      setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
  };

  const getTotal = (item: BillingDTO) => {
    const totals = item.projectForBillingDtos?.map((proj) => (proj.total ? proj.total : 0));
    const reducer = (accumulator: number | undefined, curr: number | undefined) => accumulator! + curr!;
    if (totals && totals.length > 0) {
      return totals?.reduce(reducer).toLocaleString('da-DK', { maximumFractionDigits: 2 });
    } else {
      return (0).toLocaleString('da-DK', { maximumFractionDigits: 2 });
    }
  };

  const ConvertState = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(true);
  };

  const handleClickWithBill = async () => {
    if ((billNumber === undefined || billNumber === 0) && params.customerId !== '6' && params.customerId !== '7') {
      setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    await economicServices.updateTimerecordsWithBill(new BillingRequest({ billNumber, customerId: parseInt(params.customerId, 10) }));
    history.goBack();
  };

  const handleInternalSubmit = async () => {
    setBillNumber(0);
    handleClickWithBill();
  };

  return (
    <div>
      {billingForCustomerDto ? (
        <div>
          <Typography variant="h4" className="App_pageTitle">
            {
              // eslint-disable-next-line prefer-template
              billingForCustomerDto && billingForCustomerDto.billingDto && billingForCustomerDto.billingDto.customerName
                ? localized('BillingSpecific') + ': ' + billingForCustomerDto.billingDto.customerName
                : localized('BillingSpecific')
            }{' '}
          </Typography>

          <Grid container direction="row" justify="center" alignItems="center">
            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
              <Table className={classes.table} aria-label="HourStatusTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">{localized('ProjectName')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Active')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Hours')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Rate')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Total')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingForCustomerDto.billingDto &&
                    billingForCustomerDto.billingDto.projectForBillingDtos &&
                    billingForCustomerDto.billingDto.projectForBillingDtos.map((row) => (
                      <StyledTableRow key={row.projectId}>
                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                        <StyledTableCell align="right">{ConvertState(row.active)}</StyledTableCell>
                        <StyledTableCell align="right">{row.hours!.toFixed(2)}</StyledTableCell>
                        <StyledTableCell align="right">
                          {row.rate ? row.rate.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.rate} kr.
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.total ? row.total.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.total} kr.
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  <StyledTableRow>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right">
                      {billingForCustomerDto.billingDto ? getTotal(billingForCustomerDto.billingDto) : 0} kr.
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={8}>
              <Box margin={2}>
                <TableContainer component={Paper}>
                  {billingForCustomerDto.vouchers && billingForCustomerDto.vouchers.length > 0 ? (
                    <Table aria-label=" VoucherTable">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="right">{localized('ProjectName')}</StyledTableCell>
                          <StyledTableCell align="right">{localized('BoundtoProject')}</StyledTableCell>
                          <StyledTableCell align="right"> {localized('HoursonVoucher')}</StyledTableCell>
                          <StyledTableCell align="right">{localized('UseVoucher')}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {billingForCustomerDto.vouchers &&
                          billingForCustomerDto.billingDto &&
                          billingForCustomerDto.vouchers.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell align="right">
                                {row.projectId && projects ? projects.filter((proj) => proj.id === row.projectId)[0].name : ''}
                              </StyledTableCell>
                              <StyledTableCell align="right">{row.projectId ? localized('Yes') : localized('No')}</StyledTableCell>
                              <StyledTableCell align="right">{row.remainingHours}</StyledTableCell>
                              <StyledTableCell align="right">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleBillSpecificVoucher(row.id)}
                                  size={buttonSize}
                                  className={classes.button}
                                >
                                  {localized('UseVoucher')}
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  ) : null}
                </TableContainer>
              </Box>
            </Grid>
            <Box padding={2} display="flex" justifyContent="center">
              <Button variant="contained" color="primary" onClick={handleGoBackClick} size={buttonSize} className={classes.button}>
                {localized('GoBack')}
              </Button>
              {parseInt(params.customerId, 10) !== 6 && parseInt(params.customerId, 10) !== 7 ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleWarningDialogVisible}
                    size={buttonSize}
                    className={classes.button}
                  >
                    {localized('CrateNewBillBasedOnTimerecord')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAlertDialogVisible}
                    size={buttonSize}
                    className={classes.button}
                  >
                    {localized('AddTimerecordToBill')}
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" color="primary" onClick={handleInternalSubmit} size={buttonSize} className={classes.button}>
                    {localized('ResetInternal')}
                  </Button>
                </>
              )}
              {params.customerId !== '6' && params.customerId !== '7' && (
                <TextField
                  type="text"
                  id="billSelect"
                  name="bill"
                  className={classes.button}
                  label={localized('BillNumber')}
                  variant="outlined"
                  onChange={handleBillChange}
                  value={billNumber ? billNumber.toString() : ''}
                  defaultValue={''}
                />
              )}
            </Box>

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={isSnackBarOpen}
              autoHideDuration={5000}
              onClose={handleCloseSnackbar}
            >
              <Alert severity={snackBarSeverity} variant="filled">
                {snackBarMessage}
              </Alert>
            </Snackbar>
            {alertDialogVisible === true && (
              <AlertDialog
                acceptRequest={handleClickWithBill}
                visible={alertDialogVisible}
                setVisible={(val) => setAlertDialogVisible(val)}
                title={localized('AddTimeregistrationstoInvoice')}
                description={localized('AreyousureRgistrationstoInvoice').concat(` ${billNumber}?`)}
              />
            )}
            {warningDialogVisible === true && (
              <AlertDialog
                acceptRequest={handleClickNewBill}
                visible={warningDialogVisible}
                setVisible={(val) => setWarningDialogVisible(val)}
                title={localized('YouHaveUnusedVouchers')}
                description={localized('AreYouSureYouWantToNewBillWithVoucher')}
              />
            )}
          </Grid>
        </div>
      ) : null}
    </div>
  );
}
export default withRouter(BillingSpecific);
