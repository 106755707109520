import { DropTarget } from 'react-dnd';
import moment from 'moment';
import { Identifier } from 'dnd-core';

import { navBarWidth } from '../../../utilities/constants';

import { getPos } from './util-helper';
import { DnDTypes } from './types/dnd-types';
import { CellUnits } from './types/cell-units';
import { DATETIME_FORMAT } from './types/date-formats';
import { ViewTypes } from './types/view-types';
import { SchedulerData } from './scheduler-bindings';
import ResourceEvents from './resource-events';
import { Event } from './scheduler-data';
import DnDSource from './dnd-source';

export default class DnDContext {
  public sourceMap: Map<any, any>;
  public DecoratedComponent: any;
  public getDropSpec = () => {
    return {
      drop: (
        props: {
          schedulerData: SchedulerData;
          resourceEvents: ResourceEvents;
        },
        monitor: any,
        component: any
      ) => {
        const { schedulerData, resourceEvents } = props;
        const { cellUnit } = schedulerData;
        const type = monitor.getItemType();
        const pos = getPos(component.eventContainer);
        const cellWidth = schedulerData.getContentCellWidth();
        let initialStartTime = null;
        let initialEndTime = null;
        if (type === DnDTypes.EVENT) {
          const initialPoint = monitor.getInitialClientOffset();
          let initialLeftIndex =
            Math.floor((initialPoint.x - pos.x - navBarWidth - schedulerData.config.agendaResourceTableWidth) / cellWidth) - 1;

          if (initialLeftIndex < 0) initialLeftIndex = 0;
          initialStartTime = resourceEvents.headerItems[initialLeftIndex].start;

          initialEndTime = resourceEvents.headerItems[initialLeftIndex].end;

          if (cellUnit !== CellUnits.Hour) {
            initialEndTime = moment(resourceEvents.headerItems[initialLeftIndex].start)
              .hour(23)
              .minute(59)
              .second(59)
              .format(DATETIME_FORMAT);
          }
        }
        const point = monitor.getClientOffset();
        let leftIndex = Math.floor((point.x - pos.x - navBarWidth - schedulerData.config.agendaResourceTableWidth) / cellWidth) - 1;

        if (leftIndex < 0) leftIndex = 0;

        const startTime = resourceEvents.headerItems[leftIndex].start;

        let endTime = resourceEvents.headerItems[leftIndex].end;
        if (cellUnit !== CellUnits.Hour) {
          endTime = moment(resourceEvents.headerItems[leftIndex].start).hour(23).minute(59).second(59).format(DATETIME_FORMAT);
        }

        return {
          slotId: resourceEvents.slotId,
          slotName: resourceEvents.slotName,
          start: startTime,
          end: endTime,
          initialStart: initialStartTime,
          initialEnd: initialEndTime
        };
      },

      hover: (
        props: {
          schedulerData: SchedulerData;
          resourceEvents: ResourceEvents;
          movingEvent: (
            schedulerData: SchedulerData,
            slotId: string,
            slotName: string,
            newStart: string,
            newEnd: string,
            action: string,
            type: Identifier,
            item: Event
          ) => void;
        },
        monitor: any,
        component: any
      ) => {
        const { schedulerData, resourceEvents, movingEvent } = props;
        const { cellUnit, config, viewType } = schedulerData;
        const item = monitor.getItem();
        const type = monitor.getItemType();
        const pos = getPos(component.eventContainer);
        const cellWidth = schedulerData.getContentCellWidth();
        let initialStart = null;

        if (type === DnDTypes.EVENT) {
          const initialPoint = monitor.getInitialClientOffset();
          let initialLeftIndex =
            Math.floor((initialPoint.x - pos.x - navBarWidth - schedulerData.config.agendaResourceTableWidth) / cellWidth) - 1;
          if (initialLeftIndex < 0) initialLeftIndex = 0;
          initialStart = resourceEvents.headerItems[initialLeftIndex].start;
        }

        const point = monitor.getClientOffset();
        let leftIndex = Math.floor((point.x - pos.x - navBarWidth - schedulerData.config.agendaResourceTableWidth) / cellWidth) - 1;
        if (leftIndex < 0) leftIndex = 0;
        const newStartH = resourceEvents.headerItems[leftIndex];
        let newStart = newStartH ? newStartH.start : schedulerData.startDate;
        const newEndH = resourceEvents.headerItems[leftIndex];
        let newEnd = newEndH ? newEndH.end : schedulerData.endDate;

        if (cellUnit !== CellUnits.Hour) {
          newEnd = moment(newStart).hour(23).minute(59).second(59).format(DATETIME_FORMAT);
        }

        let slotId = resourceEvents.slotId;
        let slotName = resourceEvents.slotName;
        let action = 'New';
        const isEvent = type === DnDTypes.EVENT;
        if (isEvent) {
          const event = item;
          if (config.relativeMove) {
            newStart = moment(event.start)
              .add(moment(newStart).diff(moment(initialStart)), 'ms')
              .format(DATETIME_FORMAT);
          } else if (viewType !== ViewTypes.Day) {
            const tmpMoment = moment(newStart);
            newStart = moment(event.start).year(tmpMoment.year()).month(tmpMoment.month()).date(tmpMoment.date()).format(DATETIME_FORMAT);
          }
          newEnd = moment(newStart)
            .add(moment(event.end).diff(moment(event.start)), 'ms')
            .format(DATETIME_FORMAT);

          // if crossResourceMove disabled, slot returns old value
          if (config.crossResourceMove === false) {
            slotId = schedulerData.getEventSlotId(item);
            slotName = 'undefined';
            const slot = schedulerData.getSlotById(slotId);
            if (slot && slot.name !== undefined) {
              slotName = slot.name;
            }
          }

          action = 'Move';
        }

        if (typeof movingEvent === 'function' && newStart !== undefined && newEnd !== undefined) {
          movingEvent(schedulerData, slotId, slotName, newStart, newEnd, action, type, item);
        }
      },

      canDrop: (
        props: {
          schedulerData: SchedulerData;
          resourceEvents: ResourceEvents;
        },
        monitor: any
      ) => {
        const { schedulerData, resourceEvents } = props;
        const item = monitor.getItem();
        if (schedulerData.isResizing()) {
          return false;
        }
        const { config } = schedulerData;
        return config.movable && !resourceEvents.groupOnly && (item.movable === undefined || item.movable !== false);
      }
    };
  };

  public getDropCollect = (connect: any, monitor: any) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver()
    };
  };

  public getDropTarget = () => {
    return DropTarget(Array.from(this.sourceMap.keys()), this.getDropSpec(), this.getDropCollect)(this.DecoratedComponent);
  };

  public getDndSource = (dndType = DnDTypes.EVENT) => {
    return this.sourceMap.get(dndType);
  };

  constructor(sources: DnDSource[], DecoratedComponent: any) {
    this.sourceMap = new Map();
    sources.forEach((item: DnDSource) => {
      this.sourceMap.set(item.dndType, item);
    });
    this.DecoratedComponent = DecoratedComponent;
  }
}
