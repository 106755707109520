import { Client, ProjectSubscriptionDTO } from './http-services/api';

async function getAll() {
  const client = new Client();

  const result = await client.projectSubscriptionAll();
  return result;
}

async function getById(id: number) {
  const client = new Client();

  const result = await client.projectSubscriptionById(id);
  return result;
}

async function updateProjectSubscriptions(projectSubscriptions: ProjectSubscriptionDTO[]) {
  try {
    const client = new Client();
    await client.projectSubscription(projectSubscriptions);

    return true;
  } catch (error) {
    return false;
  }
}

export const projectSubscriptionService = { getAll, updateProjectSubscriptions, getById };
