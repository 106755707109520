import { Button, TextField, Snackbar, Grid, CircularProgress, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';

import { Bill, Customer, VoucherRequest, Project } from '../../services/http-services/api';
import { customerService } from '../../services/customer.services';
import { voucherService } from '../../services/voucher.service';
import { billService } from '../../services/bill.service';
import { projectService } from '../../services/project.service';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

function CreateVoucher() {
  const [customers, setCustomers] = React.useState<Customer[] | null>();
  const [bills, setBills] = React.useState<Bill[] | null>();
  const [projects, setProjects] = React.useState<Project[] | null>();
  const [customerProjects, setCustomerProjects] = React.useState<Project[] | null>();

  const [originalSize, setOriginalSize] = React.useState(0);
  const [remainingHours, setRemainingHours] = React.useState(0);
  const [active] = React.useState(true);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>(new Customer());
  const [selectedBill, setSelectedBill] = React.useState<Bill>(new Bill());
  const [selectedProject, setSelectedProject] = React.useState<Project>(new Project());
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const history = useHistory();
  const classes = ApplicationUseStyles();

  React.useEffect(() => {
    function findCustomerProjects() {
      const customerProj = projects?.filter((project) => project.customerId === selectedCustomer.id && project.active);
      if (customerProj !== undefined) setCustomerProjects(customerProj);
    }

    findCustomerProjects();
  }, [selectedCustomer, projects]);

  React.useEffect(() => {
    async function loadCustomers() {
      const customerList = await customerService.getAll();
      setCustomers(customerList);
    }
    async function loadBills() {
      const billList = await billService.getAll();
      setBills(billList);
    }
    async function loadProjects() {
      const projectList = await projectService.getAll();
      setProjects(projectList);
    }
    loadBills();
    loadCustomers();
    loadProjects();
    document.title = 'Cenvation - Voucher';
  }, []);

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setSelectedCustomer(value);
    }
  };

  const handleBillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== null) {
      var currentCustId = selectedCustomer ? selectedCustomer.id! : 0;
      var currentBill = new Bill({ paid: false, customerId: currentCustId, number: parseInt(event.target.value, 10) });
      setSelectedBill(currentBill);
    }
  };

  const handleProjectChange = (eevent: any, value: Project | null) => {
    if (value !== null) {
      setSelectedProject(value);
    }
  };

  const handleOriginalSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemainingHours(parseFloat(event.target.value));
    setOriginalSize(parseFloat(event.target.value));
  };

  const includeProject = () => {
    return (
      <div>
        {customerProjects && (
          <Autocomplete
            id="SelectCustomerProject"
            options={customerProjects}
            getOptionLabel={(option: Project) => option.name}
            renderInput={(params) => <TextField {...params} label={localized('SelectProject')} variant="outlined" />}
            onChange={handleProjectChange}
          />
        )}
      </div>
    );
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (isNaN(originalSize) || originalSize === 0) {
      setSnackBarMessage(localized('VoucherHoursIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }
    if (selectedCustomer.id === undefined) {
      setSnackBarMessage(localized('CustomerIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }
    if (selectedBill.number === undefined) {
      setSnackBarMessage(localized('BillIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }
    await billService.createBill(selectedBill);
    var newBillId = (await billService.getCustomerBills(selectedCustomer.id)).filter((bill) => bill.number === selectedBill.number)[0].id;

    await voucherService.createVoucher(
      new VoucherRequest({
        originalSize,
        active,
        remainingHours,
        customerId: selectedCustomer.id,
        billId: newBillId!,
        projectId: selectedProject.id
      })
    );
    history.push('/get-vouchers');
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className="App_pageTitle">
        {localized('CreateVoucher')}
      </Typography>
      {customers && bills ? (
        <div className={classes.paperPadding}>
          <Grid container>
            <div>
              <Grid container>
                <Autocomplete
                  id="SelectVoucherCustomer"
                  options={customers}
                  getOptionLabel={(option: Customer) => option.name}
                  renderInput={(params) => <TextField required {...params} label={localized('SelectCustomer')} variant="outlined" />}
                  onChange={handleCustomerChange}
                />

                <TextField
                  required
                  id="CreateBillNumber"
                  name="original"
                  label={localized('BillNumber')}
                  type="number"
                  variant="outlined"
                  onChange={handleBillChange}
                />

                {
                  //  <Autocomplete
                  //  id="SelectVoucherBill"
                  //  options={bills}
                  //  getOptionLabel={(option: Bill) => option.number.toString()}
                  //  renderInput={(params) => <TextField required {...params} label={localized('SelectBill')} variant="outlined" />}
                  //  onChange={handleBillChange}
                }
              </Grid>
              <Grid container>
                <TextField
                  required
                  id="SelectVoucherSize"
                  name="original"
                  label={localized('SpecifyNumberofHours')}
                  type="number"
                  variant="outlined"
                  onChange={handleOriginalSizeChange}
                />
              </Grid>
              {includeProject()}
            </div>
          </Grid>

          <Button variant="contained" color="primary" size={buttonSize} className="button" startIcon={<SaveIcon />} onClick={handleSubmit}>
            {localized('Create')}
          </Button>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="warning" variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withRouter(CreateVoucher);
