import { Button, TextField, Grid, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';

import { Person } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { personService } from '../../../services/person.services';

const useStyles = makeStyles({
  centerjust: {
    display: 'flex',
    justifyContent: 'center'
  },
  padding50: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 10,
    paddingTop: 10
  }
});

export default function HolidayExcel() {
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [consultants, setConsultants] = React.useState<Person[] | null>();
  const [selectedConsultant, setSelectedConsultant] = React.useState<Person | null>();
  const classes = useStyles();
  const handleStartDateChange = (newDate: Date | null) => {
    setStartDate(newDate);
  };

  React.useEffect(() => {
    async function loadConsultants() {
      const result = await personService.getAll();
      setConsultants(result.filter((pers) => pers.active === true));
    }
    loadConsultants();
  }, []);

  const handleSubmit = async () => {
    if (startDate && endDate) {
      var csvContent: string = '';
      startDate.setHours(0, 0, 0); //00:00:00
      endDate.setHours(23, 0, 0); //GMT+0100 22:00:00
      //Turn holidayOrSick : bool into a number so that the CSV can be generated for arbitrary projects, using their projectId -> change backend endpoint for getHolidayCSV
      // (check api.tsx for place where the call is made, use ctrl + click on .getHolidayCSV to find it)
      var holidayOrSick = false; //Unused

      if (selectedConsultant) csvContent = await timerecordService.getHolidayCSV(startDate, endDate, holidayOrSick, selectedConsultant.id);
      if (!selectedConsultant) csvContent = await timerecordService.getHolidayCSV(startDate, endDate, holidayOrSick);

      const csvData = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=UTF-8;' });
      var csvUrl = URL.createObjectURL(csvData);

      var hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Registrations.csv';
      hiddenElement.click();
    }
  };

  const handleConsultantChange = (event: any, newConsultant: Person | null) => {
    if (newConsultant) {
      setSelectedConsultant(newConsultant);
    } else {
      setSelectedConsultant(null);
    }
  };

  const handleEndDateChange = (newDate: Date | null) => {
    setEndDate(newDate);
  };

  return (
    <form className="timerecord" noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('GetRegistrations')}
      </Typography>
      <div>
        <Grid container>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} className={classes.padding50}>
              <Autocomplete
                id="TimeRecord-select-Name"
                options={consultants ? consultants : []}
                getOptionLabel={(option) => (option ? option.name : '')}
                value={selectedConsultant ? selectedConsultant : null}
                renderInput={(inputParams) => (
                  <TextField required {...inputParams} label={localized('SelectConsultant')} variant="outlined" />
                )}
                onChange={handleConsultantChange}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label={localized('StartDate')}
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                TextFieldComponent={(inputParams) => <TextField fullWidth required {...inputParams} variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <KeyboardDatePicker
                disableToolbar
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label={localized('EndDate')}
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                TextFieldComponent={(inputParams) => <TextField fullWidth required {...inputParams} variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} className={classes.padding50}>
              <Button
                variant="contained"
                color="primary"
                size={buttonSize}
                className="button"
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              >
                {localized('GetExcel')}
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </div>
    </form>
  );
}
