import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/da';
import {
  MenuItem,
  Select,
  FormControlLabel,
  FormGroup,
  Checkbox,
  withStyles,
  TextField,
  Grid,
  makeStyles,
  FormControl,
  Box,
  Typography,
  Snackbar
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Alert } from '@material-ui/lab';

import { localized } from '../../../utilities/language/i18n';

const StyledTextField = withStyles((theme) => ({
  root: {
    width: 80,
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main
    }
  }
}))(TextField);

const useStyles = makeStyles({
  topMargin20: {
    marginTop: 20
  },
  formControl: {
    flexDirection: 'row',
    flex: 'auto'
  }
});

interface repeatProps {
  setRruleString: (rruleString: string) => void;
  returnRRule: boolean;
  startDate: Date;
}

function RepeatForm(props: repeatProps) {
  const [weekDays, setWeekDays] = React.useState({
    monday: true,
    tueday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  });
  const [repeat, setRepeat] = React.useState(1);
  const [repeatEnds, setRepeatEnds] = React.useState(1);
  const [weeklyEnds, setWeeklyEnds] = React.useState(1);
  const [monthlyEnds, setMonthlyEnds] = React.useState(1);
  const [endsAfter, setEndsAfter] = React.useState(1);
  const [onDate, setOnDate] = React.useState(props.startDate);
  const [monthRepeat, setMonthRepeat] = React.useState('on day');
  const [monthlyDate, setMonthlyDate] = React.useState('1');
  const [yearlyDate, setYearlyDate] = React.useState('1');
  const [onThe1Month, setOnThe1Month] = React.useState('First');
  const [onThe2Month, setOnThe2Month] = React.useState('Monday');
  const [onThe1Year, setOnThe1Year] = React.useState('First');
  const [onThe2Year, setOnThe2Year] = React.useState('Monday');
  const [monthYear, setMonthYear] = React.useState('January');
  const [monthYear2, setMonthYear2] = React.useState('January');
  const [yearRepeat, setYearRepeat] = React.useState('on');

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const { monday, tueday, wednesday, thursday, friday, saturday, sunday } = weekDays;

  const classes = useStyles();

  const handleMonthRepeatRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthRepeat((event.target as HTMLInputElement).value);
  };

  const handleYearRepeatRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearRepeat((event.target as HTMLInputElement).value);
  };

  const handleMonthOnThe1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOnThe1Month(event.target.value as string);
  };

  const handleMonthsInYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonthYear(event.target.value as string);
  };

  const handleMonthsInYear2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonthYear2(event.target.value as string);
  };

  const handleMonthOnThe2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOnThe2Month(event.target.value as string);
  };

  const handleYearOnThe1 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOnThe1Year(event.target.value as string);
  };

  const handleYearOnThe2 = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOnThe2Year(event.target.value as string);
  };

  const handleEndsAfter = (event: any) => {
    if (event.target.value > 0) {
      setEndsAfter(event.target.value);
    } else {
      setSnackBarMessage(localized('NumberCannotBeNegative'));
      setIsSnackBarOpen(true);
    }
  };

  const handleWeeklyEnds = (event: any) => {
    if (event.target.value > 0) {
      setWeeklyEnds(event.target.value);
    } else {
      setSnackBarMessage(localized('NumberCannotBeNegative'));
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleMonthlyEnds = (event: any) => {
    if (event.target.value > 0) {
      setMonthlyEnds(event.target.value);
    } else {
      setSnackBarMessage(localized('NumberCannotBeNegative'));
      setIsSnackBarOpen(true);
    }
  };

  const handleMonthlyDate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonthlyDate(event.target.value as string);
  };

  const handleYearlyDate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYearlyDate(event.target.value as string);
  };

  const handleRepeatRecurring = (event: any) => {
    setRepeat(event.target.value);
  };

  const handleRepeatRecurringEnds = (event: any) => {
    setRepeatEnds(event.target.value);
  };

  const handleOnDateChange = (date: Date | null) => {
    if (date !== null) setOnDate(date);
  };

  const handleWeekdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeekDays({ ...weekDays, [event.target.name]: event.target.checked });
  };

  const onTheOne = ['First', 'Second', 'Third', 'Fourth', 'Last'];
  const onTheTwo = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Day', 'Week day', 'Weekend day'];
  const monthsInYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const outputEndRRule = () => {
    let rruleEndOuputString = '';
    switch (repeatEnds) {
      case 1:
        rruleEndOuputString = rruleEndOuputString.concat(';UNTIL=');
        rruleEndOuputString = rruleEndOuputString.concat(onDate.getFullYear().toString());
        if (onDate.getMonth() < 9) {
          rruleEndOuputString = rruleEndOuputString.concat('0');
        }
        rruleEndOuputString = rruleEndOuputString.concat((onDate.getMonth() + 1).toString());
        if (onDate.getDate() < 9) {
          rruleEndOuputString = rruleEndOuputString.concat('0');
        }
        rruleEndOuputString = rruleEndOuputString.concat(onDate.getDate().toString());
        rruleEndOuputString = rruleEndOuputString.concat('T220000Z');

        return rruleEndOuputString;

      case 2:
        rruleEndOuputString = rruleEndOuputString.concat(';COUNT=');
        rruleEndOuputString = rruleEndOuputString.concat(endsAfter.toString());

        return rruleEndOuputString;
      default:
        return '';
    }
  };

  const outputRRule = () => {
    let rruleOutputString = '';
    const Days = [];
    switch (repeat) {
      case 1:
        rruleOutputString = '';
        rruleOutputString = rruleOutputString.concat('FREQ=WEEKLY;BYDAY=');

        // BYDAY

        if (monday) {
          Days.push('MO');
        }
        if (tueday) {
          Days.push('TU');
        }
        if (wednesday) {
          Days.push('WE');
        }
        if (thursday) {
          Days.push('TH');
        }
        if (friday) {
          Days.push('FR');
        }
        if (saturday) {
          Days.push('SA');
        }
        if (sunday) {
          Days.push('SU');
        }
        for (let index = 0; index < Days.length; index++) {
          rruleOutputString = rruleOutputString.concat(Days[index]);
          if (index === Days.length - 1) {
            rruleOutputString = rruleOutputString.concat(';');
          } else {
            rruleOutputString = rruleOutputString.concat(',');
          }
        }
        // INTERVAL
        rruleOutputString = rruleOutputString.concat('INTERVAL=');
        if (weeklyEnds === undefined || weeklyEnds < 1) {
          rruleOutputString = rruleOutputString.concat('1');
        } else {
          rruleOutputString = rruleOutputString.concat(weeklyEnds.toString());
        }

        return rruleOutputString;

      case 2:
        rruleOutputString = '';
        rruleOutputString = rruleOutputString.concat('FREQ=MONTHLY;');

        if (monthRepeat === 'on the') {
          // First dropdown
          rruleOutputString = rruleOutputString.concat('BYSETPOS=');
          if (onThe1Month === 'First') {
            rruleOutputString = rruleOutputString.concat('1;');
          }
          if (onThe1Month === 'Second') {
            rruleOutputString = rruleOutputString.concat('2;');
          }
          if (onThe1Month === 'Third') {
            rruleOutputString = rruleOutputString.concat('3;');
          }
          if (onThe1Month === 'Fourth') {
            rruleOutputString = rruleOutputString.concat('4;');
          }
          if (onThe1Month === 'Last') {
            rruleOutputString = rruleOutputString.concat('-1;');
          }

          // Second DropDown
          rruleOutputString = rruleOutputString.concat('BYDAY=');
          if (onThe2Month === onTheTwo[0]) {
            rruleOutputString = rruleOutputString.concat('MO;');
          }
          if (onThe2Month === onTheTwo[1]) {
            rruleOutputString = rruleOutputString.concat('TU;');
          }
          if (onThe2Month === onTheTwo[2]) {
            rruleOutputString = rruleOutputString.concat('WE;');
          }
          if (onThe2Month === onTheTwo[3]) {
            rruleOutputString = rruleOutputString.concat('TH;');
          }
          if (onThe2Month === onTheTwo[4]) {
            rruleOutputString = rruleOutputString.concat('FR;');
          }
          if (onThe2Month === onTheTwo[5]) {
            rruleOutputString = rruleOutputString.concat('SA;');
          }
          if (onThe2Month === onTheTwo[6]) {
            rruleOutputString = rruleOutputString.concat('SU;');
          }
          if (onThe2Month === onTheTwo[7]) {
            rruleOutputString = rruleOutputString.concat('MO, TU, WE, TH, FR, SA, SU;');
          }
          if (onThe2Month === onTheTwo[8]) {
            rruleOutputString = rruleOutputString.concat('MO, TU, WE, TH, FR;');
          }
          if (onThe2Month === onTheTwo[9]) {
            rruleOutputString = rruleOutputString.concat('SA, SU;');
          }
        } else if (monthRepeat === 'on day') {
          rruleOutputString = rruleOutputString.concat('BYMONTHDAY=');
          rruleOutputString = rruleOutputString.concat(monthlyDate.toString());
          rruleOutputString = rruleOutputString.concat(';');
        }

        // INTERVAL
        rruleOutputString = rruleOutputString.concat('INTERVAL=');
        if (weeklyEnds === undefined || weeklyEnds < 1) {
          rruleOutputString = rruleOutputString.concat('1');
        } else {
          rruleOutputString = rruleOutputString.concat(weeklyEnds.toString());
        }

        return rruleOutputString;

      case 3:
        rruleOutputString = '';
        rruleOutputString = rruleOutputString.concat('FREQ=YEARLY;');

        if (yearRepeat === 'on the') {
          rruleOutputString = rruleOutputString.concat('BYDAY=');

          if (onThe2Year === onTheTwo[0]) {
            rruleOutputString = rruleOutputString.concat('MO;');
          }
          if (onThe2Year === onTheTwo[1]) {
            rruleOutputString = rruleOutputString.concat('TU;');
          }
          if (onThe2Year === onTheTwo[2]) {
            rruleOutputString = rruleOutputString.concat('WE;');
          }
          if (onThe2Year === onTheTwo[3]) {
            rruleOutputString = rruleOutputString.concat('TH;');
          }
          if (onThe2Year === onTheTwo[4]) {
            rruleOutputString = rruleOutputString.concat('FR;');
          }
          if (onThe2Year === onTheTwo[5]) {
            rruleOutputString = rruleOutputString.concat('SA;');
          }
          if (onThe2Year === onTheTwo[6]) {
            rruleOutputString = rruleOutputString.concat('SU;');
          }
          if (onThe2Year === onTheTwo[7]) {
            rruleOutputString = rruleOutputString.concat('MO, TU, WE, TH, FR, SA, SU;');
          }
          if (onThe2Year === onTheTwo[8]) {
            rruleOutputString = rruleOutputString.concat('MO, TU, WE, TH, FR;');
          }
          if (onThe2Year === onTheTwo[9]) {
            rruleOutputString = rruleOutputString.concat('SA, SU;');
          }

          rruleOutputString = rruleOutputString.concat('BYSETPOS=');
          if (onThe1Year === 'First') {
            rruleOutputString = rruleOutputString.concat('1;');
          } else if (onThe1Year === 'Second') {
            rruleOutputString = rruleOutputString.concat('2;');
          } else if (onThe1Year === 'Third') {
            rruleOutputString = rruleOutputString.concat('3;');
          } else if (onThe1Year === 'Fourth') {
            rruleOutputString = rruleOutputString.concat('4;');
          } else if (onThe1Year === 'Last') {
            rruleOutputString = rruleOutputString.concat('-1;');
          } else {
            rruleOutputString = rruleOutputString.concat('1;');
          }
        }

        rruleOutputString = rruleOutputString.concat('BYMONTH=');

        if (monthYear === monthsInYear[0]) {
          rruleOutputString = rruleOutputString.concat('1');
        } else if (monthYear === monthsInYear[1]) {
          rruleOutputString = rruleOutputString.concat('2');
        } else if (monthYear === monthsInYear[2]) {
          rruleOutputString = rruleOutputString.concat('3');
        } else if (monthYear === monthsInYear[3]) {
          rruleOutputString = rruleOutputString.concat('4');
        } else if (monthYear === monthsInYear[4]) {
          rruleOutputString = rruleOutputString.concat('5');
        } else if (monthYear === monthsInYear[5]) {
          rruleOutputString = rruleOutputString.concat('6');
        } else if (monthYear === monthsInYear[6]) {
          rruleOutputString = rruleOutputString.concat('7');
        } else if (monthYear === monthsInYear[7]) {
          rruleOutputString = rruleOutputString.concat('8');
        } else if (monthYear === monthsInYear[8]) {
          rruleOutputString = rruleOutputString.concat('9');
        } else if (monthYear === monthsInYear[9]) {
          rruleOutputString = rruleOutputString.concat('10');
        } else if (monthYear === monthsInYear[10]) {
          rruleOutputString = rruleOutputString.concat('11');
        } else if (monthYear === monthsInYear[11]) {
          rruleOutputString = rruleOutputString.concat('12');
        } else {
          rruleOutputString = rruleOutputString.concat('1');
        }

        if (yearRepeat === 'on') {
          rruleOutputString = rruleOutputString.concat(';');
          rruleOutputString = rruleOutputString.concat('BYMONTHDAY=');
          if (yearlyDate === undefined) {
            rruleOutputString = rruleOutputString.concat('1');
          } else rruleOutputString = rruleOutputString.concat(yearlyDate.toString());
        }

        return rruleOutputString;
      default:
        return '';
    }
  };

  const ReturnRRule = () => {
    if (props.returnRRule === false) {
      return;
    }
    const returnStringRRule = outputRRule().concat(outputEndRRule());
    props.setRruleString(returnStringRRule);
  };

  React.useEffect(() => {
    if (props.returnRRule === false) {
      return;
    }
    ReturnRRule();
  }, [props.returnRRule]);

  const numberInMonth = () => {
    const numberArray = [];
    for (let i = 1; i < 32; i++) {
      numberArray.push(i.toString());
    }
    return numberArray;
  };

  return (
    <div>
      <Box marginTop={2} marginBottom={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h5">{localized('Repeat')}</Typography>
          <Grid item xs={4}>
            <FormControl variant="outlined">
              <Select labelId="label" id="select" value={repeat} defaultValue={1} onChange={handleRepeatRecurring}>
                <MenuItem value={1}>{localized('Weekly')}</MenuItem>
                <MenuItem value={2}>{localized('Monthly')}</MenuItem>
                <MenuItem value={3}>{localized('Yearly')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Collapse in={repeat === 1}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTextField
                    margin="dense"
                    id="WeeklyEnds"
                    type="number"
                    value={weeklyEnds}
                    onChange={handleWeeklyEnds}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    {localized('RepeatsEvery')} {weeklyEnds} {localized('Week')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup row={true}>
                    <Grid container>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={<Checkbox checked={monday} onChange={handleWeekdayChange} name="monday" />}
                          label={localized('Monday')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={tueday} onChange={handleWeekdayChange} name="tueday" />}
                          label={localized('Tuesday')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={wednesday} onChange={handleWeekdayChange} name="wednesday" />}
                          label={localized('Wednesday')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={thursday} onChange={handleWeekdayChange} name="thursday" />}
                          label={localized('Thursday')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={<Checkbox checked={friday} onChange={handleWeekdayChange} name="friday" />}
                          label={localized('Friday')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={saturday} onChange={handleWeekdayChange} name="saturday" />}
                          label={localized('Saturday')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={sunday} onChange={handleWeekdayChange} name="sunday" />}
                          label={localized('Sunday')}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid container>
            <Collapse in={repeat === 2}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTextField
                    margin="dense"
                    id="MonthlyEnds"
                    type="number"
                    value={monthlyEnds}
                    onChange={handleMonthlyEnds}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    {localized('RepeatsEvery')} {monthlyEnds} {localized('Months')}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.topMargin20}>
                  <RadioGroup aria-label="monthRepeat" name="monthRepeat" value={monthRepeat} onChange={handleMonthRepeatRadio}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <Grid item xs={4}>
                              <FormControlLabel value="on day" control={<Radio />} label="on day" />
                            </Grid>
                            <Grid item xs={8}>
                              <Select
                                labelId="monthlyDate"
                                id="monthlyDate"
                                value={monthlyDate}
                                defaultValue={1}
                                onChange={handleMonthlyDate}
                              >
                                {numberInMonth().map((index) => (
                                  <MenuItem key={index} value={index}>
                                    {index}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControlLabel value="on the" control={<Radio />} label="on the" />
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select labelId="onThe1" id="Onthe1" value={onThe1Month} onChange={handleMonthOnThe1}>
                            {onTheOne.map((index) => (
                              <MenuItem key={index} value={index}>
                                {index}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select labelId="OnThe2" id="OnThe2" value={onThe2Month} onChange={handleMonthOnThe2}>
                            {onTheTwo.map((index) => (
                              <MenuItem key={index} value={index}>
                                {index}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid container>
            <Collapse in={repeat === 3}>
              <RadioGroup aria-label="YearRepeat" name="YearRepeat" value={yearRepeat} onChange={handleYearRepeatRadio}>
                <Grid item className={classes.topMargin20}>
                  <Grid container>
                    <Grid item xs={2}>
                      <FormControlLabel value="on" control={<Radio />} label="on" />
                    </Grid>
                    <Grid item xs={10}>
                      <FormControl variant="outlined">
                        <Select labelId="MonthsInYear" id="MonthsInYear" value={monthYear} defaultValue={1} onChange={handleMonthsInYear}>
                          {monthsInYear.map((index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <Select labelId="yearlyDate" id="yearlyDate" value={yearlyDate} defaultValue={1} onChange={handleYearlyDate}>
                          {numberInMonth().map((index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={2}>
                      <FormControlLabel value="on the" control={<Radio />} label="on the" />
                    </Grid>
                    <Grid item xs={10}>
                      <FormControl variant="outlined">
                        <Select labelId="onThe1Year" id="Onthe1Year" value={onThe1Year} defaultValue={1} onChange={handleYearOnThe1}>
                          {onTheOne.map((index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined">
                        <Select labelId="OnThe2Year" id="OnThe2Year" value={onThe2Year} defaultValue={'Monday'} onChange={handleYearOnThe2}>
                          {onTheTwo.map((index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined">
                        <Select
                          labelId="MonthsInYear2"
                          id="MonthsInYear2"
                          value={monthYear2}
                          defaultValue={1}
                          onChange={handleMonthsInYear2}
                        >
                          {monthsInYear.map((index) => (
                            <MenuItem key={index} value={index}>
                              {index}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>

      <Box marginTop={2} marginBottom={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant="h5">{localized('Ends')}</Typography>
          <FormControl variant="outlined">
            <Select labelId="EndsRepeat" id="EndsRepeat" value={repeatEnds} defaultValue={1} onChange={handleRepeatRecurringEnds}>
              <MenuItem value={1}>{localized('OnDate')}</MenuItem>
              <MenuItem value={2}>{localized('Aftercount')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Collapse in={repeatEnds === 1}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <div className={classes.topMargin20}>
                  <DatePicker
                    value={onDate}
                    onChange={(date) => handleOnDateChange(date)}
                    format="dd/MM/yyyy"
                    TextFieldComponent={(inputParams) => <TextField required {...inputParams} variant="outlined" />}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Collapse>
          </Grid>
          <Grid container>
            <Collapse in={repeatEnds === 2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {localized('Aftercount')} {endsAfter} {localized('bookings')}
                  </Typography>
                </Grid>
                <StyledTextField
                  margin="dense"
                  id="EndsAfter"
                  type="number"
                  value={endsAfter}
                  onChange={handleEndsAfter}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="warning" variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default RepeatForm;
