import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../services/auth.services';
import { decodeToken, getTokenFromLocaleStorage } from '../utilities/auth-helper';

// Renew 30min before
const RENEWAL_TIME_MS = 1800 * 1000;
/* eslint-disable */
let renewalTimeout: NodeJS.Timeout | undefined;
/* eslint-enable */
function unscheduleRenewal() {
  if (!renewalTimeout) {
    return;
  }
  if (renewalTimeout) {
    clearTimeout(renewalTimeout);
    renewalTimeout = undefined;
  }
}

function scheduleTokenRenewal(token: string) {
  unscheduleRenewal();

  const jwtToken = decodeToken(token);
  const expiresAt = jwtToken.exp;
  const expiresAtDate = new Date(expiresAt * 1000);

  if (expiresAt && expiresAtDate) {
    let timeToRenewal = expiresAtDate.getTime() - new Date().getTime() - RENEWAL_TIME_MS;
    if (timeToRenewal < 0) {
      timeToRenewal = 0;
    }

    renewalTimeout = setTimeout(() => {
      if (expiresAtDate.getTime() - new Date().getTime() - RENEWAL_TIME_MS < 0) {
        authenticationService.getNewToken();
      }
    }, timeToRenewal);
  }
}

function ProtectedRoute(props: { path: any; exact: any; component: any; location: any}) {
  const development = process.env.NODE_ENV === 'development';
  const condition = development || authenticationService.isAuthenticated();
  if (condition) {
    const token = getTokenFromLocaleStorage();
    if (token !== false && token !== undefined) scheduleTokenRenewal(token);
  }
  sessionStorage.setItem('curPage', props.location.pathname);
  return condition ? <Route path={props.path} exact={props.exact} component={props.component} /> : <Redirect to="/" />;
}
export default ProtectedRoute;
