import { TableRow, TableContainer, Table, TableHead, TableBody, CircularProgress, Box, Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import format from 'date-fns/format';
import locale from 'date-fns/locale/da';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { TimerecordsPerMonthDTO } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';
import { TableStyles, StyledTableCell, StyledTableRow } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

interface TimerecordForProjectParams {
  projectId: string;
}

function TimerecordForProject() {
  const [timerecordOverviewDTO, setTimerecordOverviewDTO] = useState<TimerecordsPerMonthDTO | null>();

  const params = useParams<TimerecordForProjectParams>();

  React.useEffect(() => {
    async function loadTimerecordOverviewDTOs() {
      const dtoObject = await timerecordService.getTimerecordsPerMonthForProject(parseInt(params.projectId, 10));
      setTimerecordOverviewDTO(dtoObject);
    }
    loadTimerecordOverviewDTOs();
  }, []);

  const classes = TableStyles();
  const history = useHistory();

  const handleGoBackClick = () => {
    history.goBack();
  };

  if (
    timerecordOverviewDTO &&
    timerecordOverviewDTO.dates &&
    timerecordOverviewDTO.hours &&
    timerecordOverviewDTO.dates.length === timerecordOverviewDTO.hours.length
  ) {
    const zippedData = timerecordOverviewDTO.dates.map((x, i) => [x, timerecordOverviewDTO.hours![i]!]);

    return (
      <Box>
        <Typography variant="h4" className="App_pageTitle">
          {localized('HoursPerMonthFor')} {timerecordOverviewDTO.projectName}
        </Typography>
        <div>
          <TableContainer key={1} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell> {localized('Date')}</StyledTableCell>
                  <StyledTableCell> {localized('Hours')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {zippedData.map((item, index) => (
                  <StyledTableRow key={index}>
                    {
                      <StyledTableCell component="th" scope="row">
                        {format(item[0], 'MMMM yyyy')}
                      </StyledTableCell>
                    }
                    <StyledTableCell component="th" scope="row">
                      {item[1]}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box marginTop={2}>
            <Button variant="contained" color="primary" size={buttonSize} onClick={handleGoBackClick} startIcon={<ArrowBackIcon />}>
              {localized('GoBack')}
            </Button>
          </Box>
        </div>
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
}

export default withRouter(TimerecordForProject);
