import React from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import { projectService } from '../../../services/project.service';
import { Project } from '../../../services/http-services/api';
import { customerService } from '../../../services/customer.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { TableStyles, DataGridStyle } from '../../../styles/use-styles';

function GetCustomerProjects() {
  const [projects, setProject] = React.useState<Project[] | null>();
  const [name, setName] = React.useState<String | null>();
  const params = useParams<props>();

  interface props {
    customerProjects: string;
  }

  function sortByIsActive(a: Project, b: Project): number {
    if (a.active && !b.active) {
      return -1; // `a` comes before `b`
    } else if (!a.active && b.active) {
      return 1; // `b` comes before `a`
    } else {
      return 0; // No change in order
    }
  }

  React.useEffect(() => {
    async function loadCustomers() {
      const customerList = await customerService.getAll();
      const customerId = parseInt(JSON.parse(params.customerProjects), 10);
      const customerName = customerList.find((customer) => customer.id === customerId)!.name;
      setName(customerName);
    }
    async function dataFromProps() {
      //loadCustomers();
      const customerId = parseInt(JSON.parse(params.customerProjects), 10);
      if (customerId) {
        const custumerProjets = await projectService.getCustomerProjects(customerId);
        custumerProjets.sort(sortByIsActive);
        setProject(custumerProjets);
      }
    }
    dataFromProps();
    loadCustomers();
  }, []);

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  function showType(type: any) {
    if (type === undefined || type === null || !isFinite(type)) {
      return null;
    }
    switch (type) {
      case 4:
        return 'Business Central';
      case 3:
        return 'Project';
      case 2:
        return 'CE';
      case 1:
        return 'Internal';
      default:
        return 'No type found';
    }
  }

  const customerActive = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const isUndefined = (input: any) => {
    if (typeof input === 'undefined') {
      return '-';
    } else {
      return input;
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: localized('ProjectName'), flex: 0.5 },
    {
      field: 'type',
      headerName: localized('Type'),
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params) => {
        return <div>{showType(params.getValue(params.id, 'type'))}</div>;
      }
    },
    {
      field: 'budget',
      headerName: localized('Budget'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{isUndefined(params.getValue(params.id, 'budget'))}</div>;
      }
    },
    {
      field: 'active',
      headerName: localized('Active'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{customerActive(params.getValue(params.id, 'active'))}</div>;
      }
    },
    {
      field: 'edit',
      headerName: localized('Update'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/edit-project/${params.getValue(params.id, 'id')}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Update')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'copy',
      headerName: localized('Copy'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            className={classes.removeUnderline}
            to={{
              pathname: `/copy-project/${params.getValue(params.id, 'id')}`
            }}
          >
            <Button className={classes.buttonWithoutCaps} variant="contained" color="primary" size={buttonSize}>
              {localized('Copy')}
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('Projects') + ' for ' + name}
      </Typography>
      {projects ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {projects ? (
              <DataGrid
                className={classesGrid.root}
                rows={projects}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[15, 25, 50, 100]}
                filterModel={{
                  items: [{ columnField: 'customer', operatorValue: 'contains' }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(GetCustomerProjects);
