import {
  Dialog,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { StyledTableCell, StyledTableRow } from '../../styles/use-styles';

import { Customer } from '../../services/http-services/api';
import { customerService } from '../../services/customer.services';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

const useStyles = makeStyles({
  table: {
    minWidth: 400
  },
  paper: {
    padding: 40
  },
  padding50: {
    padding: 50,
    paddingTop: 10
  },
  centerjust: {
    display: 'flex',
    justifyContent: 'center',
    marginButtom: 25,
    marginTop: 25
  },
  number: {
    marginRight: 20
  },
  bottomButton: {
    marginBottom: 15
  }
});

interface formProps {
  visible: boolean;
  setVisible: (open: boolean) => void;
  selectedCustomers: Customer[] | undefined;
  updatedPrice: () => void;
}

function ChangePriceModal(props: formProps) {
  const classes = useStyles();

  const { visible, setVisible, selectedCustomers, updatedPrice } = props;

  const [open, setOpen] = React.useState(visible);
  const [customerInfo] = React.useState<Customer[] | undefined>(selectedCustomers);
  const [adjustPercent, setAdjustPercent] = React.useState<number>(0);
  const [newPrice, setNewPrice] = React.useState<number[] | undefined>(selectedCustomers?.map((customer) => customer.price));

  const handleClose = () => {
    setOpen(false);
    setVisible(false);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAdjustPercent(parseFloat(event.target.value));
  };

  React.useEffect(() => {
    function calculateNewPrice() {
      const newPrice = selectedCustomers?.map((customer) => customer.price * (1 + adjustPercent / 100));
      if (customerInfo !== undefined) setNewPrice(newPrice);
    }
    calculateNewPrice();
  }, [adjustPercent]);

  const handleSubmit = async (event: any) => {
    event.persist();
    if (selectedCustomers !== undefined && newPrice !== undefined) {
      const updatedCustomers = selectedCustomers.map((customer, index) => {
        const updatedCustomer = customer;
        updatedCustomer.price = newPrice[index];
        return updatedCustomer;
      });
      await customerService.priceChange(updatedCustomers);
      updatedPrice();
      handleClose();
    }
  };

  const rowSuggestions = () => {
    if (selectedCustomers === undefined) {
      return null;
    } else {
      return (
        <TableBody>
          {selectedCustomers.map((row: Customer, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.price.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}</StyledTableCell>
              <StyledTableCell align="right">
                {(row.price * (1 + adjustPercent / 100)).toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdatePriceCustomerHours')}
      </Typography>
      {customerInfo ? (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm">
          <Grid container direction="row" justify="center" alignItems="center" className={classes.bottomButton}>
            <Grid item xs={12} className={classes.centerjust}>
              <TextField
                id="PriceAdjustment"
                name="PriceAdjustment"
                label={localized('PriceAdjustment')}
                type="number"
                value={adjustPercent}
                onChange={handleChangePrice}
                variant={'outlined'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.centerjust}>
              <Button variant="contained" size={buttonSize} color="primary" onClick={handleSubmit}>
                {localized('SubmitAnnualPriceChange')}
              </Button>
            </Grid>
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center">
            <TableContainer component={Paper}>
              <Table aria-label="customerTabel" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">{localized('CustomerName')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('CurrentCustomerPrice')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('NewCustomerPrice')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                {rowSuggestions()}
              </Table>
            </TableContainer>
          </Grid>
        </Dialog>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default ChangePriceModal;
