export const ViewTypes = {
  Day: 0,
  Week: 1,
  Month: 2,
  Quarter: 3,
  HalfYear: 4,
  Custom: 5,
  Custom1: 6,
  Custom2: 7
};
