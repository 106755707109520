import { Client, Bill, CreateBillDTO } from './http-services/api';

async function getAll() {
  const client = new Client();

  const result = await client.billAll();
  return result;
}

async function getCustomerBills(customerId: number) {
  const client = new Client();

  const result = await client.customerBill(customerId);
  return result;
}

async function getBillById(billId: number) {
  const client = new Client();

  const result = await client.billById(billId);
  return result;
}

async function updateBill(bill: Bill) {
  const client = new Client();

  const result = await client.bill2(bill);
  return result;
}

async function createBill(createBillDTO: CreateBillDTO) {
  const client = new Client();

  const result = await client.bill(createBillDTO);
  return result;
}

export const billService = { getAll, getCustomerBills, getBillById, updateBill, createBill };
