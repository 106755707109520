import { TextField, CircularProgress, Button, Snackbar, Typography, Box } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import React, { useState } from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { personService } from '../../../services/person.services';
import { teamService } from '../../../services/team.services';
import { TableStyles, DataGridStyle } from '../../../styles/use-styles';
import AlertDialog from '../../../components/alert-dialog/alert-dialog';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function GetAllPersons() {
  const [savedPage, setSavedPage] = useState(0);
  const [searchName, setSearchName] = useState('');
  const [persons, setPersons] = useState<any[] | null>(null);
  const [personIdState, setPersonIdState] = useState<number | undefined>();
  const [resetPasswordName, setResetPasswordName] = useState('');
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [teams, setTeams] = useState<any[] | null>(null);

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  async function loadPersons() {
    const personList = await personService.getAll();
    setPersons(personList);
  }

  async function loadTeams() {
    const TeamList = await teamService.getAll();
    setTeams(TeamList);
  }

  const handlePageChange = (page: number) => {
    localStorage.page = page;
    setSavedPage(page);
  };

  React.useEffect(() => {
    document.title = 'Cenvation - Persons';
  }, []);

  React.useEffect(() => {
    loadTeams();
    loadPersons();
  }, [setPersons, setTeams]);

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  const handleAlertDialogVisible = (personId: number | undefined, name: string) => {
    setResetPasswordName(name);
    if (personId === undefined) {
      setSnackBarMessage(localized('PersonIdNotFound'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
    setPersonIdState(personId);
    setAlertDialogVisible(true);
  };

  const handleReset = async () => {
    if (personIdState !== undefined) {
      const status = await personService.resetPassword(personIdState);
      if (!status) {
        setSnackBarMessage(localized('ErrorResettingPassword'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
      }
      setSnackBarMessage(localized('PasswordHaveReset'));
      setSnackBarSeverity('success');
      setIsSnackBarOpen(true);
    } else if (personIdState === undefined) {
      setSnackBarMessage(localized('PersonIdNotFound'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
  };

  const ConvertState = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const ConvertTeam = (teamId: any) => {
    if (teams) {
      const pTeam = teams.find(t => t.id === teamId);
      return pTeam ? pTeam.name : "--";
    } else {
      return "--"
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  function changeSearchVal(event: string) {
    setSearchName(event);
  }
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: localized('Name'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'email',
      headerName: localized('Email'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'team',
      headerName: 'Team',
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => {
        return <div>{ConvertTeam(params.row['team'])}</div>;
      }
    },
    {
      field: 'role',
      headerName: localized('Role'),
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'active',
      headerName: localized('Active'),
      align: 'center',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => {
        return <div>{ConvertState(params.row['active'])}</div>;
      }
    },
    {
      field: 'canBeBooked',
      headerName: localized('CanBeBooked'),
      align: 'center',
      headerAlign: 'center',
      width: 175,
      renderCell: (params) => {
        return <div>{ConvertState(params.row['canBeBooked'])}</div>;
      }
    },
    {
      field: 'update',
      headerName: localized('Update'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 175,
      renderCell: (params) => {
        return (
          <Link className={classes.removeUnderline} to={{ pathname: `/edit-person/${params.row['id']}` }}>
            <Button variant="contained" color="primary" size={buttonSize}>
              {localized('Update')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'view',
      headerName: localized('ViewAllTimerecord'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 175,
      renderCell: (params) => {
        return (
          <Link className={classes.removeUnderline} to={{ pathname: `/timerecords/${params.row['id']}` }}>
            <Button variant="contained" color="primary" size={buttonSize}>
              {localized('View')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'view2',
      headerName: localized('ViewWeeklyTimerecord'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 175,
      renderCell: (params) => {
        return (
          <Link className={classes.removeUnderline} to={{ pathname: `/timerecord-week/${params.row['id']}` }}>
            <Button variant="contained" color="primary" size={buttonSize}>
              {localized('View')}
            </Button>
          </Link>
        );
      }
    },
    {
      field: 'resetpassword',
      headerName: localized('PasswordReset'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 175,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size={buttonSize}
            className={classes.button}
            onClick={() => handleAlertDialogVisible(params.row['id'], params.row['name'])}
          >
            {localized('ResetPassword')}
          </Button>
        );
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('ViewAllPersons')}
      </Typography>
      {persons && (
        <Box margin={2}>
          <TextField
            id="standard-basic"
            value={searchName}
            label={localized('SearchUsers')}
            onChange={(event) => changeSearchVal(event.target.value)}
          />
        </Box>
      )}
      {persons ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {persons ? (
              <DataGrid
                page={savedPage}
                className={classesGrid.root}
                rows={persons}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageChange={handlePageChange}
                filterModel={{
                  items: [{ columnField: 'name', operatorValue: 'contains', value: searchName }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {alertDialogVisible === true && (
        <AlertDialog
          acceptRequest={handleReset}
          visible={alertDialogVisible}
          setVisible={(val) => setAlertDialogVisible(val)}
          title={localized('ResetPassword')}
          description={localized('AreYouSureResetPassword').concat(` ${resetPasswordName}`)}
        />
      )}
    </div>
  );
}

export default withRouter(GetAllPersons);
