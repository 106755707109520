import { TextField, Button, Snackbar, Grid, Typography, Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';

import { personService } from '../../../services/person.services';
import { authenticationService } from '../../../services/auth.services';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const classes = ApplicationUseStyles();
  const history = useHistory();

  function regexCheckForValidPassword(password: string) {
    const re = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
    if (re.test(password)) {
      return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    document.title = 'Cenvation - Change Password';
  }, []);

  const handleSubmit = async (event: any) => {
    await event.persist();
    if (newPassword === confirmPassword) {
      if (regexCheckForValidPassword(newPassword)) {
        const email = authenticationService.getUser();
        if (email !== false) {
          const result = await personService.changePassword(email, newPassword, oldPassword);
          if (result === true) {
            history.push('/');
          } else {
            setSnackBarMessage(localized('OldPasswordWasWrong'));
            setSnackBarSeverity('error');
            setIsSnackBarOpen(true);
          }
        }
      } else {
        setSnackBarMessage(localized('NewPasswordDoesNotMatch'));
        setSnackBarSeverity('error');
        setIsSnackBarOpen(true);
      }
    } else {
      setSnackBarMessage(localized('NewAndConfirmDoNotMatch'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          {localized('ChangePassword')}{' '}
        </Typography>
        <div className={classes.paperPadding} style={{ width: '100%' }}>
          <Grid container>
            <div>
              <TextField
                id="OldPassword"
                name="oldPassword"
                label={localized('OldPassword')}
                type="password"
                defaultValue={oldPassword}
                className={classes.formInput}
                variant="outlined"
                onChange={(event: any) => {
                  event.persist();
                  setOldPassword(event.target.value);
                }}
              />

              <TextField
                id="NewPassWord"
                name="newPassword"
                label={localized('NewPassword')}
                type="password"
                defaultValue={newPassword}
                className={classes.formInput}
                variant="outlined"
                onChange={(event: any) => {
                  event.persist();
                  setNewPassword(event.target.value);
                }}
              />
              <TextField
                id="Confirm Password"
                name="confirmPassword"
                label={localized('ConfirmPassword')}
                type="password"
                defaultValue={confirmPassword}
                className={classes.formInput}
                variant="outlined"
                onChange={(event: any) => {
                  event.persist();
                  setConfirmPassword(event.target.value);
                }}
              />

              <Grid container justify="center">
                <Box display="grid">
                  <Typography variant="caption">{localized('Password1')}</Typography>

                  <Typography variant="caption">{localized('Password2')}</Typography>

                  <Typography variant="caption"> {localized('Password3')}</Typography>

                  <Typography variant="caption">{localized('Password4')}</Typography>

                  <Typography variant="caption"> {localized('Password5')}</Typography>
                </Box>
              </Grid>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('ChangePassword')}
                </Button>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={isSnackBarOpen}
                autoHideDuration={2500}
                onClose={handleCloseSnackbar}
              >
                <Alert severity={snackBarSeverity} variant="filled">
                  {snackBarMessage}
                </Alert>
              </Snackbar>
            </div>
          </Grid>
        </div>
      </div>
    </form>
  );
}

export default withRouter(ChangePassword);
