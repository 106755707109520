/* eslint-disable */
import { CircularProgress, Paper, TextField ,Typography, Snackbar, Box, TableContainer, Table, TableHead, TableRow, TableBody} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { localized } from '../../utilities/language/i18n';
import {timerecordService} from '../../services/timerecord.services';

function AdminPage() {
  const classes = ApplicationUseStyles();

  useEffect(() => {
    document.title = 'Cenvation - Administration';
  }, []);


  const handleGenerateCEcsv = async () => {     
    const csvContent = await timerecordService.getCSV();
    const csvData = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=UTF-8;' });
    var csvUrl = URL.createObjectURL(csvData);
      
    var hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'CE.csv';
    hiddenElement.click();
  }

  return (
    <Box alignItems="center" justifyContent="center">
      <Typography variant="h3" className="App_pageTitle">{localized('AdminFunctions')}</Typography>

      <div className={classes.paperPadding}>

        <div className={classes.wrapper2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleGenerateCEcsv}
          >
            {localized("GenerateCSV")}
          </Button>
        </div>

       
      </div>
    </Box>
  );
}

export default AdminPage;
