import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Snackbar, Typography, Grid, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Autocomplete } from '@material-ui/lab';

import { Project, Product } from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { customerService } from '../../../services/customer.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { configurationService } from '../../../services/configuration.service';

function EditProject() {
  const [id, setId] = React.useState(0);
  const [name, setProjectName] = React.useState('');
  const [orgPrice, setOrginalPrice] = React.useState(0);
  const [discount, setDiscountPrice] = React.useState<string | number>('');
  const [price, setActualPrice] = React.useState(0);
  const [budget, setBudget] = React.useState(0);
  const [timeRegNeedComment, setTimeRegNeedComment] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [fixedPrice, setFixedPrice] = React.useState(false);
  const [sla, setCheckedSLA] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(0);
  const [customerName, setCustomerName] = React.useState('');
  const [slaVal, setSlaVal] = React.useState<number>(1.5);

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [products, setProducts] = React.useState<Product[] | null>();
  const [selectedProduct, setProduct] = React.useState<Product | undefined>();

  const classes = ApplicationUseStyles();

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    editProject: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const editProjectId = JSON.parse(params.editProject);
      const productList = await configurationService.getAllProducts();
      setProducts(productList);
      if (editProjectId) {
        const editProject = await projectService.getProjectById(parseInt(editProjectId, 10));
        if (editProject.id) setId(editProject.id);
        if (editProject.name) setProjectName(editProject.name);
        if (editProject.orgPrice) {
          if (editProject.sla) {
            setCheckedSLA(editProject.sla);
            setOrginalPrice(editProject.orgPrice * slaVal);
          } else {
            setOrginalPrice(editProject.orgPrice);
          }
        }
        if (editProject.discount) setDiscountPrice(editProject.discount);
        if (editProject.price) setActualPrice(editProject.price);
        if (editProject.budget) setBudget(editProject.budget);
        if (editProject.type) setProduct(productList.find(p => p.productId === editProject.type));
        if (editProject.timeRegNeedComment) setTimeRegNeedComment(editProject.timeRegNeedComment);
        if (editProject.active) setActive(editProject.active);
        if (editProject.fixedPrice) setFixedPrice(editProject.fixedPrice);
        if (editProject.customerId) {
          setCustomerId(editProject.customerId);
          const custumer = await customerService.getCustomerById(editProject.customerId);
          setCustomerName(custumer.name);
        }
      }
    }
    async function getSla() {
      var con_lst = await configurationService.getAll();
      var _sla = con_lst.filter((con) => con.label === 'SLA');
      if (_sla.length === 1) {
        setSlaVal(parseFloat(_sla[0].value.replace(',', '.')));
      }
    }
    getSla();
    dataFromProps();
  }, []);

  const handleChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleChangeOrginalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrginalPrice(parseInt(event.target.value, 10));
  };

  const handleChangeDiscountPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setDiscountPrice(event.target.value);
      setActualPrice(orgPrice);
    } else {
      const value = parseFloat(event.target.value);
      if (value >= 0 && value <= 100) {
        setDiscountPrice(parseFloat(event.target.value));
        if (value === 0) {
          setActualPrice(orgPrice);
        } else {
          const newActualPrice = (orgPrice * (1 - value / 100)).toFixed(2);
          setActualPrice(parseFloat(newActualPrice));
        }
      } else {
        setSnackBarMessage(localized('Discount0100'));
        setIsSnackBarOpen(true);
      }
    }
  };

  const handleChangeActualPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (value > orgPrice && sla === false) {
      setSnackBarMessage(localized('ActualPriceHigherThanOri'));
      setIsSnackBarOpen(true);
    } else if (value < 0) {
      setSnackBarMessage(localized('ActualPriceNegative'));
      setIsSnackBarOpen(true);
    } else if (value === 0 && selectedProduct?.name !== "Internal") {
      setSnackBarMessage(localized('ActualPrice0'));
      setIsSnackBarOpen(true);
    } else {
      setActualPrice(value);
      if (orgPrice !== 0) {
        const newDiscount = (((orgPrice - value) / orgPrice) * 100).toFixed(2);
        setDiscountPrice(parseFloat(newDiscount));
      }
    }
  };

  const handleChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudget(parseInt(event.target.value, 10));
  };

  const handleChangeProduct = (event: any, value: Product | null) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    var product = value ? value : undefined;
    setProduct(product);
    // Remove SLA if new product type can not be SLA
    if (!product?.canBeSLA && sla) { 
      setCheckedSLA(false);
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeRegNeedComment(event.target.checked);
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleChangeFixed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedPrice(event.target.checked);
  };

  const handleChangeSLA = (event: React.ChangeEvent<HTMLInputElement>) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    // If the Checkbox is not disabled, update sla based on the user's action.
    setCheckedSLA(event.target.checked);
    if (event.target.checked) {
      setOrginalPrice(orgPrice * slaVal);
      setActualPrice(orgPrice * slaVal * discountNumber);
    } else {
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('NameIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(orgPrice) || (orgPrice === 0 && selectedProduct?.name !== "Internal")) {
      setSnackBarMessage(localized('CustomerPriceIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(price) || (price === 0 && selectedProduct?.name !== "Internal")) {
      setSnackBarMessage(localized('PriceIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }

    if (!selectedProduct) {
      setSnackBarMessage(localized('TypeIsRequired'));
      setIsSnackBarOpen(true);
      return;
    }

    let discountNumber = 0;
    if (discount === '') {
      discountNumber = 0;
    } else {
      discountNumber = parseFloat(discount.toString());
    }

    await projectService.editProject(
      new Project({
        id,
        name,
        type: selectedProduct!.productId!,
        orgPrice,
        discount: discountNumber,
        price,
        customerId,
        budget,
        timeRegNeedComment,
        active,
        fixedPrice,
        sla
      })
    );
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdateProject')}
      </Typography>
      <div className={classes.root}>
        {id && products && selectedProduct ? (
          <div className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    required
                    id="EditProjectName"
                    name="name"
                    label={localized('ProjectName')}
                    variant="outlined"
                    value={name}
                    onChange={handleChangeProjectName}
                  />

                  <TextField
                    disabled
                    id="CustomerName"
                    name="customerName"
                    label={localized('CustomerName')}
                    variant="outlined"
                    value={customerName}
                  />
                </Grid>
                <Grid container>
                  <Autocomplete
                    id="ProjectType"
                    options={
                      customerName !== 'Intern' 
                        ? products?.filter(p => p.name !== 'Internal')
                        : products
                    }
                    getOptionLabel={(option: Product) => option.name}
                    renderInput={(inputParams) => (
                      <TextField required {...inputParams} label={localized('SelectProjectType')} variant="outlined" />
                    )}
                    onChange={handleChangeProduct}
                    value={selectedProduct}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    disabled
                    id="origPrice-required"
                    label={localized('CustomerPrice')}
                    name="orgPrice"
                    type="number"
                    variant="outlined"
                    value={orgPrice}
                    onChange={handleChangeOrginalPrice}
                  />
                  <TextField
                    required
                    id="discount-number"
                    name="discount"
                    label={localized('DiscountPercent')}
                    type="number"
                    variant="outlined"
                    value={discount}
                    onChange={handleChangeDiscountPrice}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    required
                    id="actualPrice-required"
                    name="price"
                    label={localized('ProjectPrice')}
                    type="number"
                    variant="outlined"
                    value={price}
                    onChange={handleChangeActualPrice}
                  />
                  <TextField
                    id="budget-number"
                    name="Budget"
                    label={localized('Budget')}
                    type="number"
                    variant="outlined"
                    value={budget}
                    onChange={handleChangeBudget}
                  />
                </Grid>
                <FormGroup>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('RegistrationsMust')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={timeRegNeedComment}
                              onChange={handleChangeComment}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('Active')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('FixedPrice')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={fixedPrice} onChange={handleChangeFixed} inputProps={{ 'aria-label': 'primary checkbox' }} />{' '}
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('SLA-Project')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={selectedProduct?.canBeSLA ? sla : false}
                              onChange={handleChangeSLA}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={!selectedProduct?.canBeSLA}
                            />{' '}
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                <Grid container justify="space-evenly">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBackClick}
                  >
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Update')}
                  </Button>
                </Grid>

                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={isSnackBarOpen}
                  autoHideDuration={2500}
                  onClose={handleCloseSnackbar}
                >
                  <Alert severity="warning" variant="filled">
                    {snackBarMessage}
                  </Alert>
                </Snackbar>
              </div>
            </Grid>
          </div>
        ) : (
          <CircularProgress />
        )}
      </div>
    </form>
  );
}

export default EditProject;
