import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TableRow, TableContainer, Table, TableHead, TableBody, Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import { configurationService } from '../../../services/configuration.service';
import { Product } from '../../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

function Products() {
  const [products, setProducts] = React.useState<Product[] | null>();

  async function loadProducts() {
    const configurationList = await configurationService.getAllProducts();
    setProducts(configurationList);
  }

  React.useEffect(() => {
    document.title = 'Cenvation - Products';
  }, []);

  React.useEffect(() => {
    loadProducts();
  }, [setProducts]);

  const classes = TableStyles();

  return (
    <Box>
      <Typography variant="h4" className="App_pageTitle">
        {localized('Products')}
      </Typography>
      {products ? (
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table className={classes.table} aria-label="configuration-table-all">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">{localized('Product name')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Type')}</StyledTableCell>
                <StyledTableCell align="right">{localized('Update')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row: Product) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell align="right">{row.name}</StyledTableCell>
                  <StyledTableCell align="right">{row.productId}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Link
                      className={classes.removeUnderline}
                      to={{
                        pathname: '/edit-product/'.concat(row.id ? row.id.toString() : '')
                      }}
                    >
                      <Button variant="contained" color="primary" size={buttonSize}>
                        {localized('Update')}
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
      <Box padding={2}>
        <Link to={{ pathname: '/create-product' }} className={classes.removeUnderline}>
          <Button variant="contained" color="primary" size={buttonSize}>
            {localized('CreateNewProduct')}
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default withRouter(Products);
