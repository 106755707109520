import { Client, Booking } from './http-services/api';

async function create(newBooking: Booking) {
  const client = new Client();

  const result = await client.booking(newBooking);
  return result;
}

async function update(updatedBooking: Booking) {
  const client = new Client();

  const result = await client.booking3(updatedBooking);

  return result;
}

async function deleteBooking(updatedBooking: Booking) {
  const client = new Client();

  const result = await client.booking2(updatedBooking);
  return result;
}

async function listCreate(bookingList: Booking[]) {
  const client = new Client();

  const result = await client.listCreate(bookingList);
  return result;
}

async function deleteGroupedBookings(groupId: number) {
  const client = new Client();

  const result = await client.allGruopedBookings(groupId);
  return result;
}

async function getAll() {
  const client = new Client();

  const result = await client.bookingAll();

  return result;
}

async function getBookingFromDate(fromDate: Date) {
  const client = new Client();

  const result = await client.getFromDate(fromDate);

  return result;
}

async function getBookingDateAndPersonID(fromDate: Date, personId: number) {
  const client = new Client();

  const result = await client.getFromDateAndPersonId(fromDate, personId);

  return result;
}

async function getLatestID() {
  const client = new Client();

  const result = await client.getLastestId();
  return result;
}

export const bookingService = {
  create,
  getAll,
  update,
  deleteBooking,
  getLatestID,
  listCreate,
  deleteGroupedBookings,
  getBookingFromDate,
  getBookingDateAndPersonID
};
