import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
  Tooltip
} from '@material-ui/core';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';

import { customerService } from '../../services/customer.services';
import { billService } from '../../services/bill.service';
import { CreateBillDTO, Customer } from '../../services/http-services/api';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { Voucher } from '../../services/http-services/api';
import { voucherService } from '../../services/voucher.service';
import { projectService } from '../../services/project.service';
import { TableStyles, StyledTableCell, StyledTableRow } from '../../styles/use-styles';

function VoucherOverview() {
  const [customers, setCustomer] = React.useState<Customer[] | null>();
  const [vouchers, setVouchers] = React.useState<Voucher[] | null>();
  const [month, setMont] = React.useState<Date>(new Date);
  const [rend, setRend] = React.useState(true);

  const classes = ApplicationUseStyles();

  React.useEffect(() => {
    document.title = 'Cenvation - Voucher';
  }, []);


  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('VoucherOverview')}{' '}
      </Typography>
      {rend ? (
        <Paper elevation={3} >
        <Grid container direction="row" justify="center" alignItems="center">
          <TableContainer key={1} component={Paper} style={{ boxShadow: 'none' }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>

                </TableRow>
                <TableRow>
                  <StyledTableCell>{localized('Date')}</StyledTableCell>
                  <StyledTableCell>{localized('Hours')}</StyledTableCell>
                  <StyledTableCell>{localized('Time Records')}</StyledTableCell>
                  <StyledTableCell>{localized('Create')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row">
            
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <span className="timerecord-overview__row">{localized('Hours')}:</span>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    <span className="timerecord-overview__row">{localized('Hours')}:</span>
                    </StyledTableCell>
                
                    <StyledTableCell component="th" scope="row">

                    </StyledTableCell>
                  </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default withRouter(VoucherOverview);
