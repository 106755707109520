import { Table, TableBody, TableContainer, TableHead, CircularProgress, TableRow, Box, Grid, Typography, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Alert, Autocomplete, Color } from '@material-ui/lab';

import { economicServices } from '../../services/economic.services';
import { teamService } from '../../services/team.services';
import { FinancialOverviewDTO, Team } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles, ApplicationUseStyles } from '../../styles/use-styles';
import { localized } from '../../utilities/language/i18n';

function FinancialOverview() {
  const [financialOverview, setFinancialOverview] = useState<FinancialOverviewDTO[] | null>();
  const [teams, setTeams] = useState<Team[] | null>();
  const [team, setTeam] = useState(-1);

  React.useEffect(() => {
    document.title = 'Cenvation - Financial';
  }, []);

  async function loadTeams() {
    const allTeams: Team[] = await teamService.getAll();
    const all: Team = new Team({id: -1, name: "All"});
    allTeams.push(all);
    setTeams(allTeams) 
  }

  async function loadFinancialOverview() {
    const financialOverviewList = await economicServices.getFinancialOverview();
    setFinancialOverview(financialOverviewList);
  }

  async function loadFinancialOverviewByTeam(team: number) {
    const financialOverviewList = await economicServices.getFinancialOverviewByTeam(team);
    setFinancialOverview(financialOverviewList);
  }

  React.useEffect(() => {
    loadTeams();
    loadFinancialOverviewByTeam(team);
  }, [setFinancialOverview]);

  function totalEarnings(data: FinancialOverviewDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.total === undefined) {
        return;
      }
      total += item.total;
    });
    return total;
  }

  function totalHours(data: FinancialOverviewDTO[]) {
    let total = 0;
    data.forEach((item) => {
      if (item.hours === undefined) {
        return;
      }
      total += item.hours;
    });
    return total;
  }

  function isWeekday(year: number, month: number, day: number) {
    var Day = new Date(year, month, day).getDay();
    return Day != 0 && Day != 6;
  }

  function weekDaysUpToNow() {
    var today = new Date();
    var counter = 0;
    for (var i = 1; i <= today.getDate(); i++) {
      if (isWeekday(today.getFullYear(), today.getMonth(), i)) {
        counter += 1;
      }
    }
    return counter;
  }

  //function getWeekdaysInMonth(month, year) {
  //  var days = daysInMonth(month, year);
  //  var weekdays = 0;
  //  for(var i=0; i< days; i++) {
  //      if (isWeekday(year, month, i+1)) weekdays++;
  //  }
  //  return weekdays;
  //}

  function calcBusinessDays() {
    // This makes no effort to account for holidays
    // Counts end day, does not count start day
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // make copies we can normalize without changing passed in objects
    const startDate = new Date(firstDay);
    const endDate = new Date(lastDay);

    // initial total
    let totalBusinessDays = 0;

    // normalize both start and end to beginning of the day
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const current = new Date(startDate);
    //current.setDate(current.getDate() + 1);
    let day;
    // loop through each day, checking
    // eslint-disable-next-line no-unmodified-loop-condition
    while (current <= endDate) {
      day = current.getDay();
      if (day >= 1 && day <= 5) {
        ++totalBusinessDays;
      }
      current.setDate(current.getDate() + 1);
    }
    return totalBusinessDays;
  }

  const handleChangeTeam = (event: any, value: Team | null) => {
    const newTeam = value!.id ? value!.id : 0;
    setFinancialOverview(undefined);
    loadFinancialOverviewByTeam(newTeam);
    setTeam(newTeam);
  };

  const classes = TableStyles();
  const classes1 = ApplicationUseStyles();

  return (
    <Box>
      {teams ? 
      <Typography variant="h4" className="App_pageTitle">
        {localized('FinancialOverview') + ": " + teams.find(t => t.id === team)!.name}
      </Typography> :
      <Typography variant="h4" className="App_pageTitle">
      {localized('FinancialOverview') }
      </Typography>
      }
      <form noValidate autoComplete="off">
      <div className={classes1.paperPadding}>
        <Grid>
          <Autocomplete
            id="TeamChange"
            options={teams!}
            getOptionLabel={(option: Team) => option.name}
            renderInput={(params) => <TextField required {...params} label={localized('SelectTeam')} variant="outlined" />}
            onChange={handleChangeTeam}
            defaultValue={teams?.find(t => t.id === team)}
          />
        </Grid>
      </div>
       </form>
      {financialOverview && team ? (
        <>
          <TableContainer component={Paper} style={{ boxShadow: 'none', borderBottom: '2px solid #1e9bd3', borderRadius: 0 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Id</StyledTableCell>
                  <StyledTableCell align="right">{localized('Customer')}</StyledTableCell>
                  <StyledTableCell align="right">{localized('ProjectName')}</StyledTableCell>
                  <StyledTableCell align="right">{localized('NumberOfHours')}</StyledTableCell>
                  <StyledTableCell align="right">{localized('Price')}</StyledTableCell>
                  <StyledTableCell align="right">{localized('Total')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {financialOverview.map((row: FinancialOverviewDTO) => (
                  <StyledTableRow key={row.projectId}>
                    <StyledTableCell align="right">{row.projectId}</StyledTableCell>
                    <StyledTableCell align="right"> {row.customerName}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Link to={{ pathname: '/hours-project/'.concat(row.projectId ? row.projectId.toString() : '') }}>
                        {row.projectName}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.hours ? row.hours.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.hours}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.price ? row.price.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.price} kr.
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.total ? row.total.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.total} kr.
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell align="right">
                    <b>{localized('Total')}</b>
                  </StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right">
                    <b>
                      {totalEarnings(financialOverview) === 0
                        ? 0
                        : Math.floor(totalEarnings(financialOverview)).toLocaleString('da-DK', { maximumFractionDigits: 2 })}{' '}
                      kr.
                    </b>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left" style={{ background: '#1e9bd3', color: 'white' }}>
                      {localized('Description')}
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ background: '#1e9bd3', color: 'white' }}>
                      {localized('Value')}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('NumberWorkingDaysThisMonth')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid xs={7}>
                        <Box textAlign="right">{calcBusinessDays()}</Box>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('DaysPassed')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid xs={7}>
                          <Box textAlign="right">{weekDaysUpToNow()}</Box>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('EarningPerDay')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid xs={7}>
                          <Box textAlign="right">
                            {totalEarnings(financialOverview) === 0
                              ? 0
                              : Math.floor(
                                  totalEarnings(financialOverview) / (weekDaysUpToNow() === 0 ? 1 : weekDaysUpToNow())
                                ).toLocaleString('da-DK', { maximumFractionDigits: 2 })}{' '}
                            kr.
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('ExpectedEarningMonthMax')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid xs={7}>
                          <Box textAlign="right">
                            {totalEarnings(financialOverview) === 0
                              ? 0
                              : Math.floor(
                                  (totalEarnings(financialOverview) / (weekDaysUpToNow() === 0 ? 1 : weekDaysUpToNow() - 1)) *
                                    calcBusinessDays()
                                ).toLocaleString('da-DK', { maximumFractionDigits: 2 })}{' '}
                            kr.
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('ExpectedEarningMonthMin')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid xs={7}>
                          <Box textAlign="right">
                            {Math.floor(
                              (totalEarnings(financialOverview) / (weekDaysUpToNow() === 0 ? 1 : weekDaysUpToNow())) * calcBusinessDays()
                            ).toLocaleString('da-DK', { maximumFractionDigits: 2 })}{' '}
                            kr.
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('NumberOfHours')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid xs={7}>
                        <Box textAlign="right">{totalHours(financialOverview).toFixed(2)}</Box>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left">{localized('AveragePricePerHour')}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid xs={7}>
                          <Box textAlign="right">
                            {totalEarnings(financialOverview) === 0 || totalHours(financialOverview) === 0
                              ? 0
                              : Math.floor(totalEarnings(financialOverview) / totalHours(financialOverview)).toLocaleString()}{' '}
                            kr.
                          </Box>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default withRouter(FinancialOverview);
