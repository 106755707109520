import { ListItem, ListItemIcon, ListItemText, Collapse, createStyles, makeStyles, Theme, List } from '@material-ui/core';
import React from 'react';
import '../navbar-item/navbar-item.scss';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import NavBarItem, { NavBarItemProps } from '../navbar-item/navbar-item';

interface NavBarDropDownProps {
  rootText: string;
  rootIcon: JSX.Element;
  children: NavBarItemProps[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2)
    }
  })
);

function NavBarDropDown(props: NavBarDropDownProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemIcon style={{ minWidth: '40px', color: '#222' }}>{props.rootIcon}</ListItemIcon>
        <ListItemText primary={props.rootText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.nested}>
        <List component="div" disablePadding>
          {props.children.map((child) => {
            return (
              <NavBarItem
                linkTo={child.linkTo}
                text={child.text}
                key={child.linkTo}
                selected={child.selected}
                onMenuItemClicked={child.onMenuItemClicked}
              />
            );
          })}
        </List>
      </Collapse>
    </div>
  );
}

export default NavBarDropDown;
