import { List, makeStyles, createStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import TimelineIcon from '@material-ui/icons/Timeline';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import NoteIcon from '@material-ui/icons/Note';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ReceiptIcon from '@material-ui/icons/Receipt';

import NavBarDropDown from '../navbar-dropdown/navbar-dropdown';
import NavBarItem from '../navbar-item/navbar-item';
import { authenticationService } from '../../services/auth.services';
import { useAuth } from '../../utilities/use-auth';
import { localized } from '../../utilities/language/i18n';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutBtn: {
      background: 'black',
      color: 'white',
      border: 'none',
      display: 'block',
      width: '100%',
      marginTop: 32,
      minHeight: 32,
      cursor: 'pointer'
    },
    navList: {
      paddingLeft: 12,
      paddingRight: 12
    }
  })
);
const DesktopNavBar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [item, setItem] = React.useState(window.location.pathname);
  const [role, setRole] = React.useState('');
  const [user, setUserId] = React.useState('');
  const [isloggedIn, setIsLoggedIn] = React.useState(false);

  const auth = useAuth();

  const signOut = () => {
    authenticationService.logout();
    auth.signOut();
    history.push('/');
  };

  const ResetPagination = () => {
    localStorage.page = '0';
  };

  useEffect(() => {
    async function LoadUser() {
      const userId = await authenticationService.getUserId();
      if (userId) setUserId(userId.toString());
    }
    LoadUser();

    const userRole = authenticationService.getUserRole();
    setRole(userRole);

    const condition = auth.loginState;
    setIsLoggedIn(condition);
  }, [setUserId]);

  const setTimerecordOverview = () => {
    setItem('/timerecord-overview');
    ResetPagination();
  };

  const setGetTimeRecords = () => {
    setItem(`/timerecords/currentuser`);
    ResetPagination();
  };

  const setGetPersons = () => {
    setItem('/get-persons');
    ResetPagination();
  };

  const setChangePassword = () => {
    setItem('/change-password');
    ResetPagination();
  };

  const setCreatePerson = () => {
    setItem('/create-person');
    ResetPagination();
  };

  const setGetProject = () => {
    setItem('/get-projects');
    ResetPagination();
  };

  const setCreateProject = () => {
    setItem('/create-project');
    ResetPagination();
  };

  const HandleUserAdmin = () => {
    setItem('/user-page');
    ResetPagination();
  };

  const setGetCustomer = () => {
    setItem('/customer');
    ResetPagination();
  };

  const setCreateCustomer = () => {
    setItem('/customerspecific');
    ResetPagination();
  };

  const setEditSettings = () => {
    setItem('/settings');
    ResetPagination();
  };

  const setGetConfigurations = () => {
    setItem('/get-configurations');
    ResetPagination();
  };

  const setProducts = () => {
    setItem('/products');
    ResetPagination();
  };

  const setFinancialOverview = () => {
    setItem('/financial-overview');
    ResetPagination();
  };

  const setUtilizationOverview = () => {
    setItem('/utilization-overview');
    ResetPagination();
  };

  const setLogin = () => {
    setItem('/login');
    ResetPagination();
  };

  const setAdminPage = () => {
    setItem('/admin');
    ResetPagination();
  };

  const setBillingPage = () => {
    setItem('/billing');
    ResetPagination();
  };

  const setGetVouchers = () => {
    setItem('/get-vouchers');
    ResetPagination();
  };

  const setCreateVoucher = () => {
    setItem('/create-voucher');
    ResetPagination();
  };

  const setVoucherOverview = () => {
    setItem('/voucher-overview');
    ResetPagination();
  };

  const setHolidayRegistration = () => {
    setItem('/holiday-registration');
    ResetPagination();
  };

  const setBooking = () => {
    setItem('/booking');
    ResetPagination();
  };

  const setGetProjectSubscriptions = () => {
    setItem('/get-projectsubscriptions');
    ResetPagination();
  };

  const setCreateTimerecordOverview = () => {
    setItem('/create-timerecord-overview');
    ResetPagination();
  };

  const setPriceChange = () => {
    setItem('/price-change');
    ResetPagination();
  };

  const setGetBills = () => {
    setItem('/get-bills');
    ResetPagination();
  };

  const personDropDownShow = () => {
    if (role === 'Admin') {
      return (
        <NavBarDropDown
          rootText={localized('Persons')}
          rootIcon={<PersonIcon />}
          children={[
            {
              linkTo: '/create-person',
              text: localized('CreatePerson'),
              selected: item === '/create-person',
              onMenuItemClicked: setCreatePerson
            },
            {
              linkTo: '/change-password',
              text: localized('ChangePassword'),
              selected: item === '/change-password',
              onMenuItemClicked: setChangePassword
            },
            {
              linkTo: '/get-persons',
              text: localized('ViewAllPersons'),
              selected: item === '/get-persons',
              onMenuItemClicked: setGetPersons
            },
            {
              linkTo: '/settings',
              text: localized('Settings'),
              selected: item === '/settings',
              onMenuItemClicked: setEditSettings
            }
          ]}
        />
      );
    } else {
      return (
        <NavBarDropDown
          rootText={localized('Persons')}
          rootIcon={<PersonIcon />}
          children={[
            {
              linkTo: '/change-password',
              text: localized('ChangePassword'),
              selected: item === '/change-password',
              onMenuItemClicked: setChangePassword
            },
            {
              linkTo: '/get-persons',
              text: localized('ViewAllPersons'),
              selected: item === '/get-persons',
              onMenuItemClicked: setGetPersons
            },
            {
              linkTo: '/settings',
              text: 'Settings',
              selected: item === '/settings',
              onMenuItemClicked: setEditSettings
            }
          ]}
        />
      );
    }
  };

  const isLoggedIn = () => {
    if (isloggedIn === false)
      return (
        <NavBarItem
          linkTo="/login"
          text={localized('Login')}
          icon={<LockIcon />}
          selected={item === '/login'}
          onMenuItemClicked={setLogin}
        />
      );
    else return null;
  };

  const isUserAdmin = () => {
    if (role === 'Admin') {
      return (
        <div>
          <NavBarItem
            linkTo="/holiday-registration"
            text={localized('HolidayRegs')}
            icon={<FlightTakeoffIcon />}
            selected={item === '/holiday-registration'}
            onMenuItemClicked={setHolidayRegistration}
          />

          <NavBarDropDown
            rootText={localized('Configuration')}
            rootIcon={<SettingsIcon />}
            children={[
              {
                linkTo: '/get-configurations',
                text: localized('ViewAllConfigs'),
                selected: item === '/get-configurations',
                onMenuItemClicked: setGetConfigurations
              },
              {
                linkTo: '/products',
                text: localized('Products'),
                selected: item === '/products',
                onMenuItemClicked: setProducts
              }
            ]}
          />
          <NavBarDropDown
            rootText={localized('Financial')}
            rootIcon={<LocalAtmIcon />}
            children={[
              {
                linkTo: '/financial-overview',
                text: localized('FinancialOverview'),
                selected: item === '/financial-overview',
                onMenuItemClicked: setFinancialOverview
              },
              {
                linkTo: '/utilization-overview',
                text: localized('UtilizationOverview'),
                selected: item === '/utilization-overview',
                onMenuItemClicked: setUtilizationOverview
              },
              {
                linkTo: '/timerecord-overview',
                text: localized('TimerecordOverview'),
                selected: item === '/timerecord-overview',
                onMenuItemClicked: setTimerecordOverview
              },
              {
                linkTo: '/billing',
                text: localized('BillingPage'),
                selected: item === '/billing',
                onMenuItemClicked: setBillingPage
              },
              {
                linkTo: '/price-change',
                text: localized('AnnualChange'),
                selected: item === '/price-change',
                onMenuItemClicked: setPriceChange
              },
              {
                linkTo: '/get-bills',
                text: localized('Bills'),
                icon: <ReceiptIcon />,
                selected: item === '/get-bills',
                onMenuItemClicked: setGetBills
              }
            ]}
          />
          <NavBarDropDown
            rootText={localized('Vouchers')}
            rootIcon={<NoteIcon />}
            children={[
              {
                linkTo: '/get-vouchers',
                text: localized('ViewAllVouchers'),
                selected: item === '/get-vouchers',
                onMenuItemClicked: setGetVouchers
              },
              {
                linkTo: '/create-voucher',
                text: localized('CreateVoucher'),
                selected: item === '/create-voucher',
                onMenuItemClicked: setCreateVoucher
              }
            ]}
          />

          <NavBarItem
            linkTo="/admin"
            text={localized('Admin Page')}
            icon={<PeopleAltIcon />}
            selected={item === '/admin'}
            onMenuItemClicked={setAdminPage}
          />
        </div>
      );
    } else return null;
  };

  return (
    <div className="DesktopNavBar" data-testid="DesktopNavBar">
      <div style={{ padding: 22 }}>
        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src="/Cenvation-logo-1.png" />
        <p style={{ textAlign: 'left', color: '#222', marginTop: '-4px' }}>{localized('TitelMobile')}</p>
      </div>
      <List className={classes.navList}>
        <NavBarItem
          linkTo="/create-timerecord-overview"
          text={localized('Home')}
          icon={<HomeIcon />}
          selected={false}
          onMenuItemClicked={setCreateTimerecordOverview}
        />
        {isLoggedIn()}
        <NavBarDropDown
          rootText={localized('Time Records')}
          rootIcon={<TimelineIcon />}
          children={[
            {
              linkTo: '/create-timerecord-overview',
              text: localized('RegistrationOfHours'),
              selected: item === '/create-timerecord-overview',
              onMenuItemClicked: setCreateTimerecordOverview
            },
            {
              linkTo: `/timerecords/` + user,
              text: localized('My Time Reg'),
              selected: item === `/timerecords/` + user,
              onMenuItemClicked: setGetTimeRecords
            },
            {
              linkTo: '/get-projectsubscriptions',
              text: localized('ProjectSubscriptions'),
              selected: item === '/get-projectsubscriptions',
              onMenuItemClicked: setGetProjectSubscriptions
            }
          ]}
        />
        <NavBarDropDown
          rootText={localized('Projects')}
          rootIcon={<AssessmentIcon />}
          children={[
            {
              linkTo: '/get-projects',
              text: localized('ViewAllProjects'),
              selected: item === '/get-projects',
              onMenuItemClicked: setGetProject
            },
            {
              linkTo: '/create-project',
              text: localized('CreateNewProject'),
              selected: item === '/create-project',
              onMenuItemClicked: setCreateProject
            },
            {
              linkTo: '/administration',
              text: localized('Administration'),
              selected: item === '/user-admin',
              onMenuItemClicked: HandleUserAdmin
            }
          ]}
        />
        <NavBarDropDown
          rootText={localized('Customer')}
          rootIcon={<EmojiPeopleIcon />}
          children={[
            {
              linkTo: '/customer',
              text: localized('ViewAllCustomers'),
              selected: item === '/customer',
              onMenuItemClicked: setGetCustomer
            },
            {
              linkTo: '/customerspecific',
              text: localized('CreateNewCustomer'),
              selected: item === '/customerspecific',
              onMenuItemClicked: setCreateCustomer
            }
          ]}
        />
        {isUserAdmin()}
        {personDropDownShow()}
        {isloggedIn && (
          <button className={classes.logoutBtn} onClick={signOut}>
            {localized('Logout')}
          </button>
        )}
      </List>
      <footer>
        <p>&copy; 2023 Cenvation</p>
        <p>
          <a href="https://www.cenvation.com/">Visit cenvation.com</a>
        </p>
      </footer>
    </div>
  );
};

export default DesktopNavBar;
