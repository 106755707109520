import { List, makeStyles, createStyles, Theme } from '@material-ui/core';
import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import LockIcon from '@material-ui/icons/Lock';
import TimelineIcon from '@material-ui/icons/Timeline';

import NavBarDropDown from '../navbar-dropdown/navbar-dropdown';
import NavBarItem from '../navbar-item/navbar-item';
import { useAuth } from '../../utilities/use-auth';
import { localized } from '../../utilities/language/i18n';
import { authenticationService } from '../../services/auth.services';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutBtn: {
      display: 'block',
      width: '100%',
      marginTop: 32,
      minHeight: 32,
      cursor: 'pointer',
      background: 'white'
    }
  })
);

const MobileNavBar: React.FC = () => {
  const classes = useStyles();
  const setInitialPath = () => {
    return window.location.pathname;
  };
  const [item, setItem] = React.useState(setInitialPath());
  const [isloggedIn, setIsLoggedIn] = React.useState(false);

  const auth = useAuth();
  const history = useHistory();

  const signOut = () => {
    authenticationService.logout();
    auth.signOut();
    history.push('/');
  };

  React.useEffect(() => {
    const condition = auth.loginState;
    setIsLoggedIn(condition);
  }, []);

  const setHome = () => {
    setItem('/');
  };

  const setLogin = () => {
    setItem('/login');
  };

  const setCreateTimerecordOverview = () => {
    setItem('/create-timerecord-overview');
  };

  const setGetTimeRecords = () => {
    setItem(`/timerecords/currentuser`);
  };

  const setGetProjectSubscriptions = () => {
    setItem('/get-projectsubscriptions');
  };

  const isLoggedIn = () => {
    if (isloggedIn === false)
      return (
        <NavBarItem
          linkTo="/login"
          text={localized('Login')}
          icon={<LockIcon />}
          selected={item === '/login'}
          onMenuItemClicked={setLogin}
        />
      );
    else return null;
  };

  return (
    <div className="LoginNavBar" data-testid="LoginNavBar">
      <div style={{ padding: 22 }}>
        <img style={{ maxHeight: '100%', maxWidth: '100%' }} src="/Cenvation-logo-1.png" />
        <p style={{ textAlign: 'left', color: '#222', marginTop: '-4px' }}>{localized('TitelMobile')}</p>
      </div>
      <List>
        <NavBarItem linkTo="/" text={localized('Home')} icon={<HomeIcon />} selected={item === '/'} onMenuItemClicked={setHome} />
        {isLoggedIn()}
        <NavBarDropDown
          rootText="Timerecords"
          rootIcon={<TimelineIcon />}
          children={[
            {
              linkTo: '/create-timerecord-overview',
              text: localized('RegistrationOfHours'),
              selected: item === '/create-timerecord-overview',
              onMenuItemClicked: setCreateTimerecordOverview
            },
            {
              linkTo: `/timerecords/currentuser`,
              text: localized('My Time Reg'),
              selected: item === `/timerecords/currentuser`,
              onMenuItemClicked: setGetTimeRecords
            },
            {
              linkTo: '/get-projectsubscriptions',
              text: localized('ProjectSubscriptions'),
              selected: item === '/get-projectsubscriptions',
              onMenuItemClicked: setGetProjectSubscriptions
            }
          ]}
        />
      </List>
      {isloggedIn && (
        <div style={{ padding: 12 }}>
          <button className={classes.logoutBtn} onClick={signOut}>
            {localized('Logout')}
          </button>
        </div>
      )}
      <footer>
        <p>&copy; 2023 Cenvation</p>
        <p>
          <a href="https://www.cenvation.com/">Visit cenvation.com</a>
        </p>
      </footer>
    </div>
  );
};

export default MobileNavBar;
