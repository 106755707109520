/* eslint-disable no-unused-vars */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid
} from '@material-ui/core';
import { withRouter, useParams, Link, useHistory } from 'react-router-dom';
import React from 'react';
import format from 'date-fns/format';

import { Person, TimerecordsPerUserDTO, Customer } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';
import { TableStyles, StyledTableCell } from '../../../styles/use-styles';
import { personService } from '../../../services/person.services';
import { localized } from '../../../utilities/language/i18n';
import { customerService } from '../../../services/customer.services';

interface TimerecordsForUserProps {
  personId: string;
}

class TimerecordTableRow {
  date: Date = new Date();
  timerecords: TimerecordsPerUserDTO[] | null = null;
}

class TimerecordTable {
  rows: TimerecordTableRow[] | null = null;
}

function TimerecordsForUser() {
  const [timerecordsPerUserDTOs, setTimerecordsPerUserDTOs] = React.useState<TimerecordsPerUserDTO[] | null>();
  const [person, setPerson] = React.useState<Person>();
  const [allCustomers, setAllCustomers] = React.useState<Customer[] | null>();
  const [tableVals, setTableVals] = React.useState<TimerecordTable | null>();
  const params = useParams<TimerecordsForUserProps>();
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(25);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(0);

  function MakeContainers() {
    if (timerecordsPerUserDTOs) {
      const table = new TimerecordTable();
      var dateList = Array.from(timerecordsPerUserDTOs.map((elm) => elm.date));
      dateList = dateList.filter((date, i, self) => self.findIndex((d) => d!.getTime() === date!.getTime()) === i);
      const Rows: TimerecordTableRow[] = [];
      table.rows = Rows;
      dateList.forEach((element) => {
        var tempRow = new TimerecordTableRow();
        tempRow.date = element!;
        tempRow.timerecords = timerecordsPerUserDTOs.filter(
          (elm) =>
            elm!.date!.getDate() === element!.getDate() &&
            elm!.date!.getFullYear() === element!.getFullYear() &&
            elm!.date!.getMonth() === element!.getMonth()
        );
        table!.rows!.push(tempRow);
      });
      const pages = Math.ceil(table.rows.length / itemsPerPage);
      setTableVals(table);
      setTotalRows(table.rows.length)
      setTotalPages(pages);
    }
  }

  async function loadData() {
    const dtoList = await timerecordService.getAllTimerecordsForUser(parseInt(params.personId, 10));
    setTimerecordsPerUserDTOs(dtoList);
    const personFromParams = await personService.getPersonById(parseInt(params.personId, 10));
    setPerson(personFromParams);
    const customerList = await customerService.getAll();
    setAllCustomers(customerList);
  }

  React.useEffect(() => {
    loadData();
  }, []);

  const classes = TableStyles();

  React.useEffect(() => {
    MakeContainers();
  }, [timerecordsPerUserDTOs]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  }

  const handleItemsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newItemsPerPage = parseInt((event.target as HTMLInputElement).value, 10);
    setItemsPerPage(newItemsPerPage);
    const newPages = Math.ceil(totalRows / newItemsPerPage);
    setTotalPages(newPages);
  }

  return (
    <div>
      {tableVals && tableVals.rows && allCustomers && person ? (
        <div>
        <Typography className="App_pageTitle" variant="h4">
        {localized('Time Records') + ' : ' + person.name}
        </Typography>
        <TableContainer component={Paper}>
          <Grid item xs={8}>
          <InputLabel id="items-per-page-label" style={{marginLeft: '8px', marginTop: '8px'}} >{localized('Items per page')}</InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select 
              labelId="items-per-page-label"
              id="items-per-page"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{localized('Date')}</StyledTableCell>
                <StyledTableCell>{localized('Hours')}</StyledTableCell>
                <StyledTableCell>{localized('Time Records')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableVals!.rows!
              .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
              .map((row) => (
                <TableRow>
                  <StyledTableCell component="th" scope="row">
                    {row.date && format(row.date, "EEEE'\r\n'dd-MM-yyyy")}
                  </StyledTableCell>
                  <StyledTableCell>{row.timerecords?.reduce((n, { hours }) => n + hours!, 0)}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <span className="timerecord-overview__row">{localized('Time Records')}:</span>
                    {row.timerecords &&
                      row.timerecords.length > 0 &&
                      row.timerecords.map((tr, index1) => (
                        <Tooltip
                          title={
                            tr.ceRef
                              ? 'CeRef:' + tr.ceRef + ', ' + localized('Comment') + ': ' + tr.comment
                              : tr.apRef
                              ? 'APRef:' + tr.apRef + ', ' + localized('Comment') + ': ' + tr.comment
                              : tr.comment
                              ? localized('Comment') + ': ' + tr.comment
                              : ''
                          }
                        >
                          <Box key={index1}>
                            {tr.status ? (
                              <Link
                                to={{
                                  pathname: '/edit-timerecord/'.concat(tr.id ? tr.id.toString() : '')
                                }}
                              >
                                {tr.project &&
                                  allCustomers!
                                    .filter((cust) => cust.id === tr!.project!.customerId!)[0]
                                    .name.concat(` - ${tr.project.name}(${tr.hours})`)}
                              </Link>
                            ) : (
                              `${
                                tr.project &&
                                allCustomers!
                                  .filter((cust) => cust.id === tr!.project!.customerId!)[0]
                                  .name.concat(` - ${tr.project.name}(${tr.hours})`)
                              }`
                            )}
                          </Box>
                        </Tooltip>
                      ))}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ textAlign: 'center', marginTop: '16px', marginBottom: '16px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 0}
            >
              Previous
            </Button>
            <span style={{ margin: '0 8px', fontSize: '16px'}}>
              {`${localized('Page')} ${page + 1} ${localized('OutOf')} ${totalPages}`}
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages - 1}
            >
              Next
            </Button>
          </div>
        </TableContainer>
        </div>
        ) : (
          <CircularProgress />
        )}
  </div>
  );
}

export default withRouter(TimerecordsForUser);
