import { Button, Checkbox, CircularProgress, FormControlLabel, Typography, Grid, Paper, Snackbar, TextField } from '@material-ui/core';
import { Alert, Autocomplete, Color } from '@material-ui/lab';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';

import { customerService } from '../../services/customer.services';
import { billService } from '../../services/bill.service';
import { CreateBillDTO, Customer } from '../../services/http-services/api';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

function BillsCreate() {
  const [customers, setCustomer] = React.useState<Customer[] | null>();
  const [billNumber, setBillNumber] = React.useState<string | number>('');
  const [selectedCustomer, setselectedCustomer] = React.useState<Customer>(new Customer());
  const [paid, setPaid] = React.useState(false);

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const history = useHistory();
  const classes = ApplicationUseStyles();

  React.useEffect(() => {
    async function loadProjects() {
      const customerList = await customerService.getAll();
      setCustomer(customerList);
    }
    loadProjects();
  }, []);

  const handleBillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setBillNumber(event.target.value);
    } else {
      const value = parseInt(event.target.value, 10);
      if (value > 0) {
        setBillNumber(value);
      } else {
        setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      }
    }
  };

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value === null) {
      return;
    }
    setselectedCustomer(value);
  };

  const handleChangePaid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaid(event.target.checked);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (selectedCustomer.id === undefined) {
      setSnackBarMessage(localized('CustomerIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    let billNumberInt = 0;
    if (billNumber === '') {
      setSnackBarMessage(localized('Billnumberisrequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    } else {
      billNumberInt = parseInt(billNumber.toString(), 10);
    }

    await billService.createBill(new CreateBillDTO({ customerId: selectedCustomer.id, number: billNumberInt, paid }));
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('CreateBill')}{' '}
      </Typography>
      {customers ? (
        <div className={classes.root}>
          <Paper className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    required
                    id="billSelect"
                    name="bill"
                    label={localized('BillNumber')}
                    type="number"
                    variant="outlined"
                    onChange={handleBillChange}
                    value={billNumber}
                  />
                  <Autocomplete
                    id="customerChange"
                    options={customers}
                    getOptionLabel={(option: Customer) => option.name}
                    renderInput={(params) => <TextField required {...params} label={localized('SelectCustomer')} variant="outlined" />}
                    onChange={handleCustomerChange}
                  />
                </Grid>
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Create')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    label={localized('Paid')}
                    labelPlacement="start"
                    style={{ display: 'table' }}
                    control={
                      <div style={{ display: 'table-cell' }}>
                        <Checkbox checked={paid} onChange={handleChangePaid} inputProps={{ 'aria-label': 'primary checkbox' }} />
                      </div>
                    }
                  />
                </Grid>

                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={isSnackBarOpen}
                  autoHideDuration={2500}
                  onClose={handleCloseSnackbar}
                >
                  <Alert severity={snackBarSeverity} variant="filled">
                    {snackBarMessage}
                  </Alert>
                </Snackbar>
              </div>
            </Grid>
          </Paper>
        </div>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default withRouter(BillsCreate);
