import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/da';
import {
  FormControlLabel,
  Switch,
  Snackbar,
  Grid,
  makeStyles,
  Collapse,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';

import { IBookingEvent } from '../booking-module/booking-ui';
import { Booking, Customer, Person, Project } from '../../../services/http-services/api';
import AlertDialog from '../../alert-dialog/alert-dialog';
import { SchedulerEvent } from '../booking-module/scheduler-bindings';
import { customerService } from '../../../services/customer.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

import RepeatForm from './repeat-dialog';
import HandleConflictingDialog from './conflicts-dialog';

interface formProps {
  visible: boolean;
  setVisible: (open: boolean, handleConflict: boolean, existingConflicts: SchedulerEvent[], id?: string) => void;
  inputBooking: IBookingEvent;
  conflictMustBeHandled?: boolean;
  setBookingDetails: (bookingEvent: Booking) => void;
  deleteBooking: (bookingEvent: Booking) => void;
  deleteGroupBookings: (gruopId: number) => void;
  goToConflictingBooking: (bookingToUpdate: Booking, conflictBookings: SchedulerEvent[]) => void;
  persons: Person[];
  projects: Project[];
  possibleConflicts: SchedulerEvent[];
  existingConflicts: SchedulerEvent[];
}

const useStyles = makeStyles({
  topMargin: {
    marginTop: 10
  }
});

function BookingFormDialog(props: formProps) {
  // modalVisibility
  const { visible, setVisible, inputBooking, setBookingDetails, persons, projects } = props;
  const [open, setOpen] = React.useState(visible);

  // inputBooking Properties
  const [selectedStartDate, setStartDateChange] = React.useState(inputBooking.start);
  const [selectedEndDate, setEndDateChange] = React.useState(inputBooking.end);
  const [title, setTitle] = React.useState(inputBooking.title);
  //  const [bookingType, setBookingType] = React.useState(checkUndefinedStringToNumber(inputBooking.type));
  const [projectId, setRegardingProject] = React.useState(inputBooking.projectId === undefined ? 0 : inputBooking.projectId);
  const [selectedProject, setSelectedProject] = React.useState<Project>(new Project());

  // Recurring state
  const [recurring, setRecurring] = React.useState(false);
  const [rruleString, setRRuleString] = React.useState('');
  const [getRRule, setGetRRule] = React.useState(false);

  // Customer
  const [activeCustomers, setActiveCustomers] = React.useState<Customer[]>();
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>(new Customer());

  // Booking to Save in Database
  const [savedBookingDetails, setSavedBookingDetails] = React.useState<Booking>();

  // Alert Dialog State
  const [alertDialogVisible, setAlertDialogVisible] = React.useState(false);
  const [alertDialogVisibleGroupedEvents, setGroupedEventDialogVisible] = React.useState(false);

  // Conflict Dialog State
  const [openConflictModal, setOpenConflictModal] = React.useState(false);
  const [conflictingBooking, setConflictingBooking] = React.useState<SchedulerEvent[]>();

  // Snackbar State
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const classes = useStyles();

  // converts stateData to Booking - checks with Snackbar
  const convertStateDataToBooking = (): Booking | undefined => {
    const bookingDetails = new Booking();

    if (title === undefined || title === '') {
      setSnackBarMessage(localized('TitleIsRequired'));
      setIsSnackBarOpen(true);
      return undefined;
    }

    if (moment(selectedStartDate).isSameOrAfter(selectedEndDate)) {
      setSnackBarMessage(localized('StartOfBookingMust'));
      setIsSnackBarOpen(true);
      return undefined;
    }

    if (projectId === 0) {
      setSnackBarMessage(localized('ProjectIsRequired'));
      setIsSnackBarOpen(true);
      return undefined;
    }

    bookingDetails.title = title;
    bookingDetails.startDate = selectedStartDate;
    if (recurring) {
      bookingDetails.endDate = selectedStartDate;
    } else {
      bookingDetails.endDate = selectedEndDate;
    }

    bookingDetails.type = selectedProject.type;
    if (projectId !== undefined) {
      bookingDetails.projectId = projectId;
    }
    bookingDetails.personId = parseInt(inputBooking.resourceId, 10);
    if (inputBooking.existingEvent === true && inputBooking.eventId !== undefined) {
      bookingDetails.id = parseInt(inputBooking.eventId, 10);
    }
    bookingDetails.groupId = inputBooking.groupId;
    return bookingDetails;
  };

  const handleClose = () => {
    let conflictBooking: boolean = false;

    setOpen(false);
    if (props.conflictMustBeHandled === undefined || props.conflictMustBeHandled === false) conflictBooking = false;
    else {
      conflictBooking = true;
    }

    if (conflictBooking) setVisible(false, conflictBooking, props.existingConflicts, inputBooking.eventId);
    else {
      setVisible(false, conflictBooking, props.existingConflicts);
    }
  };

  const handleSubmit = () => {
    const bookingDetails = convertStateDataToBooking();
    if (bookingDetails !== undefined) {
      if (recurring === true) {
        setGetRRule(true);
        setSavedBookingDetails(bookingDetails);
      } else {
        setBookingDetails(bookingDetails);
        handleClose();
      }
    }
  };

  React.useEffect(() => {
    if (savedBookingDetails === undefined) {
      return;
    }
    const bookingDetails = savedBookingDetails;
    bookingDetails.rRule = rruleString;
    setBookingDetails(bookingDetails);
    handleClose();
  }, [rruleString]);

  React.useEffect(() => {
    async function getCustomers() {
      const customers = await customerService.getActiveCustomerOrderedByName();
      setActiveCustomers(customers);
    }
    getCustomers();
  }, []);

  React.useEffect(() => {
    if (inputBooking.projectId !== undefined && projects !== undefined) {
      const projFromProps = projects.find((proj) => proj.id === inputBooking.projectId);
      if (projFromProps) {
        setSelectedProject(projFromProps);
        if (projFromProps !== undefined) {
          const customerFromProps = activeCustomers?.find((customer) => customer.id === projFromProps.customerId);
          if (customerFromProps) setSelectedCustomer(customerFromProps);
        }
      }
    }
  }, [activeCustomers]);

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  // checks newly created Booking for Conflicts
  const conflictCheck = () => {
    let complete = true;
    if (title === undefined || title === '') {
      setSnackBarMessage(localized('TitleIsRequired'));
      setIsSnackBarOpen(true);
      complete = false;
    }

    if (moment(selectedStartDate).isSameOrAfter(selectedEndDate)) {
      setSnackBarMessage(localized('StartOfBookingMust'));
      setIsSnackBarOpen(true);
      complete = false;
    }

    if (projectId === undefined) {
      setSnackBarMessage(localized('ProjectIsRequired'));
      setIsSnackBarOpen(true);
      complete = false;
    }

    if (complete === true) {
      let hasConflict = false;
      const conflictingBookingEvent: SchedulerEvent[] = [];

      props.possibleConflicts.forEach((existingEvent: SchedulerEvent) => {
        if (
          (existingEvent.resourceId === inputBooking.resourceId && existingEvent.id !== inputBooking.eventId) ||
          (existingEvent.resourceId === inputBooking.resourceId &&
            existingEvent.title !== inputBooking.title &&
            moment(existingEvent.start).toDate() !== inputBooking.start &&
            moment(existingEvent.end).toDate() !== inputBooking.end &&
            existingEvent.description !== inputBooking.description)
        ) {
          const eStart = moment(existingEvent.start).toDate();
          const eEnd = moment(existingEvent.end).toDate();
          if (
            (selectedStartDate >= eStart && selectedStartDate < eEnd) ||
            (selectedEndDate > eStart && selectedEndDate <= eEnd) ||
            (eStart >= selectedStartDate && eStart < selectedEndDate) ||
            (eEnd > selectedStartDate && eEnd <= selectedEndDate)
          ) {
            hasConflict = true;
            conflictingBookingEvent.push(existingEvent);
          }
        }
      });

      if (hasConflict) {
        setConflictingBooking(conflictingBookingEvent);
        setOpenConflictModal(true);
      } else handleSubmit();
    }
  };

  const handleGotoConflictingBooking = (GoToConflictBookings: SchedulerEvent[]) => {
    const bookingDetails = convertStateDataToBooking();
    if (bookingDetails !== undefined) {
      if (recurring === true) {
        setGetRRule(true);
        setSavedBookingDetails(bookingDetails);
        props.goToConflictingBooking(bookingDetails, GoToConflictBookings);
      } else {
        props.goToConflictingBooking(bookingDetails, GoToConflictBookings);
        handleClose();
      }
    }
  };

  const handleStringRRule = (rrule: string) => {
    setRRuleString(rrule);
  };

  const handleAcceptDelete = () => {
    const bookingDetails = convertStateDataToBooking();
    if (bookingDetails !== undefined) {
      props.deleteBooking(bookingDetails);
      handleClose();
    }
  };

  const handleIgnore = () => {
    handleSubmit();
  };

  const handleGroupBookingAcceptDelete = () => {
    props.deleteGroupBookings(inputBooking.groupId);
    handleClose();
  };

  const handleAlertDialogVisible = () => {
    setAlertDialogVisible(true);
  };

  const handleGroupedEvents = () => {
    setGroupedEventDialogVisible(true);
  };

  const handleSwitchRecurring = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecurring(event.target.checked);
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date !== null) setEndDateChange(date);
  };

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      setStartDateChange(date);
    }
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setSelectedCustomer(value);
      setSelectedProject(new Project());
      setRegardingProject(0);
      setTitle(value.name);
    }
  };

  const handleProjectChange = (event: any, value: Project | null) => {
    if (value !== null && value.id !== undefined) {
      setSelectedProject(value);
      setRegardingProject(value.id);
      setTitle(selectedCustomer.name.concat(' - ').concat(value.name));
    }
  };

  function getPersonName(): string {
    const person = persons.find((pson) => pson.id === parseInt(inputBooking.resourceId, 10));
    if (person !== undefined && person.name !== undefined) return person.name;
    return '';
  }

  const ConflictToHandle = () => {
    if (props.conflictMustBeHandled === undefined || props.conflictMustBeHandled === false) {
      return <DialogTitle id="form-dialog-title">{localized('PersonBooking')}</DialogTitle>;
    } else {
      return <DialogTitle id="form-dialog-title">{localized('ConflictBooking')} </DialogTitle>;
    }
  };

  function isBookingExistingInDatabase() {
    if (inputBooking.existingEvent === true && inputBooking.eventId !== undefined) {
      return (
        <Button variant="contained" size={buttonSize} onClick={handleAlertDialogVisible} color="secondary">
          {localized('Delete')}
        </Button>
      );
    } else return null;
  }

  function isBookingGrouped() {
    if (inputBooking.existingEvent === true && inputBooking.eventId !== undefined && inputBooking.groupId !== 0) {
      return (
        <Button variant="contained" size={buttonSize} onClick={handleGroupedEvents} color="secondary">
          {localized('DeleteGroupedEvents')}
        </Button>
      );
    } else return null;
  }

  const customerSelect = () => {
    if (activeCustomers) {
      return (
        <Autocomplete
          id="ProjectType"
          options={activeCustomers}
          getOptionLabel={(option: Customer) => option.name || ''}
          renderInput={(inputParams) => <TextField required {...inputParams} label={localized('SelectCustomer')} variant="outlined" />}
          onChange={handleCustomerChange}
          value={selectedCustomer}
        />
      );
    } else return null;
  };

  const projectSelect = () => {
    if (selectedCustomer) {
      const relevantProjects = projects.filter((proj) => proj.customerId === selectedCustomer.id);
      return (
        <Autocomplete
          id="ProjectSelect"
          options={relevantProjects}
          getOptionLabel={(option: Project) => option.name || ''}
          renderInput={(inputParams) => <TextField required {...inputParams} label={localized('SelectProject')} variant="outlined" />}
          onChange={handleProjectChange}
          value={selectedProject}
        />
      );
    } else return null;
  };

  const IsTypeHoliday = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {customerSelect()}
        </Grid>
        <Grid item xs={12} className={classes.topMargin}>
          {projectSelect()}
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm">
        <Grid container item justify="center">
          <Grid>{ConflictToHandle()}</Grid>
        </Grid>
        <DialogContent>
          <Grid container>
            <Typography variant="h6">
              {localized('EnterTheDetailsBooking')}
              {getPersonName()}
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="Title"
                label={localized('Title')}
                type="text"
                variant="outlined"
                fullWidth
                value={title}
                onChange={handleChangeTitle}
              />
            </Grid>
          </Grid>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <Grid container className={classes.topMargin} justify="space-evenly">
              <Grid item xs={6}>
                <DatePicker
                  required
                  autoOk
                  value={selectedStartDate}
                  onChange={(date) => handleDateChange(date)}
                  label={localized('Begins')}
                  showTodayButton
                  TextFieldComponent={(inputParams) => <TextField required {...inputParams} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={6}>
                <Collapse in={!recurring}>
                  <DatePicker
                    required
                    autoOk
                    value={selectedEndDate}
                    onChange={(date) => handleEndDateChange(date)}
                    label={localized('Ends')}
                    showTodayButton
                    TextFieldComponent={(inputParams) => <TextField required {...inputParams} variant="outlined" />}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid container>
            <Grid item xs={6} className={classes.topMargin}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          checked={recurring}
                          onChange={handleSwitchRecurring}
                          color="primary"
                          name="checkedB"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label={localized('Repeat')}
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.topMargin}>
              <Grid container className={classes.topMargin}>
                {IsTypeHoliday()}
              </Grid>
            </Grid>
          </Grid>

          <Collapse in={recurring}>
            <RepeatForm setRruleString={handleStringRRule} returnRRule={getRRule} startDate={selectedEndDate} />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-evenly">
            {isBookingExistingInDatabase()}
            {isBookingGrouped()}

            <Button variant="contained" onClick={handleClose} color="primary" size={buttonSize}>
              {localized('Cancel')}
            </Button>
            <Button variant="contained" onClick={conflictCheck} color="primary" size={buttonSize}>
              {localized('Submit')}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      {alertDialogVisible === true && (
        <AlertDialog
          acceptRequest={handleAcceptDelete}
          visible={alertDialogVisible}
          setVisible={(val) => setAlertDialogVisible(val)}
          title={localized('DeleteBooking')}
          description={localized('WantToDeleteBooking')}
        />
      )}

      {alertDialogVisibleGroupedEvents === true && (
        <AlertDialog
          acceptRequest={handleGroupBookingAcceptDelete}
          visible={alertDialogVisibleGroupedEvents}
          setVisible={(val) => setAlertDialogVisible(val)}
          title={localized('DeleteAllBookings')}
          description={localized('DeleteAllBookingsSubText')}
        />
      )}

      {openConflictModal === true && (
        <HandleConflictingDialog
          IgnoreConflict={handleIgnore}
          GoToConflictingBooking={(conflictBooking) => handleGotoConflictingBooking(conflictBooking)}
          visible={openConflictModal}
          setVisible={(visibility) => setOpenConflictModal(visibility)}
          conflictingBookings={conflictingBooking!}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error" variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BookingFormDialog;
