import { 
  Button, 
  TextField, 
  Snackbar, 
  Grid, 
  Typography,
  FormControlLabel,
  Checkbox
 } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';

import { configurationService } from '../../../services/configuration.service';
import { Product } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

const CreateProduct: React.FC = () => {
  const [name, setProductName] = React.useState('');
  const [productId, setProductId] = React.useState(0);

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  const [canBeSLA, setProductSLA] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<Product[] | null>();

  const history = useHistory();
  const classes = ApplicationUseStyles();

  const handleChangeProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductName(event.target.value);
  };

  const handleProductId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductId(parseInt(event.target.value));
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleChangeSLA = () => {
    setProductSLA(!canBeSLA);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('LabelIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (productId === 0) {
      setSnackBarMessage(localized('ValueIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    await configurationService.createProduct(new Product({ name, productId, canBeSLA }));
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          {localized('Create new product')}
        </Typography>
        <div>
          <Grid container>
            <div>
              <TextField
                required
                id="configurationLabel"
                name="label"
                label={localized('Product name')}
                variant="outlined"
                onChange={handleChangeProductName}
              />
              <TextField
                required
                id="configurationValue"
                name="productId"
                label={localized('Type')}
                type="number"
                variant="outlined"
                onChange={handleProductId}
              />
              <Grid container>
                <FormControlLabel
                    label={localized('canBeSLA')}
                    labelPlacement="start"
                    style={{ display: 'table' }}
                    control={
                      <div style={{ display: 'table-cell' }}>
                        <Checkbox checked={canBeSLA} onChange={handleChangeSLA} inputProps={{ 'aria-label': 'primary checkbox' }} />
                      </div>
                    }
                  />
              </Grid>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('Create product')}
                </Button>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default CreateProduct;