import { Route, Switch } from 'react-router-dom';
import React from 'react';

import './App.css';
import Login from './pages/login/login';
import ProtectedRoute from './middelwares/protected-route';
import NavBar from './components/topbar/topbar';
import CreateProject from './pages/project/project-create/project-create';
import CreatePerson from './pages/person/person-create/person-create';
import ChangePassword from './pages/person/person-change-password/person-change-password';
import GetAllPersons from './pages/person/person-get-all/person-get-all';
import CustomerList from './pages/customer/customer-get-all/customer-get-all';
import CustomerSpecific from './pages/customer/customer-create/customer-create';
import GetAllProjects from './pages/project/project-get-all/project-get-all';
import CreateTimerecord from './pages/timerecord/timerecord-create/timerecord-create';
import CustomerCopy from './pages/customer/customer-copy/customer-copy';
import EditProject from './pages/project/project-edit/project-edit';
import CopyProject from './pages/project/project-copy/project-copy';
import GetAllConfigurations from './pages/configuration/configuration-get-all/configuration-get-all';
import Procucts from './pages/products/products/products';
import CreateProduct from './pages/products/create-product/create-product';
import UpdateProduct from './pages/products/edit-product/edit-product';
import CreateConfiguration from './pages/configuration/configuration-create/configuration-create';
import UpdateConfiguration from './pages/configuration/configuration-edit/configuration-edit';
import TimerecordOverview from './pages/timerecord/timerecord-overview/timerecord-overview';
import BookingPage from './pages/booking-registration/booking-page';
import FinancialOverview from './pages/financial-overview-get/financial-overview';
import GetHoursForProject from './pages/project/project-get-hours/project-get-hours';
import ProjectManagement from './pages/project/project-management/project-management';
import UtilizationOverview from './pages/utilization-overview/utilization-overview';
import UtilizationByMonth from './pages/utilization-by-month/utilization-by-month';
import AdminPage from './pages/admin-page/admin-page';
import CreateVoucher from './pages/voucher/voucher-create';
import VoucherGetAll from './pages/voucher/voucher-get-all';
import VoucherEdit from './pages/voucher/voucher-edit';
import VoucherOverview from './pages/voucher/voucher-overview';
import TimerecordsForUser from './pages/timerecord/timerecord-for-user/timerecord-for-user';
import TimerecordEdit from './pages/timerecord/timerecord-edit/timerecord-edit';
import HolidayRegistration from './pages/admin-page/holiday-registration/holiday-registration';
import UserAdminTask from './pages/admin-page/user-page';
import BillingOverview from './pages/billing/billing-overview';
import BillingSpecific from './pages/billing/billing-specific-new';
import BillingOnePerProject from './pages/billing/billing-one-per-project';
import ProtectedAdminRoute from './middelwares/protected-admin-route';
import PriceChangeCustomer from './pages/admin-page/annual-price-change/annual-price-change';
import CustomerEdit from './pages/customer/customer-edit/customer-edit';
import EditPerson from './pages/person/person-edit/person-edit';
import OverviewCreateTimerecord from './pages/timerecord/timerecord-overview/overview-create-timerecord';
import ProjectSubscriptionsForUser from './pages/project/project-subscription/project-subscriptions-for-user';
import BillingPerCustomer from './pages/billing/billing-per-customer';
import BillSpecification from './pages/billing/billing-specification';
import GetCustomerProjects from './pages/project/project-per-customer/project-per-customer';
import BillsGetAll from './pages/bills/bills-get-all';
import BillsCreate from './pages/bills/bills-create';
import { Paper } from '@material-ui/core';
import TimerecordForProject from './pages/timerecord/timerecord-for-project/timerecord-for-project';
import EditSettings from './pages/settings/settings-page';
import TimerecordWeekOverviewTable from './pages/timerecord/timerecord-by-week/timerecord-by-week';
import HolidayExcel from './pages/admin-page/holiday-excel/holidays-by-date';
import TimerecordForBill from './pages/timerecord/timerecord-for-bill/timerecord-for-bill';

function App() {
  return (
    <div className="App">
      <main id="App-header-overall" className="App-header">
        <NavBar />
        <Paper className="App-content">
          <Switch>
            <ProtectedAdminRoute exact={true} path="/admin" component={AdminPage} location={true} />
            <ProtectedRoute exact={true} path="/create-project" component={CreateProject} location={true} />
            <ProtectedRoute exact={true} path="/edit-project/:editProject" component={EditProject} location={true} />
            <ProtectedRoute exact={true} path="/copy-project/:copyProject" component={CopyProject} location={true} />
            <ProtectedRoute exact={true} path="/hours-project/:projectId" component={GetHoursForProject} location={true} />
            <ProtectedRoute exact={true} path="/management-project/:customerId" component={ProjectManagement} location={true} />
            <ProtectedRoute exact={true} path="/get-projects" component={GetAllProjects} location={true} />
            <ProtectedRoute exact={true} path="/get-projectsubscriptions" component={ProjectSubscriptionsForUser} location={true} />
            <ProtectedRoute exact={true} path="/customer-projects/:customerProjects" component={GetCustomerProjects} location={true} />

            <ProtectedRoute exact={true} path="/booking" component={BookingPage} location={true} />

            <ProtectedAdminRoute exact={true} path="/get-bills" component={BillsGetAll} location={true} />
            <ProtectedAdminRoute exact={true} path="/create-bill" component={BillsCreate} location={true} />

            <ProtectedAdminRoute exact={true} path="/get-configurations" component={GetAllConfigurations} location={true} />
            <ProtectedAdminRoute exact={true} path="/edit-configuration/:editConfiguration" component={UpdateConfiguration} location={true} />
            <ProtectedAdminRoute exact={true} path="/create-configuration" component={CreateConfiguration} location={true} />
            <ProtectedAdminRoute exact={true} path="/products" component={Procucts} location={true} />
            <ProtectedAdminRoute exact={true} path="/edit-product/:editProduct" component={UpdateProduct} location={true} />
            <ProtectedAdminRoute exact={true} path="/create-product" component={CreateProduct} location={true} />

            <ProtectedAdminRoute exact={true} path="/create-person" component={CreatePerson} location={true} />
            <ProtectedRoute exact={true} path="/change-password" component={ChangePassword} location={true} />
            <ProtectedRoute exact={true} path="/get-persons" component={GetAllPersons} location={true} />
            <ProtectedAdminRoute exact={true} path="/edit-person/:personId" component={EditPerson} location={true} />

            <ProtectedRoute exact={true} path="/customer" component={CustomerList} location={true} />
            <ProtectedRoute exact={true} path="/customerSpecific" component={CustomerSpecific} location={true} />
            <ProtectedRoute exact={true} path="/customerCopy/:copyCustomer" component={CustomerCopy} location={true} />
            <ProtectedRoute exact={true} path="/customerEdit/:editCustomer" component={CustomerEdit} location={true} />

            <ProtectedRoute exact={true} path="/create-timerecord/:dateString" component={CreateTimerecord} location={true} />
            <ProtectedRoute exact={true} path="/administration" component={UserAdminTask} location={true} />
            <ProtectedRoute exact={true} path="/create-timerecord" component={CreateTimerecord} location={true} />
            <ProtectedAdminRoute exact={true} path="/timerecord-overview" component={TimerecordOverview} location={true} />
            <ProtectedRoute exact={true} path="/timerecords/:personId" component={TimerecordsForUser} location={true} />
            <ProtectedRoute exact={true} path="/timerecords-by-bill/:billId" component={TimerecordForBill} location={true} />
            <ProtectedRoute exact={true} path="/edit-timerecord/:timerecordId" component={TimerecordEdit} location={true} />
            <ProtectedRoute exact={true} path="/create-timerecord-overview/" component={OverviewCreateTimerecord} location={true} />
            <ProtectedRoute exact={true} path="/timerecords-for-project/:projectId" component={TimerecordForProject} location={true} />
            <ProtectedRoute exact={true} path="/settings" component={EditSettings} location={true} />
            <ProtectedAdminRoute exact={true} path="/financial-overview" component={FinancialOverview} location={true} />
            <ProtectedAdminRoute exact={true} path="/utilization-overview" component={UtilizationOverview} location={true} />
            <ProtectedAdminRoute exact={true} path="/utilization/:dateString" component={UtilizationByMonth} location={true} />
            <ProtectedAdminRoute exact={true} path="/holiday-registration" component={HolidayRegistration} location={true} />
            <ProtectedAdminRoute exact={true} path="/price-change" component={PriceChangeCustomer} location={true} />
            <ProtectedAdminRoute exact={true} path="/create-voucher" component={CreateVoucher} location={true} />
            <ProtectedAdminRoute exact={true} path="/get-vouchers" component={VoucherGetAll} location={true} />
            <ProtectedAdminRoute exact={true} path="/edit-voucher/:voucherId" component={VoucherEdit} location={true} />
            <ProtectedAdminRoute exact={true} path="/voucher-overview" component={VoucherOverview} location={true} />
            <ProtectedAdminRoute exact={true} path="/billing" component={BillingOverview} location={true} />
            <ProtectedAdminRoute exact={true} path="/billing/:customerId" component={BillingSpecific} location={true} />
            <ProtectedAdminRoute exact={true} path="/billing/one-per-project/:customerId" component={BillingOnePerProject} location={true} />
            <ProtectedAdminRoute exact={true} path="/customer-bills/:customerBills" component={BillingPerCustomer} location={true} />
            <ProtectedAdminRoute exact={true} path="/bill-specification/:billSpecification" component={BillSpecification} location={true} />
            <ProtectedAdminRoute exact={true} path="/timerecord-week/:personId" component={TimerecordWeekOverviewTable} location={true} />
            <ProtectedAdminRoute exact={true} path="/administration/get-holidays" component={HolidayExcel} location={true} />
            <Route path="/" component={Login} />
          </Switch>
        </Paper>
      </main>
    </div>
  );
}

export default App;
