import { 
  Button, 
  CircularProgress, 
  Typography,
  Grid, 
  TextField, 
  FormControlLabel,
  Checkbox, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { configurationService } from '../../../services/configuration.service';
import { Product } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { CheckBox } from '@material-ui/icons';

function UpdateProduct() {
  const [id, setId] = React.useState(0);
  const [name, setProductName] = React.useState('');
  const [productId, setProductType] = React.useState(0);
  const [canBeSLA, setProductSLA] = React.useState<boolean>(false);

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    editProduct: string;
  }

  const classes = ApplicationUseStyles();
  React.useEffect(() => {
    async function dataFromProps() {
      const editProductId = JSON.parse(params.editProduct);
      if (editProductId) {
        const editProduct = await configurationService.getProductById(parseInt(editProductId, 10));
        console.log(editProduct);
        if (editProduct.id) setId(editProduct.id);
        if (editProduct.name) setProductName(editProduct.name);
        if (editProduct.productId) setProductType(editProduct.productId);
        if (editProduct.canBeSLA) setProductSLA(editProduct.canBeSLA);
      }
    }
    dataFromProps();
  }, []);

  const handleChangeProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductName(event.target.value);
  };

  const handleProductId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductType(parseInt(event.target.value));
  };

  const handleDeleteClick = async () => {
    await configurationService.deleteProduct(new Product({ id, name, productId, canBeSLA }));
    history.goBack();
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleChangeSLA = () => {
    setProductSLA(!canBeSLA);
  };

  const handleSubmit = async (event: any) => {
    event.persist();
    await configurationService.editProduct(new Product({ id, name, productId, canBeSLA }));
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('UpdateProduct')}
      </Typography>
      {id ? (
        <div className={classes.root}>
          <div className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    required
                    id="outlined-label"
                    name="name"
                    label={localized('Product name')}
                    variant="outlined"
                    value={name}
                    onChange={handleChangeProductName}
                  />
                  <TextField
                    required
                    id="outlined-value"
                    name="value"
                    label={localized('Type')}
                    variant="outlined"
                    type = "number"
                    value={productId}
                    onChange={handleProductId}
                  />
                </Grid>
                <Grid container>
                  <FormControlLabel
                      label={localized('canBeSLA')}
                      labelPlacement="start"
                      style={{ display: 'table' }}
                      control={
                        <div style={{ display: 'table-cell' }}>
                          <Checkbox checked={canBeSLA} onChange={handleChangeSLA} inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </div>
                      }
                    />
                </Grid>
                <Grid container justify="space-evenly">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBackClick}
                  >
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('Update')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleDeleteClick}
                  >
                    {localized('Delete')}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </form>
  );
}

export default UpdateProduct;