import { Client, Customer } from './http-services/api';

async function getAll() {
  const client = new Client();

  const result = await client.customerAll();
  return result;
}

async function create(customer: Customer) {
  const client = new Client();

  const result = await client.customer(customer);
  return result;
}

async function update(customer: Customer) {
  const client = new Client();

  const result = await client.customer2(customer);
  return result;
}

async function getCustomerOrderedByName() {
  const client = new Client();

  const result = await client.allCustomerOrdersByName();
  return result;
}

async function getActiveCustomerOrderedByName() {
  const client = new Client();

  const result = await client.activeCustumerByName();
  return result;
}

async function getCustomerBySecret(bySecret: string) {
  const client = new Client();

  const result = await client.bySecret(bySecret);
  return result;
}

async function getCustomerById(id: number) {
  const client = new Client();

  const result = await client.customer4(id);
  return result;
}

async function deleteCustomer(customer: Customer) {
  const client = new Client();
  try {
    const result = await client.customer3(customer);
    return result;
  } catch (error) {
    return false;
  }
}

async function updateStatusSent(custumerId: number) {
  const client = new Client();

  const result = await client.statusSent(custumerId);
  return result;
}

async function priceChange(customers: Customer[]) {
  const client = new Client();

  const result = await client.annualPriceChange(customers);
  return result;
}

export const customerService = {
  create,
  priceChange,
  getAll,
  updateStatusSent,
  getCustomerById,
  getCustomerBySecret,
  getActiveCustomerOrderedByName,
  getCustomerOrderedByName,
  update,
  deleteCustomer
};
