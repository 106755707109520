import { Button, TextField, Snackbar, Grid, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';

import { configurationService } from '../../../services/configuration.service';
import { Configuration } from '../../../services/http-services/api';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';

const CreateConfiguration: React.FC = () => {
  const [label, setConfigurationLabel] = React.useState('');
  const [value, setConfigurationValue] = React.useState('');

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const history = useHistory();
  const classes = ApplicationUseStyles();

  const handleChangeConfigurationLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationLabel(event.target.value);
  };

  const handleConfigurationValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigurationValue(event.target.value);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (label === '') {
      setSnackBarMessage(localized('LabelIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (value === '') {
      setSnackBarMessage(localized('ValueIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    await configurationService.createConfiguration(new Configuration({ label, value }));
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          Create Configuration
        </Typography>
        <div>
          <Grid container>
            <div>
              <TextField
                required
                id="configurationLabel"
                name="label"
                label={localized('Label')}
                variant="outlined"
                onChange={handleChangeConfigurationLabel}
              />
              <TextField
                required
                id="configurationValue"
                name="value"
                label={localized('Value')}
                variant="outlined"
                onChange={handleConfigurationValue}
              />

              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  size={buttonSize}
                  className="button"
                  startIcon={<SaveIcon />}
                  onClick={handleSubmit}
                >
                  {localized('CreateConfiguration')}
                </Button>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default CreateConfiguration;
