import { Dialog, Paper, Table, TableBody, TableHead, TableContainer, Typography, TableRow, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import React from 'react';

import { Booking, Project } from '../../services/http-services/api';
import { StyledTableRow, StyledTableCell } from '../../styles/use-styles';
import { localized } from '../../utilities/language/i18n';

const useStyles = makeStyles({
  table: {
    minWidth: 400
  },
  paper: {
    padding: 40
  },
  padding50: {
    padding: 50,
    paddingTop: 10
  },
  centerjust: {
    display: 'flex',
    justifyContent: 'center'
  }
});

interface formProps {
  visible: boolean;
  setVisible: (open: boolean) => void;
  bookingPossibilities: Booking[] | undefined;
  setChosenIndex: (index: Booking) => void;
  projects: Project[] | undefined;
}

function MultipleBookings(props: formProps) {
  const classes = useStyles();

  const { visible, setVisible, bookingPossibilities, setChosenIndex, projects } = props;

  const [open, setOpen] = React.useState(visible);
  const [bookings] = React.useState(bookingPossibilities);

  const handleClose = () => {
    setOpen(false);
    setVisible(false);
  };

  const selectBooking = (index: number) => {
    if (bookingPossibilities === undefined) {
      handleClose();
    }
    if (bookingPossibilities !== undefined) {
      const chosenBooking = bookingPossibilities[index];
      setChosenIndex(chosenBooking);
      handleClose();
    }
  };

  const setProjectNames = (projectId: number) => {
    if (projects === undefined) {
      return null;
    } else {
      return projects?.find((proj) => proj.id === projectId);
    }
  };

  const rowSuggestions = () => {
    if (bookings === undefined) {
      handleClose();
      return null;
    } else {
      return (
        <TableBody>
          {bookings.map((row: Booking, index: number) => (
            <StyledTableRow key={index} onClick={() => selectBooking(index)}>
              <StyledTableCell align="right">{setProjectNames(row.projectId)?.name}</StyledTableCell>
              <StyledTableCell align="right">{row.title}</StyledTableCell>
              <StyledTableCell align="right"> {format(row.startDate, "eee dd-MM-yy '\r\n' k:mm")}</StyledTableCell>
              <StyledTableCell align="right"> {format(row.endDate, "eee dd-MM-yy '\r\n' k:mm")}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} className={classes.centerjust}>
            <Typography variant="h3">{localized('MultipleSuggestions')}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.centerjust}>
            <Typography variant="h5">{localized('SelectASuggestion')}</Typography>
          </Grid>
          <div className={classes.padding50}>
            <TableContainer component={Paper}>
              <Table aria-label="possiblity table" className={classes.table} stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">{localized('Project')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Title')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('StartTime')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('EndTime')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                {rowSuggestions()}
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Dialog>
    </div>
  );
}

export default MultipleBookings;
