import detector from 'i18next-browser-languagedetector';
// Import languages so the moment locale is set correctly
import 'moment/locale/en-gb';
import 'moment/locale/da';
import moment from 'moment';
import i18n from 'i18next';

import danishTranslation from './da.json';
import englishTranslation from './en.json';

export const Languages = {
  da: 'da',
  en: 'en'
} as const;

export type LanguageType = keyof typeof Languages;

export type Translation = typeof englishTranslation;

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding MOMENT LOCALE at the top!
const i18nResources: any = {
  da: {
    translation: danishTranslation
  },
  en: {
    translation: englishTranslation
  }
};

export type i18nTranslationKey = any;

i18n.use(detector);
i18n.init(
  {
    fallbackLng: 'en',
    resources: i18nResources,
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default'
    }
  },
  (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key');
    const userLang = navigator.language;
    if (userLang === 'da') {
      i18n.changeLanguage('da');
    }
    moment.locale([i18n.language, userLang === 'da' ? 'da' : 'en']);
    return null;
  }
);

export const localized = (key: keyof Translation): string => {
  return i18n.t(key);
};

export const localizedDynamic = (key: keyof Translation | string): string => {
  return i18n.t(key);
};

export const localizedInterpolation = (key: keyof Translation, keysToReplace: any): string => {
  return i18n.t(key, keysToReplace);
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const getLanguage = () => {
  return i18n.language === 'en-US' ? 'en' : i18n.language;
};

export const changeLanguage = async (language: keyof typeof Languages) => {
  await i18n.changeLanguage(language);

  moment.locale([language, 'en']);
};

export const getTranslationNamespace = () => i18n.getFixedT('translation');

export const getLocaleData = () => {
  return moment.localeData(i18n.language);
};
