function getPos(element: any) {
  let x = 0;
  let y = 0;
  if (element) {
    do {
      x += element.offsetLeft - element.scrollLeft;
      y += element.offsetTop - element.scrollTop;
    } while (element === element.offsetParent);
  }
  return { x, y };
}

export { getPos };

function getPosFromSchedulerStateData(element: any) {
  let x = 0;
  let y = 0;
  if (element) {
    const scrollLeft = element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollLeft;
    const scrollTop = element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.scrollTop;

    x = -scrollLeft;
    y = -scrollTop;
  }
  return { x, y };
}

export { getPosFromSchedulerStateData };
