import { TableRow, TableContainer, Table, TableHead, TableBody, Typography, CircularProgress, Box, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import format from 'date-fns/format';
import locale from 'date-fns/locale/da';
import { Link } from 'react-router-dom';
import { PieChart, Pie, Cell, Label } from 'recharts';

import { UtilizationMonthDTO } from '../../services/http-services/api';
import { economicServices } from '../../services/economic.services';
import { TableStyles, StyledTableCell, StyledTableRow } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized, localizedDynamic } from '../../utilities/language/i18n';

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

function UtilizationOverview() {
  const [utilizationMonthDTOs, setUtilizationMonthDTOs] = useState<UtilizationMonthDTO[] | null>();

  React.useEffect(() => {
    async function loadUtilizationMonthDTOs() {
      const dataList = await economicServices.getUtilizationMonthOverview();
      setUtilizationMonthDTOs(dataList);
    }
    loadUtilizationMonthDTOs();
  }, [setUtilizationMonthDTOs]);

  React.useEffect(() => {
    document.title = 'Cenvation - Utilization';
  }, []);

  function generateDate(item: any) {
    if (item.datetime && format(item.datetime, 'MMMM yyyy').split(' ')[0]) {
      const month = localizedDynamic(format(item.datetime, 'MMMM yyyy').split(' ')[0]);
      // eslint-disable-next-line prefer-template
      return month + ' ' + format(item.datetime, 'MMMM yyyy').split(' ')[1];
    }
    return 'No month';
  }

  const classes = TableStyles();
  const COLORS = ['#1e9bd3', '#808080'];

  return (
    <Box>
      <Typography variant="h4" className="App_pageTitle">
        {localized('UtilizationOverview')}
      </Typography>
      {utilizationMonthDTOs ? (
        <div>
          <TableContainer key={1} component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{localized('TotalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('ExternalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('InternalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('Utilization')}</StyledTableCell>
                  <StyledTableCell>{localized('Month')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {utilizationMonthDTOs.map((item) => (
                  <StyledTableRow key={item.datetime?.toDateString()}>
                    <StyledTableCell component="th" scope="row">
                      {item.totalHours!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.externalHours!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.internalHours!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <PieChart height={60} width={60}>
                        <Pie
                          data={[
                            { name: 'Group A', value: item.utilization },
                            { name: 'Group B', value: 100 - item.utilization! }
                          ]}
                          innerRadius={24}
                          outerRadius={30}
                          startAngle={90}
                          endAngle={450}
                          fill="#1e9bd3"
                          paddingAngle={2}
                          dataKey="value"
                        >
                          {[
                            { name: 'Group A', value: item.utilization },
                            { name: 'Group B', value: 100 - item.utilization! }
                          ].map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                          <Label
                            value={`${item.utilization!.toLocaleString(localized('Format'), { maximumFractionDigits: 2 })}%`}
                            position="center"
                            fill="#000000"
                            fontSize={14}
                          />
                        </Pie>
                      </PieChart>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Link
                        className={classes.removeUnderline}
                        to={{
                          pathname: '/utilization/'.concat(item.datetime ? format(item.datetime, 'dd-MM-yy') : '')
                        }}
                      >
                        <Button variant="contained" color="primary" size={buttonSize} fullWidth>
                          {generateDate(item)}
                        </Button>
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
}

export default UtilizationOverview;
