import { VoucherRequest, Client } from './http-services/api';

async function createVoucher(voucherRequest: VoucherRequest) {
  const client = new Client();

  const result = await client.voucher(voucherRequest);
  return result;
}

async function getAll() {
  const client = new Client();

  const result = await client.voucherAll();
  return result;
}

async function getVoucherById(id: number) {
  const client = new Client();

  const result = await client.voucher2(id);
  return result;
}

async function updateVoucher(id: number, voucherRequest: VoucherRequest) {
  const client = new Client();

  const result = await client.voucher3(id, voucherRequest);
  return result;
}

export const voucherService = { createVoucher, getAll, getVoucherById, updateVoucher };
