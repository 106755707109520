import React, { useEffect, useState, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Grid, Snackbar, Typography } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';

import './login.scss';
import { authenticationService } from '../../services/auth.services';
import { useWindowWidth } from '../../utilities/use-window-width';
import { useAuth } from '../../utilities/use-auth';
import { localized } from '../../utilities/language/i18n';
import { buttonSize, mobileMaxWidth } from '../../utilities/constants';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const currentHistory = useHistory();
  const { width } = useWindowWidth();
  const auth = useAuth();

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  useEffect(() => {
    document.title = 'Cenvation - Login';
  }, []);

  const loginSubmit = async (event: MouseEvent) => {
    event.preventDefault();
    const authorized = await authenticationService.login(email, password);
    if (authorized) {
      auth.signIn();
      const pushPage = sessionStorage.getItem('curPage') ? sessionStorage.getItem('curPage') : '/create-timerecord-overview';
      sessionStorage.removeItem('curPage');
      currentHistory.push(pushPage!);
    } else {
      setSnackBarMessage(localized('WrongEmailorPassword'));
      setSnackBarSeverity('error');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  return (
    <div className={width > mobileMaxWidth ? 'Login' : 'LoginSmall'} data-testid="Login">
      <form className="Container" noValidate autoComplete="off">
        <div className="Card">
          <Typography className="App_pageTitle" variant="h4">
            Log ind
          </Typography>
          <CardContent>
            <div>
              <TextField
                fullWidth
                id="email"
                type="email"
                label={localized('Email')}
                placeholder={localized('Email')}
                margin="normal"
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                fullWidth
                id="password"
                type="password"
                label={localized('Password')}
                placeholder={localized('Password')}
                margin="normal"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </CardContent>
          <CardActions>
            <Grid container justify="center">
              <Button variant="contained" type="submit" size={buttonSize} color="primary" className="loginBtn" onClick={loginSubmit}>
                {localized('Login')}
              </Button>
            </Grid>
          </CardActions>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
