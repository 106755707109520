import { TextField, CircularProgress, Button, Typography, Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { DataGrid, GridColDef } from '@material-ui/data-grid';

import { billService } from '../../services/bill.service';
import { customerService } from '../../services/customer.services';
import { Voucher} from '../../services/http-services/api';
import { voucherService } from '../../services/voucher.service';
import { TableStyles, DataGridStyle } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { projectService } from '../../services/project.service';

function VoucherGetAll() {
  const [vouchers, setVouchers] = useState<Voucher[] | null>();
  const [savedPage, setSavedPage] = useState(0);
  const [searchName, setSearchName] = useState('');

  function sortByIsActive(a: Voucher, b: Voucher): number {
    if (a.active && !b.active) {
      return -1; // `a` comes before `b`
    } else if (!a.active && b.active) {
      return 1; // `b` comes before `a`
    } else {
      return 0; // No change in order
    }
  }

  React.useEffect(() => {
    document.title = 'Cenvation - Voucher';
  }, []);

  React.useEffect(() => {
    async function loadVouchers() {
      const dataList = await voucherService.getAll();
      const finishedDatalist: Voucher[] = [];
      for (const elm of dataList) {
        const bill = elm.billId;
        const cust = elm.customerId;
        const proj = elm.projectId;
        elm.bill = await billService.getBillById(bill);
        elm.customer = await customerService.getCustomerById(cust);
        if (proj) {
          elm.project = await projectService.getProjectById(proj);
        }
        finishedDatalist.push(elm);
      }
      finishedDatalist.sort(sortByIsActive);
      setVouchers(finishedDatalist);
    }
    loadVouchers();
  }, [setVouchers]);

  const loadSavedPage = () => {
    if (localStorage.page) {
      setSavedPage(parseInt(localStorage.page, 10));
      return savedPage;
    }
    return savedPage;
  };

  React.useEffect(() => {
    loadSavedPage();
  }, []);

  const handlePageChange = (page: number) => {
    localStorage.page = page;
    setSavedPage(page);
  };

  const ConvertState = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const classes = TableStyles();
  const classesGrid = DataGridStyle();

  function changeSearchVal(event: string) {
    setSearchName(event);
  }
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: localized('Id'),
      align: 'center',
      headerAlign: 'center',
      width: 150
    },
    {
      field: 'customer',
      headerName: localized('Customer'),
      align: 'center',
      headerAlign: 'center',
      width: 320,
      valueGetter: (params) => (params.row.customer ? params.row.customer.name : '')
    },
    {
      field: 'originalSize',
      headerName: localized('OriginalHours'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'remainingHours',
      headerName: localized('HoursRemaining'),
      align: 'center',
      headerAlign: 'center',
      width: 200
    },
    {
      field: 'number',
      headerName: localized('InvoiceNumber'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => {
        return params.row['billId'] ? (
          <Link to={'/timerecords-by-bill/' + params.row['billId']}>{params.row['bill'].number}</Link>
        ) : params.row['billId'] ? (
          params.row['billId']
        ) : (
          ''
        );
      }
    },
    {
      field: 'active',
      headerName: localized('Active'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      sortable: true,
      renderCell: (params) => {
        return <div>{ConvertState(params.value)}</div>;
      }
    },
    {
      field: 'project',
      headerName: localized('Project'),
      align: 'center',
      headerAlign: 'center',
      width: 200,
      valueGetter: (params) => (params.row.project ? params.row.project.name : '')
    },
    {
      field: 'update',
      headerName: localized('Update'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Link className={classes.removeUnderline} to={{ pathname: `/edit-voucher/${params.row['id']}` }}>
            <Button variant="contained" color="primary" size={buttonSize}>
              {localized('Update')}
            </Button>
          </Link>
        );
      }
    }
  ];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('ViewAllVouchers')}
      </Typography>
      {vouchers && (
        <Box margin={2}>
          <TextField
            id="standard-basic"
            value={searchName}
            label={localized('SearchVouchers')}
            onChange={(event) => changeSearchVal(event.target.value)}
          />
        </Box>
      )}
      {vouchers ? (
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1, minHeight: 890 }}>
            {vouchers ? (
              <DataGrid
                page={savedPage}
                className={classesGrid.root}
                rows={vouchers}
                columns={columns}
                pageSize={15}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageChange={handlePageChange}
                filterModel={{
                  items: [{ columnField: 'customer', operatorValue: 'contains', value: searchName }]
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(VoucherGetAll);
