import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Alert, { Color } from '@material-ui/lab/Alert';

import { personService } from '../../../services/person.services';

import { Person, TimerecordRequest, Project, Booking, HolidayDTO } from '../../../services/http-services/api';
import { timerecordService } from '../../../services/timerecord.services';

import { StyledTableRow } from '../../../styles/use-styles';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { projectService } from '../../../services/project.service';
import { bookingService } from '../../../services/booking.services';
import { configurationService } from '../../../services/configuration.service';

const useStyles = makeStyles({
  table: {
    minWidth: 300
  },
  paper: {
    padding: 20
  }
});

const HolidayRegistration = () => {
  const [selectedDates, setValue] = useState<DateObject | DateObject[] | null>();
  const [description, setDescription] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('error');
  const [consultants, setConsultants] = React.useState<Person[]>();
  const [selectedConsultant, setSelectedConsultant] = React.useState<Person | null>();
  const [holidayProjects, setHolidayProjects] = React.useState<Project[]>();
  const [selectedType, setSelectedType] = React.useState<Project | null>();

  const classes = useStyles();

  const removeDate = (index: number) => {
    if (selectedDates !== undefined && Array.isArray(selectedDates)) {
      const dates = selectedDates.filter((_d, i) => i !== index);
      setValue(dates);
    }
  };

  async function loadCustomers() {
    const tempConsultants = await personService.getActive();
    setConsultants(tempConsultants);
  }

  async function loadHoliday() {
    const ferieId = (await configurationService.getAll()).filter((conf) => conf.label === 'FerieID')[0];
    const helligId = (await configurationService.getAll()).filter((conf) => conf.label === 'HelligdagID')[0];

    const tempHolidayProjects = (await projectService.getActiveProjects()).filter(
      (proj) => proj.id === parseInt(ferieId.value) || proj.id === parseInt(helligId.value)
    );
    setHolidayProjects(tempHolidayProjects);
  }

  const handleConsultantChange = (event: any, value: Person | null) => {
    setSelectedConsultant(value);
  };

  const handleTypeChange = (event: any, value: Project | null) => {
    setSelectedType(value);
  };

  React.useEffect(() => {
    document.title = 'Cenvation - Holiday Registration';
    loadCustomers();
    loadHoliday();
  }, []);

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const convertDataObjectToHolidayDTO = () => {
    const holidayDTO = new HolidayDTO();

    if (description === undefined || description === '') {
      setSnackBarMessage(localized('EnterDescription'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return false;
    }

    if (selectedDates === undefined) {
      setSnackBarMessage(localized('SelectOneOrMoreDates'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return false;
    }

    if (selectedType === undefined) {
      setSnackBarMessage(localized('SelectHolidayType'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return false;
    }

    holidayDTO.title = description;
    holidayDTO.type = selectedType!.id!;
    holidayDTO.holidayDates = [];
    if (Array.isArray(selectedDates)) {
      selectedDates.forEach((element) => {
        if (holidayDTO.holidayDates === undefined) {
          holidayDTO.holidayDates = [element.toDate()];
        } else {
          holidayDTO.holidayDates.push(element.toDate());
        }
      });
    }
    return holidayDTO;
  };

  const handleSubmit = (event: any) => {
    if (selectedType) {
      if (selectedConsultant) {
        if (Array.isArray(selectedDates)) {
          selectedDates.forEach((element) => {
            timerecordService.createTimerecord(
              new TimerecordRequest({
                personId: selectedConsultant!.id!,
                projectId: selectedType!.id!,
                date: element!.toDate(),
                hours: 8,
                comment: '',
                CEref: 0,
                APref: 0
              })
            );
            const startDate = element!.setHour(9).setMinute(0).toDate();

            const endDate = element!.setHour(17).setMinute(0).toDate();
            bookingService.create(
              new Booking({
                projectId: selectedType!.id!,
                personId: selectedConsultant!.id!,
                startDate: startDate!,
                endDate: endDate!,
                title: description!,
                type: 1
              })
            );
            setSnackBarMessage(localized('HolidayDatesRegistered'));
            setSnackBarSeverity('success');
            setIsSnackBarOpen(true);
          });
        } else {
          timerecordService.createTimerecord(
            new TimerecordRequest({
              personId: selectedConsultant!.id!,
              projectId: selectedType!.id!,
              date: selectedDates!.toDate(),
              hours: 8,
              comment: '',
              CEref: 0,
              APref: 0
            })
          );

          const startDate = selectedDates!.setHour(9).setMinute(0).toDate();

          const endDate = selectedDates!.setHour(17).setMinute(0).toDate();
          bookingService.create(
            new Booking({
              projectId: selectedType!.id!,
              personId: selectedConsultant!.id!,
              startDate: startDate!,
              endDate: endDate!,
              title: description!,
              type: 1
            })
          );
          setSnackBarMessage(localized('HolidayDatesRegistered'));
          setSnackBarSeverity('success');
          setIsSnackBarOpen(true);
        }
      } else {
        event.persist();
        const holidayDTO = convertDataObjectToHolidayDTO();
        if (holidayDTO !== false) {
          try {
            //eslint-disable-next-line no-unused-vars
            const result = timerecordService.createHolidayRegistration(holidayDTO);
            setSnackBarMessage(localized('HolidayDatesRegistered'));
            setSnackBarSeverity('success');
            setIsSnackBarOpen(true);
          } catch (error) {
            //eslint-disable-next-line no-empty
          }
        }
      }
      setSelectedConsultant(null);
      setSelectedType(null);
      setValue(null);
      setDescription('');
    }
  };

  const handleDateChange = (newDate: DateObject | DateObject[] | null) => {
    setValue(newDate);
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const functionseperateValues = () => {
    return (
      <TableBody>
        {Array.isArray(selectedDates) &&
          selectedDates.map((object, index) => {
            return (
              <StyledTableRow key={index}>
                <TableCell align="left"> {object.format('dddd DD MMMM YYYY')}</TableCell>
                <TableCell align="right">
                  <DeleteForeverIcon onClick={() => removeDate(index)} />
                </TableCell>
              </StyledTableRow>
            );
          })}
      </TableBody>
    );
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('HolidayRegs')}
      </Typography>
      <div>
        <Typography variant="body1" style={{ margin: 12, fontWeight: 'bold' }}>
          {localized('CreateHolidayRegistrationsforAllEmployees')}
        </Typography>

        <Box padding={2}>
          <Grid container direction="column" justify="center" alignItems="center" style={{ marginBottom: '0.5em' }}>
            <TextField
              required
              id="Description"
              value={description}
              margin="normal"
              fullWidth
              variant="outlined"
              label={localized('HolidayDesciption')}
              onChange={handleChangeDescription}
            />
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: '1em' }}>
            <Autocomplete
              id="Consultant"
              fullWidth
              options={consultants ? consultants : []}
              getOptionLabel={(option: Person) => option.name}
              renderInput={(inputParams) => (
                <TextField required {...inputParams} label={localized('SelectConsultant')} variant="outlined" />
              )}
              onChange={handleConsultantChange}
              value={selectedConsultant}
            />
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: '1em' }}>
            <Autocomplete
              id="HolidayType"
              fullWidth
              options={holidayProjects ? holidayProjects : []}
              getOptionLabel={(option: Project) => option.name}
              renderInput={(inputParams) => (
                <TextField required {...inputParams} label={localized('SelectHolidayType')} variant="outlined" />
              )}
              onChange={handleTypeChange}
              value={selectedType}
            />
          </Grid>
        </Box>
        <Grid container direction="row" justify="center" alignItems="center">
          <DatePicker
            value={selectedDates}
            onChange={handleDateChange}
            multiple
            format="DD MMM YYYY"
            sort={true}
            minDate={Date.now()}
            type="button"
            placeholder={localized('ClickToShowDate')}
          />

          <TableContainer component={Paper}>
            <Table aria-label="datesTabel" className={classes.table}>
              {functionseperateValues()}
            </Table>
          </TableContainer>
        </Grid>

        <Box margin={2}>
          <Button variant="contained" size={buttonSize} color="primary" onClick={handleSubmit}>
            {localized('SubmitHolidayRegs')}
          </Button>
        </Box>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={snackBarSeverity} variant="filled">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HolidayRegistration;
