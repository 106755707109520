/* eslint-disable no-unused-vars */
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormGroup,
  Checkbox,
  Snackbar,
  Paper,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';

import AlertDialog from '../../components/alert-dialog/alert-dialog';
import { personService } from '../../services/person.services';
import { Person } from '../../services/http-services/api';
import { ApplicationUseStyles } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { authenticationService } from '../../services/auth.services';

function EditSettings() {
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const [id, setId] = React.useState(0);
  const [name, setPersonName] = React.useState('');

  const [email, setEmail] = useState('');
  const [personRole, setPersonRole] = useState('');
  const [active, setActive] = useState(false);
  const [canBeBooked, setCanBeBooked] = useState(false);
  const [commentColumn, setCommentColumn] = useState(false);
  const [bookingSuggestion, setBookingSuggestion] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [team, setTeam] = useState(0);

  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const classes = ApplicationUseStyles();
  const history = useHistory();
  const params = useParams<props>();

  interface props {
    personId: string;
  }

  React.useEffect(() => {
    async function getPerson() {
      const currentId = await authenticationService.getUserId();
      if (currentId) setId(currentId);
    }
    getPerson();
  }, []);

  React.useEffect(() => {
    async function dataForPerson() {
      if (id) {
        const editPerson = await personService.getPersonById(id);
        if (editPerson.id) setId(editPerson.id);
        if (editPerson.name) setPersonName(editPerson.name);
        if (editPerson.email) setEmail(editPerson.email);
        if (editPerson.role) setPersonRole(editPerson.role);
        if (editPerson.active) setActive(editPerson.active);
        if (editPerson.canBeBooked) setCanBeBooked(editPerson.canBeBooked);
        if (editPerson.BookingSuggestion) setBookingSuggestion(editPerson.BookingSuggestion);
        if (editPerson.Dashboard) setDashboard(editPerson.Dashboard);
        if (editPerson.CommentColumn) setCommentColumn(editPerson.CommentColumn);
        if (editPerson.team) setTeam(editPerson.team);
      }
    }
    dataForPerson();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.persist();
    const role = personRole;

    if (name === '') {
      setSnackBarMessage(localized('NameIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (email === '') {
      setSnackBarMessage(localized('EmailIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (personRole === '') {
      setSnackBarMessage(localized('RoleIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (active === false) {
      // eslint-disable-next-line prefer-template
      setPersonName('Konsulent' + id.toString(10));
    }

    await personService.update(
      new Person({
        id,
        name,
        email,
        role,
        active,
        canBeBooked,
        BookingSuggestion: bookingSuggestion,
        CommentColumn: commentColumn,
        Dashboard: dashboard,
        team
      })
    );
    setSnackBarMessage('Settings successfully updated');
    setSnackBarSeverity('success');
    setIsSnackBarOpen(true);
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleSetInactive = () => {
    // eslint-disable-next-line prefer-template
    setPersonName('Konsulent' + id.toString(10));
    setActive(false);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <div className={classes.root}>
        <Typography variant="h4" className="App_pageTitle">
          {localized('Settings')}
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            label={localized('ShowCommentColumn')}
            labelPlacement="start"
            style={{ display: 'table' }}
            control={
              <div style={{ display: 'table-cell' }}>
                <Checkbox
                  checked={commentColumn}
                  onChange={(event: any) => {
                    event.persist();
                    setCommentColumn(!commentColumn);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            }
          />
          <FormControlLabel
            label={localized('SuggestBookings')}
            labelPlacement="start"
            style={{ display: 'table' }}
            control={
              <div style={{ display: 'table-cell' }}>
                <Checkbox
                  checked={bookingSuggestion}
                  onChange={(event: any) => {
                    event.persist();
                    setBookingSuggestion(!bookingSuggestion);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            }
          />
          <FormControlLabel
            label="Dashboard"
            labelPlacement="start"
            style={{ display: 'table' }}
            control={
              <div style={{ display: 'table-cell' }}>
                <Checkbox
                  checked={dashboard}
                  onChange={(event: any) => {
                    event.persist();
                    setDashboard(!dashboard);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            }
          />
          <Button
            variant="contained"
            color="primary"
            size={buttonSize}
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            {localized('SaveChanges')}
          </Button>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isSnackBarOpen}
          autoHideDuration={2500}
          onClose={handleCloseSnackbar}
        >
          <Alert severity={snackBarSeverity} variant="filled">
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </div>
    </form>
  );
}

export default withRouter(EditSettings);
