// This file is made with inspiration from https://www.barrymichaeldoyle.com/use-viewport/

import { createContext, FC, useContext, useState } from 'react';

import { authenticationService } from '../services/auth.services';

interface IAuth {
  loginState: boolean;
  signIn: () => void;
  signOut: () => void;
}

function useProvideAuth() {
  const [loginState, setLoggedIn] = useState(authenticationService.isAuthenticated());

  const signIn = () => {
    setLoggedIn(true);
    return loginState;
  };

  const signOut = () => {
    setLoggedIn(false);
    return loginState;
  };

  return {
    loggedIn: loginState,
    signIn,
    signOut
  };
}

const AuthContext = createContext<IAuth>({
  loginState: authenticationService.isAuthenticated(),
  signIn: (): void => {
    throw new Error('signIn function must be overridden');
  },
  signOut: (): void => {
    throw new Error('signOut function must be overridden');
  }
});

export const AuthProvider: FC = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={{ loginState: auth.loggedIn, signIn: auth.signIn, signOut: auth.signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext<IAuth>(AuthContext);
};
