import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TableRow, TableContainer, Table, TableHead, TableBody, Button, Box, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams, Link, withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Bill, Customer } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../styles/use-styles';
import { billService } from '../../services/bill.service';
import { customerService } from '../../services/customer.services';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';

function BillingPerCustomer() {
  const [customerBills, setCustomerBills] = React.useState<Bill[] | null>();
  const [customer, setCustomer] = React.useState<Customer | null>();

  const history = useHistory();
  const params = useParams<props>();

  interface props {
    customerBills: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const customerId = JSON.parse(params.customerBills);
      if (customerId) {
        const custBills = await billService.getCustomerBills(parseInt(customerId, 10));
        custBills.sort(function (a, b) {
          if (a.number > b.number) {
            return -1;
          }
          if (a.number < b.number) {
            return 1;
          }
          return 0;
        });
        setCustomerBills(custBills);

        const cust = await customerService.getCustomerById(customerId);
        setCustomer(cust);
      }
    }

    dataFromProps();
  }, []);

  const ConvertState = (active: any) => {
    if (active) {
      return localized('Yes');
    } else {
      return localized('No');
    }
  };

  const classes = TableStyles();

  const handleGoBackClick = () => {
    history.goBack();
  };

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('InvoicesFor')} {customer?.name}
      </Typography>
      {customerBills ? (
        <div>
          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
            <Table className={classes.table} aria-label="customerBills-table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="right"> {localized('InvoiceNumber')}</StyledTableCell>
                  <StyledTableCell align="right"> {localized('Paid')}</StyledTableCell>
                  <StyledTableCell align="right"> {localized('SeeSpecification')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerBills.map((row: Bill) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="right">{row.number}</StyledTableCell>
                    <StyledTableCell align="right">{ConvertState(row.paid)}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Link
                        className={classes.removeUnderline}
                        to={{
                          pathname: '/bill-specification/'.concat(row.id ? row.id.toString() : '')
                        }}
                      >
                        <Button variant="contained" color="primary" size={buttonSize}>
                          {localized('Specification')}
                        </Button>
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Box margin={2}>
        <Button
          variant="contained"
          color="primary"
          size={buttonSize}
          className="button"
          onClick={handleGoBackClick}
          startIcon={<ArrowBackIcon />}
        >
          {localized('GoBack')}
        </Button>
      </Box>
    </div>
  );
}

export default withRouter(BillingPerCustomer);
