import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Snackbar,
  Paper,
  Grid,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Autocomplete, Color } from '@material-ui/lab';

import { Customer, Project, Product } from '../../../services/http-services/api';
import { projectService } from '../../../services/project.service';
import { ApplicationUseStyles } from '../../../styles/use-styles';
import { customerService } from '../../../services/customer.services';
import { buttonSize } from '../../../utilities/constants';
import { localized } from '../../../utilities/language/i18n';
import { configurationService } from '../../../services/configuration.service';

function CopyProject() {
  const [name, setProjectName] = React.useState('');
  const [orgPrice, setOrginalPrice] = React.useState(0);
  const [discount, setDiscountPrice] = React.useState<string | number>('');
  const [actualPrice, setActualPrice] = React.useState(0);
  const [budget, setBudget] = React.useState(0);
  const [type, setType] = React.useState(0);
  const [timeRegNeedComment, setTimeRegNeedComment] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [fixedPrice, setFixedPrice] = React.useState(false);
  const [sla, setCheckedSLA] = React.useState(false);
  const [dataIsPresent, setDataIsPresent] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(0);
  const [customers, setCustomer] = React.useState<Customer[] | null>();
  const [slaVal, setSlaVal] = React.useState<number>(1.5);
  const [products, setProducts] = React.useState<Product[] | null>();
  const [selectedProduct, setProduct] = React.useState<Product | undefined>();
  const [selectedCustomer, setselectedCustomer] = React.useState<Customer>(new Customer());

  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');

  const history = useHistory();
  const params = useParams<props>();
  const classes = ApplicationUseStyles();

  interface props {
    copyProject: string;
  }

  React.useEffect(() => {
    async function dataFromProps() {
      const copyProjectId = JSON.parse(params.copyProject);
      const productList = await configurationService.getAllProducts();
      setProducts(productList);
      if (copyProjectId) {
        const copyProject = await projectService.getProjectById(parseInt(copyProjectId, 10));
        if (copyProject.name) setProjectName(copyProject.name);
        if (copyProject.orgPrice) setOrginalPrice(copyProject.orgPrice);
        if (copyProject.discount) setDiscountPrice(copyProject.discount);
        if (copyProject.price) setActualPrice(copyProject.price);
        if (copyProject.budget) setBudget(copyProject.budget);
        if (copyProject.type) setProduct(productList.find(p => p.productId === copyProject.type));
        if (copyProject.timeRegNeedComment) setTimeRegNeedComment(copyProject.timeRegNeedComment);
        if (copyProject.active) setActive(copyProject.active);
        if (copyProject.fixedPrice) setFixedPrice(copyProject.fixedPrice);
        if (copyProject.sla) setCheckedSLA(copyProject.sla);
        if (copyProject.customerId) {
          setCustomerId(copyProject.customerId);
          const custumer = await customerService.getCustomerById(copyProject.customerId);
          setselectedCustomer(custumer);
        }
        const customerList = await customerService.getAll();
        setCustomer(customerList);

        var con_lst = await configurationService.getAll();
        var _sla = con_lst.filter((con) => con.label === 'SLA');
        if (_sla.length === 1) {
          setSlaVal(parseFloat(_sla[0].value.replace(',', '.')));
        }

        setDataIsPresent(true);
      }
    }
    dataFromProps();
  }, []);

  const handleChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleChangeOrginalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrginalPrice(parseInt(event.target.value, 10));
  };

  const handleChangeDiscountPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setDiscountPrice(event.target.value);
      setActualPrice(orgPrice);
    } else {
      const value = parseFloat(event.target.value);
      if (value >= 0 && value <= 100) {
        setDiscountPrice(parseFloat(event.target.value));
        if (value === 0) {
          setActualPrice(orgPrice);
        } else {
          const newActualPrice = (orgPrice * (1 - value / 100)).toFixed(3);
          setActualPrice(parseFloat(newActualPrice));
        }
      } else {
        setSnackBarMessage(localized('Discount0100'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      }
    }
  };

  const handleChangeActualPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (value > orgPrice && sla === false) {
      setSnackBarMessage(localized('ActualPriceHigherThanOri'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else if (value < 0) {
      setSnackBarMessage(localized('ActualPriceNegative'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else if (value === 0 && selectedProduct?.name !== "Internal") {
      setSnackBarMessage(localized('ActualPrice0'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    } else {
      setActualPrice(value);
      if (orgPrice !== 0) {
        const newDiscount = (((orgPrice - value) / orgPrice) * 100).toFixed(3);
        setDiscountPrice(parseFloat(newDiscount));
      }
    }
  };

  const handleChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudget(parseInt(event.target.value, 10));
  };

  const handleChangeProduct = (event: any, value: Product | null) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    var product = value ? value : undefined;
    setProduct(product);
    // Remove SLA if new product type can not be SLA
    if (!product?.canBeSLA && sla) { 
      setCheckedSLA(false);
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleCustomerChange = (event: any, value: Customer | null) => {
    if (value !== null) {
      setselectedCustomer(value);
      setOrginalPrice(value.price);
      if (actualPrice) {
        var newDiscount = ((value.price - actualPrice) / value.price) * 100;
        setDiscountPrice(newDiscount);
      }
    }
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeRegNeedComment(event.target.checked);
  };

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  const handleChangeFixed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFixedPrice(event.target.checked);
  };

  const handleChangeSLA = (event: React.ChangeEvent<HTMLInputElement>) => {
    var discountNumber = 1 - parseFloat(discount.toString()) / 100;
    // If the Checkbox is not disabled, update sla based on the user's action.
    setCheckedSLA(event.target.checked);
    if (event.target.checked) {
      setOrginalPrice(orgPrice * slaVal);
      setActualPrice(orgPrice * slaVal * discountNumber);
    } else {
      setOrginalPrice(orgPrice / slaVal);
      setActualPrice((orgPrice / slaVal) * discountNumber);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleSubmit = async (event: any) => {
    event.persist();

    if (name === '') {
      setSnackBarMessage(localized('NameIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(orgPrice) || (orgPrice === 0 && selectedProduct?.name !== "Internal")) {
      setSnackBarMessage(localized('CustomerPriceIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (isNaN(actualPrice) || (actualPrice === 0 && selectedProduct?.name !== "Internal")) {
      setSnackBarMessage(localized('PriceIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }

    if (selectedProduct === undefined) {
      setSnackBarMessage(localized('TypeIsRequired'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    let discountNumber = 0;
    if (discount === '') {
      discountNumber = 0;
    } else {
      discountNumber = parseFloat(discount.toString());
    }

    await projectService.createProject(
      new Project({
        name,
        type: selectedProduct!.productId!,
        orgPrice,
        discount: discountNumber,
        price: actualPrice,
        customerId,
        budget,
        timeRegNeedComment,
        active,
        fixedPrice,
        sla
      })
    );
    history.goBack();
  };

  return (
    <form noValidate autoComplete="off">
      <Typography variant="h4" className="App_pageTitle">
        {localized('CopyProject')}
      </Typography>
      <div className={classes.root}>
        {dataIsPresent && customers && products ? (
          <Paper className={classes.paperPadding}>
            <Grid container>
              <div>
                <Grid container>
                  <TextField
                    required
                    id="ProjectName"
                    name="name"
                    label={localized('ProjectName')}
                    variant="outlined"
                    value={name}
                    onChange={handleChangeProjectName}
                  />
                  <Autocomplete
                    id="customerChange"
                    value={selectedCustomer}
                    options={customers}
                    getOptionLabel={(option: Customer) => option.name}
                    renderInput={(params) => <TextField required {...params} label={localized('SelectCustomer')} variant="outlined" />}
                    onChange={handleCustomerChange}
                    disabled={
                      selectedProduct ? 
                        selectedProduct.name === "Internal"
                      : false}
                  />
                </Grid>
                <Grid container>
                  <Autocomplete
                    id="ProjectTypeCopy"
                    options={
                      selectedCustomer.name !== 'Intern' 
                        ? products?.filter(p => p.name !== 'Internal')
                        : products
                    }
                    getOptionLabel={(option: Product) => option.name}
                    renderInput={(inputParams) => (
                      <TextField required {...inputParams} label={localized('SelectProjectType')} variant="outlined" />
                    )}
                    onChange={handleChangeProduct}
                    value={selectedProduct}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    disabled
                    id="ProjCopy-OrigPrice"
                    label={localized('CustomerPrice')}
                    name="orgPrice"
                    type="number"
                    variant="outlined"
                    value={orgPrice}
                    onChange={handleChangeOrginalPrice}
                  />
                  <TextField
                    required
                    id="ProjCopy-Discount"
                    name="discount"
                    label={localized('DiscountPercent')}
                    type="number"
                    variant="outlined"
                    value={discount}
                    onChange={handleChangeDiscountPrice}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    required
                    id="ProjCopy-ActualPrice"
                    name="price"
                    label={localized('ProjectPrice')}
                    type="number"
                    variant="outlined"
                    value={actualPrice}
                    onChange={handleChangeActualPrice}
                  />
                  <TextField
                    required
                    id="ProjCopy-Budget"
                    name="Budget"
                    label={localized('Budget')}
                    type="number"
                    variant="outlined"
                    value={budget}
                    onChange={handleChangeBudget}
                  />
                </Grid>

                <FormGroup>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('RegistrationsMust')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={timeRegNeedComment}
                              onChange={handleChangeComment}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('Active')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} />
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('FixedPrice')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox checked={fixedPrice} onChange={handleChangeFixed} inputProps={{ 'aria-label': 'primary checkbox' }} />
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={localized('SLA-Project')}
                        labelPlacement="start"
                        style={{ display: 'table' }}
                        control={
                          <div style={{ display: 'table-cell' }}>
                            <Checkbox
                              checked={selectedProduct?.canBeSLA ? sla : false}
                              onChange={handleChangeSLA}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={!selectedProduct?.canBeSLA}
                            />{' '}
                          </div>
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                <Grid container justify="space-evenly">
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBackClick}
                  >
                    {localized('GoBack')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size={buttonSize}
                    className="button"
                    startIcon={<SaveIcon />}
                    onClick={handleSubmit}
                  >
                    {localized('MakeCopy')}
                  </Button>
                </Grid>
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={isSnackBarOpen}
                  autoHideDuration={2500}
                  onClose={handleCloseSnackbar}
                >
                  <Alert severity={snackBarSeverity} variant="filled">
                    {snackBarMessage}
                  </Alert>
                </Snackbar>
              </div>
            </Grid>
          </Paper>
        ) : (
          <CircularProgress />
        )}
      </div>
    </form>
  );
}

export default CopyProject;
