import React from 'react';
import {
  Snackbar,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Button,
  TextField,
  Radio,
  CircularProgress,
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import Alert, { Color } from '@material-ui/lab/Alert';
import { projectService } from '../../services/project.service';
import { economicServices } from '../../services/economic.services';
import { BillingForCustomerDTO, BillingRequest, Voucher, Project, Customer } from '../../services/http-services/api';
import { StyledTableCell, StyledTableRow, TableStyles } from '../../styles/use-styles';
import AlertDialog from '../../components/alert-dialog/alert-dialog';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { customerService } from '../../services/customer.services';

interface BillingSpecificProps {
  customerId: string;
}

function BillingOnePerProject() {
  const [billingForCustomerDto, setBillingForCustomerDto] = React.useState<BillingForCustomerDTO | null>();
  const [billNumber, setBillNumber] = React.useState<number | undefined>(undefined);
  const [vouchers, setVouchers] = React.useState<Voucher[]>();
  const [selectedProjectIndex, setSelectedProjectIndex] = React.useState(0);
  const [projects, setProjects] = React.useState<Project[]>();
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState<Color>('warning');
  const [customer, setCustomer] = React.useState<Customer | null>();
  const [alertDialogVisible, setAlertDialogVisible] = React.useState(false);
  const [warningDialogVisible, setWarningDialogVisible] = React.useState(false);

  const params = useParams<BillingSpecificProps>();

  async function loadBillingForCustomerDto() {
    var tempCust = await customerService.getCustomerById(parseInt(params.customerId, 10));
    setCustomer(tempCust);
    const billingDto = await economicServices.getCustomerBilling(parseInt(params.customerId, 10));
    setBillingForCustomerDto(billingDto);
    setVouchers(billingDto.vouchers);
  }
  async function loadProjects() {
    const tempProjs = await projectService.getCustomerProjects(parseInt(params.customerId, 10));
    setProjects(tempProjs);
  }

  React.useEffect(() => {
    loadProjects();
    loadBillingForCustomerDto();
  }, []);

  React.useEffect(() => {
    loadBillingForCustomerDto();
  }, [setBillingForCustomerDto, snackBarMessage]);

  const classes = TableStyles();
  const history = useHistory();

  const handleBillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setBillNumber(0);
    } else {
      const value = parseInt(event.target.value, 10);
      if (value > 0) {
        setBillNumber(value);
      } else {
        setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
        setIsSnackBarOpen(true);
      }
    }
  };

  function FindProjectSubtracted(voucherListOne: Voucher[], voucherListTwo: Voucher[]) {
    var name = '';
    if (voucherListOne.length === voucherListTwo.length) {
      voucherListOne.forEach((element) => {
        const comparedElm = voucherListTwo.filter((voucher) => voucher.id === element.id)[0];
        if (comparedElm && element.remainingHours !== comparedElm.remainingHours && comparedElm.projectId)
          name = projects!.filter((proj) => proj.id === element.projectId)[0].name;
      });
    } else {
      voucherListOne.forEach((element) => {
        const comparedElm = voucherListTwo.filter((voucher) => voucher.id === element.id)[0];
        if (!comparedElm && element.projectId) name = projects!.filter((proj) => proj.id === element.projectId)[0].name;
      });
    }
    return name;
  }
  async function handleBillSpecificVoucher(voucherId: number | undefined) {
    //
    if (
      billingForCustomerDto?.billingDto?.projectForBillingDtos &&
      billingForCustomerDto?.billingDto?.projectForBillingDtos.length > 0 &&
      voucherId
    ) {
      const voucher = vouchers?.filter((elm) => elm.id == voucherId)[0];
      const projectIds = voucher!.projectId
        ? [voucher!.projectId]
        : [billingForCustomerDto?.billingDto?.projectForBillingDtos[selectedProjectIndex]!.projectId!];
      await economicServices.billHoursWithSpecificVoucher(voucherId, projectIds!);
      setSnackBarMessage('Testing Voucher Functiuons');
      await loadBillingForCustomerDto();
    }
  }

  const handleClickNewBill = async () => {
    if (
      billingForCustomerDto &&
      billingForCustomerDto.billingDto &&
      billingForCustomerDto.billingDto.projectForBillingDtos &&
      billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex] &&
      billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex].projectId
    ) {
      const response = await economicServices.createNewBillFromTimerecordsSpecificProject(
        billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex].projectId!,
        parseInt(params.customerId, 10)
      );
      const billingDtoNew = await economicServices.getCustomerBilling(parseInt(params.customerId, 10));
      loadBillingForCustomerDto();
      const ProjectName = vouchers && billingDtoNew.vouchers ? FindProjectSubtracted(vouchers, billingDtoNew.vouchers) : null;
      if (response === null || response === undefined) {
        setSnackBarMessage(localized('ErrorCreatingNewInvoice'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      } else if (response.hoursRemainingOnVouchers! > 0) {
        setSnackBarMessage(
          localized('VoucherInvoice1')
            .concat(`${response.totalHoursForCustomer}`)
            .concat(localized('VoucherInvoice2').concat(`${response.hoursRemainingOnVouchers}`).concat(localized('VoucherInvoice3')))
            .concat(ProjectName ? ', '.concat(localized('Voucher').concat(': ').concat(ProjectName)) : '')
        );
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
      } else if (response.hoursOnVouchers! > 0) {
        setSnackBarMessage(
          localized('DraftAndVoucher1')
            .concat(`${response.hoursOnVouchers}`)
            .concat(
              localized('DraftAndVoucher2')
                .concat(`${response.hoursSentToEconomic}`)
                .concat(localized('DraftAndVoucher3'))
                .concat(ProjectName ? ', '.concat(localized('Voucher').concat(': ').concat(ProjectName)) : '')
            )
        );
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
      } else {
        setSnackBarMessage(localized('DraftToEconomic1').concat(`${response.hoursSentToEconomic}`).concat(localized('DraftToEconomic2')));
        setSnackBarSeverity('success');
        setIsSnackBarOpen(true);
      }
      setBillNumber(undefined);
    } else {
      setSnackBarMessage(localized('PleaseSelectAProjectOrSynchronize'));
      setSnackBarSeverity('info');
      setIsSnackBarOpen(true);
    }
  };
  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleAlertDialogVisible = () => {
    if (billNumber && billNumber > 0) {
      setAlertDialogVisible(true);
    } else {
      setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
      setIsSnackBarOpen(true);
    }
  };

  const handleWarningDialogVisible = () => {
    if (billingForCustomerDto) {
      var projectId = billingForCustomerDto.billingDto!.projectForBillingDtos![selectedProjectIndex].projectId!;
      if (
        vouchers &&
        vouchers!
          .filter((voucher) => voucher.projectId === projectId || voucher.projectId === undefined || voucher.projectId === null)
          .map((elm) => elm.remainingHours)
          .reduce((a, b) => a + b, 0) > 0
      ) {
        setWarningDialogVisible(true);
      } else {
        handleClickNewBill();
      }
    }
  };

  const handleClickWithBill = async () => {
    if (billNumber === undefined || billNumber === 0) {
      setSnackBarMessage(localized('BillNumbermustbegreaterthan0'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
      return;
    }
    if (
      billingForCustomerDto &&
      billingForCustomerDto.billingDto &&
      billingForCustomerDto.billingDto.projectForBillingDtos &&
      billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex] &&
      billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex].projectId
    ) {
      const projectForBillingDTO = billingForCustomerDto.billingDto.projectForBillingDtos[selectedProjectIndex];
      if (billNumber > 0 && projectForBillingDTO.projectId) {
        await economicServices.updateTimerecordsWithBillSpecificProject(
          projectForBillingDTO.projectId,
          new BillingRequest({ billNumber, customerId: parseInt(params.customerId, 10) })
        );
        loadBillingForCustomerDto();
        setBillNumber(undefined);
        if (billingForCustomerDto.billingDto.projectForBillingDtos.length > 1) {
          setSelectedProjectIndex(0);
          loadBillingForCustomerDto();
        }
      } else {
        setSnackBarMessage(localized('PleaseSelectBill'));
        setSnackBarSeverity('warning');
        setIsSnackBarOpen(true);
      }
    } else {
      setSnackBarMessage(localized('PleaseSelectProject'));
      setSnackBarSeverity('warning');
      setIsSnackBarOpen(true);
    }
  };

  const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackBarOpen(false);
  };

  const handleChangeSelectedProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.value, 10);
    setSelectedProjectIndex(index);
  };

  return (
    <div>
      {billingForCustomerDto ? (
        <div>
          <Typography variant="h4" className="App_pageTitle">
            {localized('InvoicesFor')} {customer?.name}
          </Typography>
          <Box>
            <TableContainer>
              <Table className={classes.table} aria-label="onePerProject-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">{localized('ProjectName')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Active')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Hours')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Rate')}</StyledTableCell>
                    <StyledTableCell align="right">{localized('Total')}</StyledTableCell>
                    <StyledTableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingForCustomerDto.billingDto &&
                    billingForCustomerDto.billingDto.projectForBillingDtos &&
                    billingForCustomerDto.billingDto.projectForBillingDtos.map((row, index) => (
                      <StyledTableRow key={row.projectId}>
                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                        <StyledTableCell align="right">{row.active ? localized('Yes') : localized('No')}</StyledTableCell>
                        <StyledTableCell align="right">{row.hours?.toFixed(2)}</StyledTableCell>
                        <StyledTableCell align="right">
                          {row.rate ? row.rate.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.rate} kr.
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.total ? row.total.toLocaleString('da-DK', { maximumFractionDigits: 2 }) : row.total} kr.
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Radio
                            checked={selectedProjectIndex === index}
                            onChange={handleChangeSelectedProject}
                            value={index}
                            name="radio-button-selected-project"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Grid container>
            <Box marginTop={2} marginBottom={2}>
              <TableContainer>
                {vouchers && vouchers.length > 0 ? (
                  <Table aria-label="onePerProject-Voucher">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="right">{localized('Project')}</StyledTableCell>
                        <StyledTableCell align="right">{localized('BoundtoProject')}</StyledTableCell>
                        <StyledTableCell align="right">{localized('HoursonVoucher')}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vouchers &&
                        billingForCustomerDto.billingDto &&
                        vouchers.map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell align="right">
                              {row.projectId && projects ? projects.filter((proj) => proj.id === row.projectId)[0].name : ''}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.projectId ? localized('Yes') : localized('No')}</StyledTableCell>
                            <StyledTableCell align="right">{row.remainingHours}</StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleBillSpecificVoucher(row.id)}
                                size={buttonSize}
                                className={classes.button}
                              >
                                {localized('UseVoucher')}
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : null}
              </TableContainer>
            </Box>
          </Grid>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleGoBackClick} size={buttonSize} className={classes.button}>
              {localized('GoBack')}
            </Button>
            <Button
              style={{ marginLeft: 6 }}
              variant="contained"
              color="primary"
              onClick={handleWarningDialogVisible}
              size={buttonSize}
              className={classes.button}
            >
              {localized('CrateNewBillBasedOnTimerecord')}
            </Button>
            <Button
              style={{ marginLeft: 6 }}
              variant="contained"
              color="primary"
              onClick={handleAlertDialogVisible}
              size={buttonSize}
              className={classes.button}
            >
              {localized('AddTimerecordToBill')}
            </Button>

            {params.customerId !== '6' && params.customerId !== '7' && (
              <TextField
                type="text"
                id="billSelect"
                className={classes.button}
                name="bill"
                label={localized('BillNumber')}
                variant="outlined"
                onChange={handleBillChange}
                value={billNumber ? billNumber.toString() : ''}
                defaultValue={''}
              />
            )}
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isSnackBarOpen}
            autoHideDuration={5000}
            onClose={handleCloseSnackbar}
          >
            <Alert severity={snackBarSeverity} variant="filled">
              {snackBarMessage}
            </Alert>
          </Snackbar>
          {alertDialogVisible === true && (
            <AlertDialog
              acceptRequest={handleClickWithBill}
              visible={alertDialogVisible}
              setVisible={(val) => setAlertDialogVisible(val)}
              title={localized('AddTimeregistrationstoInvoice')}
              description={localized('AreyousureRgistrationstoInvoice').concat(` ${billNumber}?`)}
            />
          )}
          {warningDialogVisible === true && (
            <AlertDialog
              acceptRequest={handleClickNewBill}
              visible={warningDialogVisible}
              setVisible={(val) => setWarningDialogVisible(val)}
              title={localized('YouHaveUnusedVouchers')}
              description={localized('AreYouSureYouWantToNewBillWithVoucher')}
            />
          )}
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default withRouter(BillingOnePerProject);
