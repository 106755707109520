import { TimerecordRequest, Client, HolidayDTO } from './http-services/api';

async function createTimerecord(timerecord: TimerecordRequest) {
  const client = new Client();

  try {
    await client.timerecord(timerecord);
    return true;
  } catch (error) {
    return false;
  }
}

async function getCSV() {
  const client = new Client();
  const result = await client.requestTimerecordCSV();
  return result;
}

async function getHolidayCSV(startDate: Date, endDate: Date, HolidayOrSick: boolean | null, personId?: number) {
  const client = new Client();
  const result = await client.requestStatsTimerecordCSV(startDate, endDate, personId, HolidayOrSick); //HolidayOrSick is unused
  return result;
}

async function Delete(id: number) {
  const client = new Client();

  const result = await client.deleteTimeregistration(id);
  return result;
}

async function getAll() {
  const client = new Client();

  const result = await client.getAll();
  return result;
}

async function getOverview(startDate: Date, endDate: Date, personId: number = 0) {
  const client = new Client();

  const result = await client.overview(startDate, endDate, personId);
  return result;
}

async function getAllTimerecordsForUser(personId: number) {
  const client = new Client();

  const result = await client.allTimerecordsForUser(personId);
  return result;
}

async function getAllTimerecordsForCurrentUser() {
  const client = new Client();

  const result = await client.allTimerecordsForCurrentUser();
  return result;
}

async function getTimerecordById(id: number) {
  const client = new Client();

  const result = await client.timerecord2(id);
  return result;
}

async function updateTimerecord(id: number, voucherRequest: TimerecordRequest) {
  const client = new Client();

  const result = await client.timerecord3(id, voucherRequest);
  return result;
}

async function createHolidayRegistration(holiday: HolidayDTO) {
  const client = new Client();

  const result = await client.holidayRegistration(holiday);
  return result;
}

async function getSpecificationByBillId(billId: number) {
  const client = new Client();

  const result = await client.timeRecordBill(billId);
  return result;
}

async function getOverviewTimerecordCreate(view: string) {
  const client = new Client();

  const result = await client.createTimerecordOverview(view);
  return result;
}

async function getLatestRegistrationFromUser() {
  const client = new Client();

  const result = await client.latestUserRegistration();
  return result;
}

async function getTimerecordsPerMonthForProject(projectId: number) {
  const client = new Client();

  const result = await client.timerecordsPerMonth(projectId);
  return result;
}

async function getTimerecordsForProject(projectId: number) {
  const client = new Client();

  const result = await client.getTimeRecordByProject(projectId);
  return result;
}

async function getHolidaysCurrentMonth(date: Date, userId: number) {
  const client = new Client();

  const result = await client.getHolidaysCurrentMonth(date, userId);
  return result;
}

export const timerecordService = {
  createTimerecord,
  getAll,
  getOverview,
  getAllTimerecordsForUser,
  getTimerecordById,
  updateTimerecord,
  createHolidayRegistration,
  getOverviewTimerecordCreate,
  getAllTimerecordsForCurrentUser,
  getSpecificationByBillId,
  getLatestRegistrationFromUser,
  getTimerecordsPerMonthForProject,
  getTimerecordsForProject,
  Delete,
  getCSV,
  getHolidayCSV,
  getHolidaysCurrentMonth
};
