import { TableRow, TableContainer, Table, TableHead, TableBody, Button, CircularProgress, Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'date-fns/parse';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { UtilizationUserDTO } from '../../services/http-services/api';
import { economicServices } from '../../services/economic.services';
import { TableStyles, StyledTableCell, StyledTableRow } from '../../styles/use-styles';
import { buttonSize } from '../../utilities/constants';
import { localized } from '../../utilities/language/i18n';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';

interface UtilizationByMonthParams {
  dateString: string;
}

function UtilizationByMonth() {
  const [utilizationUserDTOs, setUtilizationUserDTOs] = useState<UtilizationUserDTO[] | null>();

  const params = useParams<UtilizationByMonthParams>();

  React.useEffect(() => {
    async function loadUtilizationUserDTOs() {
      let date = parse(params.dateString, 'dd-MM-yy', new Date());
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dataList = await economicServices.getUtilizationByUser(date);
      setUtilizationUserDTOs(dataList);
    }
    loadUtilizationUserDTOs();
  }, [setUtilizationUserDTOs]);

  const classes = TableStyles();
  const history = useHistory();

  const handleGoBackClick = () => {
    history.goBack();
  };

  const internal = utilizationUserDTOs?.reduce((sum, rec) => sum + rec.internalHours!, 0);
  const external = utilizationUserDTOs?.reduce((sum, rec) => sum + rec.externalHours!, 0);
  const util = (external! / (external! + internal!)) * 100;
  const data = [
    { name: 'Group A', value: external! },
    { name: 'Group B', value: internal! }
  ];
  const COLORS = ['#1e9bd3', '#808080'];

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('UtilizationOverview')}
      </Typography>
      {utilizationUserDTOs ? (
        <div>
          <ResponsiveContainer height={150} width="100%">
            <PieChart>
              <Pie
                data={data}
                innerRadius={55}
                outerRadius={70}
                startAngle={90}
                endAngle={450}
                fill="#1e9bd3"
                paddingAngle={2}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <Label
                  value={util.toLocaleString(localized('Format'), { maximumFractionDigits: 2 }) + '%'}
                  position="center"
                  fill="#000000"
                  fontSize={18}
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{localized('Person')}</StyledTableCell>
                  <StyledTableCell>{localized('TotalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('ExternalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('InternalHours')}</StyledTableCell>
                  <StyledTableCell>{localized('Utilization')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {utilizationUserDTOs.map((item) => (
                  <StyledTableRow key={item.personName}>
                    <StyledTableCell component="th" scope="row">
                      {item.personName}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.totalHours}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.externalHours}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.internalHours}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.utilization}%
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box marginTop={2} marginLeft={2}>
            <Button variant="contained" color="primary" size={buttonSize} onClick={handleGoBackClick} startIcon={<ArrowBackIcon />}>
              {localized('GoBack')}
            </Button>
          </Box>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default UtilizationByMonth;
