import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { timerecordService } from '../../../services/timerecord.services';
import { Person } from '../../../services/http-services/api';
import { StyledTableCell, StyledTableRow } from '../../../styles/use-styles';
import { localized } from '../../../utilities/language/i18n';
import { personService } from '../../../services/person.services';

interface TimerecordOverviewTableProps {
  personId: string;
}

class WeeklyTableRow {
  yearNumber: number = 0;
  weeknumber: number = 0;
  hours: number = 0;
}

export default function TimerecordWeekOverviewTable() {
  const [tableRows, setTableRows] = React.useState<WeeklyTableRow[] | null>();
  const [userId, setUserId] = React.useState<number>();
  const [user, setUser] = React.useState<Person>();
  const params = useParams<TimerecordOverviewTableProps>();

  async function generateMonths() {
    const Timerecords = await timerecordService.getAllTimerecordsForUser(userId!);
    const dates = Timerecords.map((tr) => tr.date);
    if (dates && dates.length > 0) {
      const Years = [...Array.from(new Set(dates.map((item) => item!.getFullYear())))];
      const Months = [...Array.from(new Set(dates.map((item) => item!.getMonth())))];
      return [Years, Months];
    }
  }
  React.useEffect(() => {
    async function loadInit() {
      setUserId(parseInt(params.personId, 10));
      if (userId) {
        var person = await personService.getPersonById(userId);
        setUser(person);
      }
    }
    loadInit();
  }, [userId]);

  function CurrentDateWeek(currentDate: Date) {
    function dateDiffInDays(a: Date, b: Date) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc1 - utc2) / _MS_PER_DAY);
    }
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = dateDiffInDays(currentDate, startDate);
    var firstDay = startDate.getDay();

    if (firstDay >= 5) {
      //fri or sat
      days = days - (7 - firstDay);
    }
    if (firstDay > 0 && firstDay < 5) {
      // mon-thu
      days = days + firstDay;
    }
    var weekNumber = Math.ceil(days / 7);

    return weekNumber;
  }

  React.useEffect(() => {
    async function loadTimerecordOverviewResponse() {
      const rows: WeeklyTableRow[] = [];
      if (userId) {
        const Times = await generateMonths();
        if (Times) {
          const Years = Times[0].sort(function (a, b) {
            return a - b;
          });
          const Months = Times[1].sort(function (a, b) {
            return a - b;
          });
          var startDate = new Date(Years[0], 0, 1);
          var firstDay = startDate.getDay();
          if (firstDay == 0 || firstDay == 5 || firstDay == 6) {
            //fri, sat and sun
            while (startDate.getDay() !== 1) {
              startDate.setDate(startDate.getDate() + 1);
            }
          } else {
            while (startDate.getDay() !== 1) {
              startDate.setDate(startDate.getDate() - 1);
            }
          }

          var endDate = new Date(Years[Years.length - 1], Months[Months.length - 1], 31);

          var now = new Date();
          while (now.getDay() !== 0) {
            now.setDate(now.getDate() + 1);
          }

          startDate.setHours(12);
          endDate.setHours(12);

          const overView = await timerecordService.getOverview(startDate, endDate, userId);

          for (var startDatetemp = startDate; startDatetemp <= now; startDatetemp.setDate(startDatetemp.getDate() + 7)) {
            var endDatetemp: Date = new Date(startDatetemp.getFullYear(), startDatetemp.getMonth(), startDatetemp.getDate() + 6); // sunday in week j
            startDatetemp.setHours(0);
            endDatetemp.setHours(23);
            var tempRow: WeeklyTableRow = new WeeklyTableRow();
            if (overView && overView.length > 0) {
              var hours = overView[0]
                .dateAndHours!.filter((tr) => tr.date! <= endDatetemp && tr.date! >= startDatetemp)
                .map((tr) => tr.hour!)
                .reduce((partialSum, a) => partialSum + a, 0);
              var thursday = new Date(startDatetemp.getFullYear(), startDatetemp.getMonth(), startDatetemp.getDate() + 3);
              tempRow.weeknumber = CurrentDateWeek(thursday);
              tempRow.yearNumber = startDatetemp.getFullYear();
              if (hours) {
                tempRow.hours = hours;
              }
              rows.push(tempRow);
            }
          }

          //  tempdate = new Date(tempdate.getFullYear(), tempdate.getMonth(), tempdate.getDay()+28)
        }
      }
      setTableRows(rows.reverse());
    }

    loadTimerecordOverviewResponse();
  }, [userId]);

  function loadData() {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Year</StyledTableCell>
              <StyledTableCell>Week</StyledTableCell>
              <StyledTableCell>Hours</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows!.map((row, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell align="right"> {row.yearNumber.toString()} </StyledTableCell>
                  <StyledTableCell align="right"> {row.weeknumber.toString()} </StyledTableCell>
                  <StyledTableCell align="right">{row.hours.toString()} </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <div>
      <Typography variant="h4" className="App_pageTitle">
        {localized('WeeklyTimeRegistrationsFor')} {user && user.name ? user.name : ''}
      </Typography>
      {tableRows && tableRows.length > 0 ? loadData() : <CircularProgress />}
    </div>
  );
}
