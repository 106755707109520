import { Project, Client } from './http-services/api';

async function createProject(project: Project) {
  const client = new Client();

  const result = await client.project(project);
  return result;
}

async function getAll() {
  const client = new Client();

  const result = await client.projectAll();
  return result;
}

async function getAllWithCustomers() {
  const client = new Client();

  const result = await client.projectsWithCustomer();
  return result;
}

async function getActiveProjects() {
  const client = new Client();

  const result = await client.getActiveProjects();
  return result;
}

async function editProject(project: Project) {
  const client = new Client();

  const result = await client.project2(project);
  return result;
}

async function getProjectById(projectId: number) {
  const client = new Client();

  const result = await client.project3(projectId);
  return result;
}

async function getProjectHours(projectId: number) {
  const client = new Client();

  const result = await client.hours(projectId);
  return result;
}

async function getSubscribedProjects() {
  const client = new Client();

  const result = await client.subscribed();
  return result;
}

async function getCustomerProjects(customerId: number) {
  const client = new Client();

  const result = await client.customerProjects(customerId);
  return result;
}

export const projectService = {
  createProject,
  getAll,
  editProject,
  getProjectById,
  getProjectHours,
  getActiveProjects,
  getSubscribedProjects,
  getCustomerProjects,
  getAllWithCustomers
};
